import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import Block from '../Block/viewer';

const RefBlock = props => {
  const { WrapperFactory, data = {}, block = {}, dereferencedBlock = {}, editor, parsed } = props;

  const blockId = _.replace(data.$ref, '#/blocks/', '');
  const refBlock = _.find(editor.connectedBlocks, { id: blockId }) || {};
  const refBlockDeref = _.cloneDeep(_.get(parsed, ['blocks', blockId], {}));

  refBlock.input = _.unionBy(block.input, refBlock.input, 'name');
  refBlockDeref.input = _.unionBy(dereferencedBlock.input, refBlockDeref.input, 'name');

  const contentElem = <Block {...props} block={refBlock} dereferencedBlock={refBlockDeref} />;

  return <WrapperFactory contentElem={contentElem} />;
};

export default observer(RefBlock);
