import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { stepResultMeta, stepName, stepToken } from '@platform/utils/collections';

import Accordion from '../../Accordion';
import BarListItem from '../../BarListItem';

import HttpOutput from './http';

function flattenSteps(result) {
  const scenarioSteps = _.get(result, 'input.spec.steps');
  const outputSteps = _.get(result, 'output.steps');

  if (_.isEmpty(outputSteps) || _.isEmpty(scenarioSteps)) {
    return [];
  }

  let steps = [];

  for (const index in scenarioSteps) {
    const step = scenarioSteps[index];
    const stepResult = outputSteps[index];

    if (step.type === 'ref') {
      steps = steps.concat(flattenSteps(stepResult));
      continue;
    }

    steps.push({ step, stepResult });
  }

  return steps;
}

function computePanels(result, mappedScenarioNames) {
  const steps = flattenSteps(result);

  const panels = [];

  for (const index in steps) {
    const { step, stepResult } = steps[index];

    const resultData = stepResultMeta({ stepResult });

    panels.push({
      title: () => (
        <BarListItem
          className="ScenarioStepList-item"
          parts={[
            {
              fill: true,
              bg: true,
              padded: true,
              elem: () => {
                let tokenElem;

                const token = stepToken(step, { maxLength: 4 });
                if (token && token.name) {
                  tokenElem = (
                    <div className={`ScenarioStepList-token ${token.className}`}>{token.name}</div>
                  );
                }

                return (
                  <div className="flex items-center ScenarioStepList-name">
                    <Icon name="dropdown" />
                    {tokenElem}
                    {stepName(step, { skipToken: true, mappedScenarioNames })}
                  </div>
                );
              },
            },
            {
              bg: true,
              padded: true,
              className: resultData.className,
              elem: () => resultData.status,
            },
          ]}
        />
      ),
      content: () => <HttpOutput id={`${step.id}-${index}`} result={stepResult} />,
    });
  }

  return panels;
}

function RefOutput({ result, mappedScenarioNames }) {
  const panels = computePanels(result, mappedScenarioNames);

  return (
    <div>
      <div className="SplitEditor-parsedTitle">Output Steps</div>
      <Accordion fluid exclusive={false} panels={panels} />
    </div>
  );
}

export default inject(({ collectionEditorStore }, { id }) => {
  return {
    mappedScenarioNames: collectionEditorStore.getEditor({ id }).mappedNames,
  };
})(observer(RefOutput));
