import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Icon, Button, Popup } from 'semantic-ui-react';

const EntityEditorGroup = props => {
  const {
    id,
    name,
    className = '',
    children,
    contentFactory,
    contentClassName = '',
    padded,
    active,
    warning,
    error,
    subtitle,
    defaultOpen,
    ui,
    updateUi,
    actions = [],
    tipFactory,
    onToggle,
    forceOpen,
  } = props;

  let isOpen;
  if (onToggle) {
    if (forceOpen) {
      isOpen = true;
    }
  } else if (ui.open || (!ui.hasOwnProperty('open') && (defaultOpen || ui.forceOpen))) {
    // should be open if the user said it should be, by default it is, or if
    // it was originally forcedOpen and the user has not closed it
    isOpen = true;
  }

  const actionElems = _.map(actions, (action, index) => {
    return (
      <div className="EntityEditorGroup-action" key={index}>
        <Button basic compact size="mini" {...action} />
      </div>
    );
  });

  return (
    <div
      id={id}
      className={cn('EntityEditorGroup', className, {
        'is-active': active,
        'is-open': isOpen,
      })}
    >
      <div
        className="EntityEditorGroup-header flex items-center"
        onClick={e => {
          if (isOpen && _.get(e, 'target.tagName', '').toLowerCase() === 'button') {
            return;
          }

          updateUi('set', ['open'], !isOpen);

          if (onToggle) {
            onToggle(!isOpen);
          }
        }}
      >
        <div className="EntityEditorGroup-toggle">
          <Icon name={cn('caret', isOpen ? 'down' : 'right')} />
        </div>

        <div className="EntityEditorGroup-name">{name}</div>

        {!_.isEmpty(subtitle) ? <div className="EntityEditorGroup-subtitle">{subtitle}</div> : null}

        <div className="EntityEditorGroup-actions flex items-center">{actionElems}</div>

        {active ? (
          <div className="EntityEditorGroup-status c-positive">
            <Icon name="check" />
          </div>
        ) : null}

        {warning ? (
          <div className="EntityEditorGroup-status c-warning">
            <Icon name="exclamation circle" />
          </div>
        ) : null}

        {error ? (
          <div className="EntityEditorGroup-status c-error">
            <Icon name="exclamation circle" />
          </div>
        ) : null}

        <div className="flex-1" />

        {tipFactory ? (
          <Popup
            trigger={
              <div>
                <Icon className="c-muted" name="question circle" />
              </div>
            }
            size="small"
            position="top center"
            hoverable
            wide
          >
            {tipFactory()}
          </Popup>
        ) : null}
      </div>

      <div className="EntityEditorGroup-content">
        <div className={cn(contentClassName, { 'p-3': padded })}>
          {isOpen ? children || contentFactory() : null}
        </div>
      </div>
    </div>
  );
};

export default inject(({ appStore }, { id, forceOpen }) => ({
  ...appStore.injectUi(`EntityEditorGroup-${id}`, {
    forceOpen,
  }),
}))(observer(EntityEditorGroup));
