import React from 'react';
import { Statistic } from 'semantic-ui-react';
import _ from 'lodash';

const ScenarioStats = ({ title, items = [] }) => {
  let contentElem;
  if (_.isEmpty(items)) {
    contentElem = <div className="text-center font-bold">no results yet</div>;
  } else {
    contentElem = <Statistic.Group size="tiny" widths={items.length} items={items} />;
  }

  return (
    <div className="ScenarioStats">
      {title ? <div className="SplitEditor-parsedTitle">{title}</div> : null}
      {contentElem}
    </div>
  );
};

export default ScenarioStats;
