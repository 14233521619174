import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
import { languages, buildSnippet } from '@platform/utils/httpsnippet';
import { alert } from '@platform/utils/alert';
import { Button, Icon } from 'semantic-ui-react';

import Highlight from '../Highlight';
import FormDropdown from '../FormDropdown';

const CodeGenerator = props => {
  const { className, updateUi, ui, data = {} } = props;

  const selected = _.get(ui, 'selected') || _.head(languages);
  const libraries = selected.libraries;

  const { librarychoice, codechoice, mode } = selected;

  const snippet = buildSnippet(data, { codechoice, librarychoice });

  const hasError = _.has(snippet, 'error');

  let code;

  if (hasError) {
    code = <div className="c-muted p-2">{snippet.error}</div>;
  } else {
    code = (
      <div className="bg-white">
        <Highlight mode={mode}>{snippet}</Highlight>
      </div>
    );
  }

  return (
    <div className={cn('CodeGenerator', className)}>
      <div className="flex mb-6 items-end">
        <div className="flex items-center w-1/2">
          <div className="flex-1">
            <div className="font-bold mb-2">Language</div>

            <FormDropdown
              value={selected.value}
              options={languages}
              onChange={(e, { value }) => {
                const selected = _.find(languages, function(o) {
                  return o.value === value;
                });

                updateUi('set', 'selected', selected);
              }}
              selection
              search
              fluid
            />
          </div>

          {libraries && (
            <div className="flex-1 ml-4">
              <div className="font-bold mb-2">Library</div>

              <FormDropdown
                className="flex-1"
                value={_.get(selected, 'librarychoice') || _.get(selected, 'libraries.0.value')}
                options={libraries}
                onChange={(e, { value }) => {
                  const selected = _.find(libraries, function(o) {
                    return o.value === value;
                  });

                  updateUi('set', 'selected.librarychoice', selected.librarychoice);
                }}
                fluid
                selection
                search
              />
            </div>
          )}
        </div>

        {!hasError && (
          <div className="ml-4">
            <Button
              title="Copy to clipboard"
              icon="copy"
              onClick={() => {
                try {
                  copy(snippet);
                  alert.success('Copied!');
                } catch (error) {
                  alert.error(`Error copying to clipboard: ${error.message}`);
                }
              }}
            />
          </div>
        )}
      </div>

      {code}
    </div>
  );
};

export default inject(({ appStore }, { editorId }) => ({
  ...appStore.injectUi(`codegen-${editorId}`),
}))(observer(CodeGenerator));
