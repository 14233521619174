// import React from 'react';
// import { asyncComponent } from 'react-async-component';

// import PageLoading from '../PageLoading';

import CodeMirror from './codeMirror';

// const CodeMirror = asyncComponent({
//   serverMode: 'defer',
//   resolve: () => System.import('./codeMirror'),
//   LoadingComponent: () => <PageLoading inverted={false} />, // Optional
//   ErrorComponent: ({ error }) => <div>{error.message}</div>, // Optional
// });

// const CodeMirror = dynamic({
//   ssr: false,

//   loading: () => <PageLoading inverted={false} />,

//   modules: props => {
//     const components = {
//       codeMirrorInstance: import('./codeMirror'),
//     };

//     return components;
//   },

//   render: (props, { codeMirrorInstance }) => {
//     const { render } = props;
//     return render(codeMirrorInstance);
//   },
// });

export default CodeMirror;
