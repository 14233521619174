import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';

import StepBasics from './step_basics';

import EditorContentSection from '../EditorContentSection';
import StepInput from '../ScenarioEditor/input';
import StepOutput from '../ScenarioEditor/output';
import ScenarioLogs from '../ScenarioLogs';

function Basics(props) {
  const { editor } = props;
  const { currentStep = {} } = editor;

  return <StepBasics step={currentStep.data} {...props} />;
}
const InjectedBasics = inject(({ collectionEditorStore }, { editorId }) => {
  return {
    editor: collectionEditorStore.getEditor({ id: editorId }),
  };
})(observer(Basics));

function Input(props) {
  const { editor = {}, readOnly, autocompleteScopes, inputProps = {}, handleUpdate } = props;

  const { currentStep = {} } = editor;

  return (
    <StepInput
      editorId={editor.id}
      editor={editor}
      collection={editor.parsed}
      collectionId={editor.id}
      scenarioId={editor.currentScenarioId}
      readOnly={readOnly}
      step={currentStep.data}
      stepIndex={currentStep.index}
      autocompleteScopes={autocompleteScopes}
      handleUpdate={(t, p, v) => {
        if (!_.isEmpty(editor)) {
          editor.updateCurrentParsed(t, p, v);

          if (handleUpdate) {
            handleUpdate(t, p, v);
          }
        }
      }}
      {...inputProps}
    />
  );
}
const InjectedInput = inject(({ collectionEditorStore }, { editorId }) => {
  return {
    collectionEditorStore,
    editor: collectionEditorStore.getEditor({ id: editorId }),
  };
})(observer(Input));

function Debug({ editor = {} }) {
  const { currentResult, currentRunning } = editor;

  const logs = _.get(currentResult, 'data.logs');
  if (currentRunning || _.isEmpty(logs)) {
    return null;
  }

  return (
    <EditorContentSection id="ScenarioStep:result" title="Debug">
      <ScenarioLogs id={currentResult.id} logs={logs} />
    </EditorContentSection>
  );
}
const InjectedDebug = inject(({ collectionEditorStore }, { editorId }) => ({
  editor: collectionEditorStore.getEditor({ id: editorId }),
}))(observer(Debug));

function Output({ editor = {}, standalone, outputProps = {} }) {
  const { currentResult, currentRunning } = editor;

  let elem;
  if (currentRunning) {
    elem = (
      <div className="p-6 text-center">
        <Loader indeterminate active inline>
          Running
        </Loader>
      </div>
    );
  } else if (currentResult) {
    elem = (
      <StepOutput
        id={currentResult.id}
        type={currentResult.type}
        result={currentResult.data}
        {...outputProps}
      />
    );
  } else if (standalone) {
    return null;
  } else {
    elem = (
      <h4 className="c-muted font-bold text-center">click the run step button to view output</h4>
    );
  }

  return <div className="ScenarioOutput mt-6">{elem}</div>;
}

const InjectedOutput = inject(({ collectionEditorStore }, { editorId }) => ({
  editor: collectionEditorStore.getEditor({ id: editorId }),
}))(observer(Output));

const ScenarioStepEditor = props => {
  const { editorId, readOnly, autocompleteScopes, inputProps, outputProps, handleUpdate } = props;

  const standalone = _.get(inputProps, 'standalone');
  if (standalone) {
    return (
      <div className="ScenarioStep">
        <InjectedInput
          editorId={editorId}
          readOnly={readOnly}
          autocompleteScopes={autocompleteScopes}
          inputProps={inputProps}
          handleUpdate={handleUpdate}
          standalone
        />

        <InjectedOutput
          editorId={editorId}
          outputProps={outputProps}
          handleUpdate={handleUpdate}
          standalone
        />
      </div>
    );
  }

  return (
    <div className="ScenarioStep">
      <EditorContentSection id="ScenarioStep:basics">
        <InjectedBasics {...props} />
      </EditorContentSection>

      <EditorContentSection id="ScenarioStep:input" title="Input">
        <InjectedInput
          editorId={editorId}
          readOnly={readOnly}
          autocompleteScopes={autocompleteScopes}
          inputProps={inputProps}
          handleUpdate={handleUpdate}
        />
      </EditorContentSection>

      <InjectedDebug editorId={editorId} />

      <EditorContentSection id="ScenarioStep:output" title="Output" canToggle={false}>
        <InjectedOutput editorId={editorId} outputProps={outputProps} handleUpdate={handleUpdate} />
      </EditorContentSection>
    </div>
  );
};

export default ScenarioStepEditor;
