import React from 'react';
import _ from 'lodash';

function customizer(objValue, othValue) {
  if (_.isFunction(objValue) || _.isFunction(othValue)) {
    return true;
  }
}

// Use skipFunctions with care! It's easy to create subtle bugs.
function deepCompare({ skipFunctions, debug } = {}) {
  return WrappedComponent => {
    return class extends React.Component {
      componentWillMount = () => {
        if (debug) {
          console.log('deepCompare.componentWillMount', this.props);
        }
      };

      shouldComponentUpdate = nextProps => {
        let same;
        if (skipFunctions) {
          same = _.isEqualWith(this.props, nextProps, customizer);
        } else {
          same = _.isEqual(this.props, nextProps);
        }

        if (debug) {
          console.log('deepCompare.shouldComponentUpdate?', !same, this.props, nextProps);
        }

        return !same;
      };

      render = () => {
        return <WrappedComponent {...this.props} />;
      };
    };
  };
}

export default deepCompare;
