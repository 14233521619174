import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import ScenarioStep from '@platform/components/ScenarioEditor/step';

import { initCollectionEditor, getCollectionEditor, stopStep, runStep } from './common';

class HttpBlock extends React.Component {
  componentWillMount() {
    initCollectionEditor(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // init if its the first time, or we're re-painting for the current id
    // the repaint happens because dereference process is delayed
    if (!getCollectionEditor(nextProps).id || nextProps.id === this.props.id) {
      initCollectionEditor(nextProps);
    }
  }

  render = () => {
    const { id, WrapperFactory, config = {}, block, data = {} } = this.props;

    const currentRunning = !_.isEmpty(getCollectionEditor(this.props).currentRunning);

    const codegen = _.get(block, ['config', 'codegen']);

    const contentElem = (
      <ScenarioStep
        editorId={id}
        inputProps={{
          type: 'http',
          standalone: true,
          config: config,
          tabWhitelist: _.compact([
            'settings',
            !_.isEmpty(data.headers) && 'headers',
            !_.isEmpty(data.query) && 'query',
            !_.isEmpty(data.body) && 'body',
            !_.isEmpty(data.auth) && 'auth',
            'code-generation',
          ]),
          endpoints: data.endpoints,
          headerElems: [
            {
              render: () => {
                let buttonProps = {};

                if (currentRunning) {
                  buttonProps = {
                    icon: 'stop',
                    content: 'Stop',
                    onClick: () => {
                      stopStep(this.props);
                    },
                    negative: true,
                  };
                } else {
                  buttonProps = {
                    icon: 'play',
                    content: 'Send',
                    onClick: () => {
                      runStep(this.props);
                    },
                    secondary: true,
                  };
                }

                return <Button {...buttonProps} />;
              },
            },
          ],
          readOnly: true,
        }}
        outputProps={{
          tabWhitelist: ['body', 'headers', 'request'],
        }}
        codegen={codegen}
      />
    );

    return <WrapperFactory {...this.props} contentElem={contentElem} />;
  };
}

export default inject(({ collectionEditorStore }) => {
  return {
    collectionEditorStore,
  };
})(observer(HttpBlock));
