import React from 'react';
import Highlight from '@platform/components/Highlight';

const CodeBlock = props => {
  const { id, WrapperFactory, data = '', config = {} } = props;
  const { mode, lineNumbers, highlightRange } = config;

  const contentElem = (
    <Highlight
      id={id}
      content={data}
      mode={mode}
      lineNumbers={lineNumbers}
      highlightRange={highlightRange}
      immediate
    />
  );

  return <WrapperFactory {...props} contentElem={contentElem} />;
};

export default CodeBlock;
