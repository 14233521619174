import React from 'react';
import _ from 'lodash';

const defaultConvertToTarget = value => value;
const defaultConvertFromTarget = value => value;

const DebounceContainer = (props = {}) => BaseComponent => {
  const {
    convertForTarget = defaultConvertToTarget,
    convertFromTarget = defaultConvertFromTarget,
    targetPropName = 'value',
  } = props;

  class Debouncer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        value: convertForTarget(props.value, props),
        externalValue: props.value,
      };

      this.persistChange = _.debounce(this.persistChange, 1000);
    }

    componentWillUpdate(nextProps, nextState) {
      this.onExternalValueChange(nextProps, nextState);
    }

    componentWillUnmount() {
      if (this.persistChange) {
        this.persistChange.flush();
      }
    }

    onExternalValueChange = (props, state) => {
      if (state.internal) {
        state.internal = false;
      } else if (!_.isEqual(props.value, state.externalValue)) {
        state.value = convertForTarget(props.value, props);
        state.externalValue = props.value;
      }
    };

    _persistChange = (value, options) => {
      const { onChange } = this.props;

      const nextVal = convertFromTarget(value, Object.assign({}, this.props));

      this.setState({
        value,
        internal: true,
        externalValue: nextVal,
      });

      if (onChange) {
        onChange({ value: nextVal, options });
      }
    };

    persistChange = (value, options) => {
      this._persistChange(value, options);
    };

    onChange = (value, options = {}) => {
      this.setState({
        value,
        internal: true,
      });

      if (options.immediate) {
        this._persistChange(value, options);
      } else {
        this.persistChange(value, options);
      }
    };

    render() {
      const { value } = this.state || {};

      const computedProps = {
        [targetPropName]: value,
      };

      return <BaseComponent {...this.props} {...computedProps} onChange={this.onChange} />;
    }
  }

  return Debouncer;
};

export default DebounceContainer;
