import React from 'react';
import _ from 'lodash';

import { Link } from '@platform/utils/router';

import { computeTheme } from '../../utils';

import { defaultTheme } from './index';

const CardChild = props => {
  const { child = {}, store, theme = {}, childIndex } = props;

  const {
    image = '//react.semantic-ui.com/assets/images/wireframe/image.png',
    title,
    content,
    route = {},
  } = child;

  const computedTheme = computeTheme({ theme, defaultTheme });
  const cardBgStyle = _.get(computedTheme, ['styles', 'card', childIndex, 'background'], {});
  const cardTextStyle = _.get(computedTheme, ['styles', 'card', childIndex, 'text'], {});

  return (
    <Link
      className="CardListBlock-child flex items-center"
      to={store.buildViewPath(route.path)}
      style={cardBgStyle}
    >
      <div className="CardListBlock-image">
        <img src={image} />
      </div>

      <div className="CardListBlock-content flex-1">
        <div className="CardListBlock-title font-bold" style={cardTextStyle}>
          {title}
        </div>
        <div className="CardListBlock-body" style={cardTextStyle}>
          {content}
        </div>
      </div>
    </Link>
  );
};

const BlockCardList = props => {
  const { WrapperFactory, data = {}, theme = {}, header = {}, basePath, store } = props;

  const { children = [] } = data;
  const { title, subtitle } = header;

  const computedTheme = computeTheme({ theme, defaultTheme });
  const baseBgStyle = _.get(computedTheme, 'styles.base.background', {});
  const baseTextStyle = _.get(computedTheme, 'styles.base.text', {});

  const contentElem = [
    <div key="h" className="CardListBlock-header">
      {title ? (
        <div className="HubBlock-content-title" style={baseTextStyle}>
          {title}
        </div>
      ) : null}

      {subtitle ? (
        <div className="HubBlock-content-body" style={baseTextStyle}>
          {subtitle}
        </div>
      ) : null}
    </div>,

    <div key="c" className="CardListBlock-cards flex items-center justify-center flex-wrap">
      {_.map(children, (child, key) => (
        <CardChild
          key={key}
          child={child}
          childIndex={key}
          basePath={basePath}
          store={store}
          theme={computedTheme}
        />
      ))}
    </div>,
  ];

  return (
    <WrapperFactory
      {...props}
      headerElem={null}
      contentProps={{ style: baseBgStyle }}
      contentElem={contentElem}
      className="flex flex-col justify-center"
    />
  );
};

export default BlockCardList;
