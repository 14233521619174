import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { Accordion, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

class AccordionWrapper extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    panels: PropTypes.array,
    className: PropTypes.string,
    defaultOpen: PropTypes.bool,
    defaultActiveIndex: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  };

  constructor(props) {
    super(props);

    if (!_.isNil(props.defaultActiveIndex)) {
      props.updateUi('set', 'panels', _.map([].concat(props.defaultActiveIndex), this.buildPanels));
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.id !== this.props.id) {
      const { defaultActiveIndex = [], ui, updateUi } = nextProps;

      if (_.isEmpty(ui.panels)) {
        updateUi('set', 'panels', _.map([].concat(defaultActiveIndex), this.buildPanels));
      }
    }
  }

  buildPanels = (key, panel) => {
    const { exclusive, ui } = this.props;
    let panels = _.cloneDeep(ui.panels) || {};
    const isActive = this.isPanelActive(key, panel);

    if (!exclusive) {
      panels[key] = !isActive;
    } else {
      panels = {
        [key]: !isActive,
      };
    }

    return panels;
  };

  togglePanel = (key, panel) => {
    const { updateUi } = this.props;

    updateUi('set', 'panels', this.buildPanels(key, panel));
  };

  isPanelActive = (key, panel = {}) => {
    const { ui } = this.props;
    const { panels = {} } = ui;
    const { defaultOpen } = panel;

    return _.get(panels, key) ? true : _.has(panels, key) ? false : defaultOpen;
  };

  renderPanel = (panel, index) => {
    let title;
    let content;
    const key = panel.id || index;
    const isActive = this.isPanelActive(key, panel);

    if (panel.title) {
      title = (
        <Accordion.Title
          key={`${key}-title`}
          active={isActive}
          onClick={
            panel.onClick ? () => panel.onClick(key, panel) : () => this.togglePanel(key, panel)
          }
        >
          {_.isFunction(panel.title) ? (
            panel.title()
          ) : (
            <div className="flex items-center">
              <Icon name="dropdown" />

              <div className={panel.icon ? '' : 'flex-1'}>{panel.title}</div>

              {panel.icon ? (
                <div className="ml-3">
                  <Icon {...panel.icon} />
                </div>
              ) : null}
            </div>
          )}
        </Accordion.Title>
      );
    }

    if (panel.content && isActive) {
      content = (
        <Accordion.Content key={`${key}-content`} active={isActive}>
          {_.isFunction(panel.content) ? panel.content() : panel.content}
        </Accordion.Content>
      );
    }

    return [title, content];
  };

  render() {
    const { panels, className, ...otherProps } = this.props;

    // omit any props that don't need to be passed to semantic-ui-react
    const accordionProps = _.omit(otherProps, [
      'defaultActiveIndex',
      'id',
      'ui',
      'updateUi',
      'clearUi',
    ]);

    return (
      <Accordion className={cn('AccordionWrapper', className)} {...accordionProps}>
        {_.map(panels, this.renderPanel)}
      </Accordion>
    );
  }
}

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(`Accordion-${id}`),
  };
})(observer(AccordionWrapper));
