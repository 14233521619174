import * as Unist from '@core/unist';

function getTextContent(node: Unist.IElement) {
  return node.children.map(child => (child as Unist.IText).value).join('\n');
}

function transformer(node: Unist.INode) {
  if (node.type === 'element' && (node as Unist.IElement).tagName === 'script') {
    if ((node as Unist.IElement).children.length > 0) {
      node.type = 'comment';
      (node as Unist.IComment).value = `<script>${getTextContent(node as Unist.IElement)}</script>`;
    } else {
      node.type = 'text';
      (node as Unist.IText).value = '';
    }
  } else if ('children' in node && (node as Unist.IParent).children.length > 0) {
    for (const child of (node as Unist.IParent).children) {
      transformer(child);
    }
  }
}

export function sanitizeScript() {
  return transformer;
}
