import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';
import { safeStringify } from '@platform/utils/json';

import HttpBodyViewer from '../../HttpBodyViewer';
import InputResultList from '../../InputResultList';
import ScenarioStats from '../../ScenarioStats';

const ScenarioOutput = props => {
  const { id, ui, updateUi, className = '', result = {}, stats = [] } = props;

  if (_.isEmpty(result)) {
    return null;
  }

  const { activeResultTab = 'body' } = ui;
  const response = result.output || {};

  const handleItemClick = (e, { name }) => {
    updateUi('set', ['activeResultTab'], name);
  };

  const responseContentType = response.headers ? response.headers['Content-Type'] : '';

  // BUILD THE TAB CONTENT

  let contentElem;
  switch (activeResultTab) {
    case 'body':
      contentElem = (
        <div>
          <ScenarioStats items={stats} />

          <HttpBodyViewer
            name={`${id}-body`}
            contentType={responseContentType}
            value={safeStringify(_.get(response, 'body', ''))}
            uiCache={ui.body}
            uiCacheScope={['body']}
            updateUiCache={updateUi}
            noTypeSelector
          />
        </div>
      );
      break;
    case 'headers':
      contentElem = <InputResultList fields={_.get(response, 'headers')} sort />;
      break;
    default:
      contentElem = <div>Please select a tab.</div>;
      break;
  }

  // BUILD THE TABS

  // BODY TAB

  const status = String(_.get(response, 'status') || 'na');
  const bodyChar = status.charAt(0);
  let bodyClass = 'text-active';
  if (bodyChar === '2') {
    bodyClass = 'c-positive';
  } else if (_.includes(['4', '5'], bodyChar)) {
    bodyClass = 'c-negative';
  }
  const bodyName = (
    <span>
      Scenario Response <span className={`ml-3 ${bodyClass}`}>[{status}]</span>{' '}
      {response.time ? (
        <span className="c-muted ml-3">
          {response.time}
          ms
        </span>
      ) : (
        ''
      )}
    </span>
  );

  // HEADERS TAB

  const headerName = (
    <span>
      Headers{' '}
      <b>
        <span className="ml-2 text-active">[{_.size(_.get(response, 'headers') || {})}]</span>
      </b>
    </span>
  );

  const menuElem = (
    <Menu pointing secondary>
      <Menu.Item name="body" active={activeResultTab === 'body'} onClick={handleItemClick}>
        {bodyName}
      </Menu.Item>

      <Menu.Item name="headers" active={activeResultTab === 'headers'} onClick={handleItemClick}>
        {headerName}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={`ScenarioOutput ${className}`}>
      {menuElem}
      {contentElem}
    </div>
  );
};

export default inject(({ appStore }, { id }) => ({
  ...appStore.injectUi(`http-output-${id}`),
}))(observer(ScenarioOutput));
