import _ from 'lodash';
import HTTPSnippet from 'httpsnippet';

import { safeStringify, safeParse } from '../json';
import { replacePathParams } from '../url';
import { replaceVariables } from '../variables';

export const languages = [
  {
    text: 'Shell',
    value: 'shell',
    mode: 'bash',
    codechoice: 'shell',
    libraries: [
      { text: 'cURL', librarychoice: 'curl', value: 'curl' },
      { text: 'HTTPie', librarychoice: 'httpie', value: 'httpie' },
      { text: 'Wget', librarychoice: 'wget', value: 'wget' },
    ],
  },
  {
    text: 'Go',
    value: 'go',
    mode: 'go',
    codechoice: 'go',
    librarychoice: null,
  },
  {
    text: 'Java',
    value: 'java',
    mode: 'java',
    codechoice: 'java',
    libraries: [
      { text: 'Unirest', librarychoice: 'unirest', value: 'unirest' },
      { text: 'OkHttp', librarychoice: 'okhttp', value: 'okhttp' },
    ],
  },
  {
    text: 'Javascript',
    value: 'javascript',
    mode: 'javascript',
    codechoice: 'javascript',
    libraries: [
      { text: 'XMLHttpRequest', librarychoice: 'xmlhttprequest', value: 'xmlhttprequest' },
      { text: 'jQuery', librarychoice: 'jquery', value: 'jquery' },
    ],
  },
  {
    text: 'Node',
    value: 'node',
    mode: 'javascript',
    codechoice: 'node',
    libraries: [
      { text: 'Native', librarychoice: 'native', value: 'native' },
      { text: 'Request', librarychoice: 'request', value: 'request' },
      { text: 'Unirest', librarychoice: 'unirest', value: 'unirest' },
    ],
  },
  {
    text: 'Obj-C',
    value: 'objc',
    mode: 'objectivec',
    codechoice: 'objc',
    librarychoice: null,
  },
  {
    text: 'PHP',
    value: 'php',
    mode: 'php',
    codechoice: 'php',
    libraries: [
      { text: 'ext-curl', librarychoice: 'curl', value: 'curl' },
      { text: 'pecl/http v1', librarychoice: 'http1', value: 'http1' },
      { text: 'pecl/http v2', librarychoice: 'http2', value: 'http2' },
    ],
  },
  {
    text: 'Python',
    value: 'python',
    mode: 'python',
    codechoice: 'python',
    libraries: [
      { text: 'Python 3', librarychoice: 'python3', value: 'python3' },
      { text: 'Requests', librarychoice: 'requests', value: 'requests' },
    ],
  },
  {
    text: 'Powershell',
    value: 'powershell',
    mode: 'powershell',
    codechoice: 'powershell',
    libraries: [
      { text: 'WebRequest', librarychoice: 'webrequest', value: 'webrequest' },
      { text: 'RestMethod', librarychoice: 'restmethod', value: 'restmethod' },
    ],
  },
  {
    text: 'Ruby',
    value: 'ruby',
    mode: 'ruby',
    codechoice: 'ruby',
    librarychoice: null,
  },
  {
    text: 'Swift',
    value: 'swift',
    mode: 'swift',
    codechoice: 'swift',
    librarychoice: null,
  },
  {
    text: 'C#',
    value: 'csharp',
    mode: 'csharp',
    codechoice: 'csharp',
    librarychoice: null,
  },
  {
    text: 'C',
    value: 'c',
    mode: 'cpp',
    codechoice: 'c',
    librarychoice: null,
  },
];

export const buildSnippet = (props = {}, options = {}) => {
  const { method, url, headers = {}, body = {}, auth = {}, params = {}, schemes = [] } = props;

  let snippet;
  let data = {};
  let computedHeaders = [];
  let u = url;
  let postData = {};

  if (!method || _.isEmpty(u)) {
    return { error: 'Enter a valid URL, and request method, to see code generation' };
  }

  if (!_.isEmpty(params)) {
    const paramVals = _.values(params);
    if (paramVals.length !== _.without(paramVals, false, null, '', undefined, NaN).length) {
      return { error: 'One or more variables not set' };
    }
  }

  if (!_.isEmpty(headers)) {
    computedHeaders = _.map(headers, (value, key) => {
      if (value) {
        return { name: key, value };
      }
    });

    if (_.has(headers, 'Content-Type')) {
      _.set(postData, 'mimeType', headers['Content-Type']);
    }
  }

  const replacedAuth = replaceVariables(auth, params);
  if (replacedAuth.type === 'basic' && replacedAuth.username) {
    const basicAuth = window.btoa(`${replacedAuth.username}:${replacedAuth.password}`);
    computedHeaders.push({
      name: 'Authorization',
      value: `Basic ${basicAuth}`,
    });
  }

  // postData params is only relevant to form-urlencoded
  if (!_.isEmpty(body)) {
    if (
      _.isObject(body) &&
      _.get(headers, 'Content-Type') === 'application/x-www-form-urlencoded'
    ) {
      const params = _.map(body, (value, key) => {
        return { name: key, value };
      });
      _.set(postData, 'params', params);
    } else {
      _.set(postData, 'text', safeStringify(safeParse(body), 0));
    }
  }

  // params need to be strings. http-snippet will choke if use a number as a header value, for example.
  for (const k in params) {
    params[k] = String(params[k]);
  }

  _.set(data, 'method', method.toUpperCase());
  _.set(data, 'url', replacePathParams(safeStringify(u), params));
  _.set(data, 'headers', replaceVariables(_.compact(computedHeaders), params));
  _.set(data, 'postData', replaceVariables(postData, params));

  if (!/^[a-z]+:\/\//i.test(data.url)) {
    data.url = (schemes[0] || 'http') + '://' + data.url;
  }

  try {
    snippet = new HTTPSnippet(data);

    const { codechoice, librarychoice } = options;

    snippet = snippet.convert(codechoice, librarychoice);
  } catch (err) {
    console.error('code generation error', { props, options, err });
    return { error: 'Could not generate code. More information in the developer console.' };
  }

  return snippet;
};
