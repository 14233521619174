import React from 'react';
import _ from 'lodash';
import { Menu, Segment, Icon, Popup, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import CapturesTab, { CapturesTabItem } from './tabs/captures';
import ScriptsTab, { ScriptsTabItem } from './tabs/scripts';
import RefBuilder from '../../RefBuilder';

const defaultLogicLocations = [
  'output.headers',
  'output.body',
  'output.status',
  'output.time',
  '$.ctx',
  '$$.env',
  '$$.response.headers',
  '$$.response.body',
  '$$.response.status',
];

function RefInput(props) {
  const {
    id,
    editorId,
    collection,
    scenarioId,
    step = {},
    ui = {},
    updateUi,

    headerElems = [],
    handleUpdate,
    readOnly,
    autocompleteScopes = {},
  } = props;

  let pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  if (scenarioId && scenarioId !== 'undefined') {
    pathname = pathname.replace(new RegExp(`/${scenarioId}$`), '');
    if (pathname.charAt(0) === '/') {
      pathname = pathname.substring(1);
    }
  }

  const stepRef = _.get(step, ['input', '$ref']);

  const inputTab = ui.hasOwnProperty('inputTab') ? ui.inputTab : 'captures';
  const handleInputTabClick = (e, { name }) => {
    updateUi('set', ['inputTab'], name === inputTab ? null : name);
  };
  let contentElem;
  switch (inputTab) {
    case 'captures':
      contentElem = (
        <CapturesTab
          fields={_.get(step, 'after.transforms')}
          handleUpdate={(t, p, v) => {
            handleUpdate(t, ['after', 'transforms'].concat(p), v);
          }}
          logicLocations={defaultLogicLocations}
          readOnly={readOnly}
        />
      );
      break;
    case 'scripts':
      contentElem = (
        <ScriptsTab
          step={step}
          handleUpdate={handleUpdate}
          readOnly={readOnly}
          autocompleteScopes={{ ref: true, ...autocompleteScopes }}
        />
      );
      break;
    default:
      contentElem = <div>Please select a tab.</div>;
      break;
  }

  return (
    <div className="ScenarioStepInput type-ref">
      {readOnly ? (
        <Message info size="small">
          <Icon name="info" /> This editor is in read only mode.
        </Message>
      ) : null}

      <div className="ScenarioStepInput-methodUrl flex">
        {headerElems.map((elem, i) => (
          <div key={elem.key || i} className="mr-3">
            {elem.render()}
          </div>
        ))}

        <div className="flex flex-1 items-center">
          <RefBuilder
            id={id}
            className="flex-1"
            localData={collection}
            initialSource="local"
            $ref={stepRef || ''}
            fileFilter={{ scenario: [/scenario/] }}
            routeDataTargets={{ scenario: ['scenarios', 'utilities'] }}
            targets
            onComplete={value => {
              const val = _.split(value, '/');
              handleUpdate('set', ['input'], {
                name: `${_.last(val)} - ${val[_.size(val) - 2]}`,
                $ref: value,
              });
            }}
          />
        </div>
      </div>

      <Menu id="tutorial-scenario-input" attached="top" tabular size="small">
        <CapturesTabItem
          step={step}
          active={inputTab === 'captures'}
          onClick={handleInputTabClick}
        />

        <ScriptsTabItem step={step} active={inputTab === 'scripts'} onClick={handleInputTabClick} />
      </Menu>

      <Segment attached="bottom">{contentElem}</Segment>
    </div>
  );
}

export default inject(({ routerStore, appStore, collectionEditorStore }) => ({
  routerStore,
  ...appStore.injectUi('ref-step'),
}))(observer(RefInput));
