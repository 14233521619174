import React from 'react';
import { inject } from 'mobx-react';
import _ from 'lodash';
import { registerLogger } from '@platform/utils/logging';
const log = registerLogger('components', 'JsonPathCacheParentContainer');

const JsonPathCacheParentContainer = (props = {}) => BaseComponent => {
  const {
    cacheKey,
    cacheKeyProp,
    basePath,
    basePathProp,
    path,
    pathProp,
    children,
    childrenProp,
  } = props;

  class JsonPathCacheParent extends React.Component {
    componentWillMount() {
      this.registerChildren(this.props);
    }

    componentWillReceiveProps(nextProps) {
      const data = this.props.jsonPathContainerData || {};
      const nextData = nextProps.jsonPathContainerData || {};

      if (
        !_.isEqual(data.cacheKey, nextData.cacheKey) ||
        !_.isEqual(data.basePath, nextData.basePath) ||
        !_.isEqual(data.path, nextData.path) ||
        !_.isEqual(_.size(data.children), _.size(nextData.children))
      ) {
        this.registerChildren(nextProps);
      }
    }

    // sets up the cache for all the child blocks, so that we have their keys in
    // advance to use
    registerChildren(props) {
      const { jsonPathContainerData = {} } = props;
      const { store, cacheKey, basePath, path, children } = jsonPathContainerData;

      store.registerChildren({
        cacheKey,
        basePath,
        path,
        children,
      });
    }

    render() {
      const { jsonPathContainerData = {}, ...baseProps } = this.props;
      const { store, cacheKey, basePath, path } = jsonPathContainerData;

      const childCacheIds = store.getChildrenCacheIds({ cacheKey, basePath, path });

      log.debug(`passing childCacheIds to ${BaseComponent.displayName}`, childCacheIds, {
        cacheKey,
        basePath,
        path,
      });

      return <BaseComponent {...baseProps} childCacheIds={childCacheIds} />;
    }
  }

  return inject(({ jsonPathCache }, props) => {
    const ck = cacheKey || _.get(props, cacheKeyProp);
    const bp = basePath || _.get(props, basePathProp);
    const p = path || _.get(props, pathProp);
    const c = children || _.get(props, childrenProp);

    return {
      jsonPathContainerData: {
        store: jsonPathCache,
        cacheKey: ck,
        basePath: bp,
        path: p,
        children: c,
      },
    };
  })(JsonPathCacheParent);
};

export default JsonPathCacheParentContainer;
