export const modeObjs = {
  c: 'text/x-csrc',
  cpp: 'text/x-c++src',
  csharp: 'text/x-csharp',
  css: 'text/css',
  go: 'text/x-go',
  html: 'text/html',
  http: 'message/http',
  java: 'text/x-java',
  js: 'text/javascript',
  json: 'application/json',
  jsx: 'text/jsx',
  markdown: 'text/x-markdown',
  objectivec: 'text/x-objectivec',
  perl: 'text/x-perl',
  php: 'text/x-php',
  python: 'text/x-python',
  ruby: 'text/x-ruby',
  scala: 'text/x-scala',
  shell: 'text/x-sh',
  sql: 'text/x-sql',
  swift: 'text/x-swift',
  xml: 'application/xml',
  yaml: 'text/x-yaml',
};

export const modeOptions = [
  { text: 'C', value: 'c' },
  { text: 'C++', value: 'cpp' },
  { text: 'C#', value: 'csharp' },
  { text: 'CSS', value: 'css' },
  { text: 'Go', value: 'go' },
  { text: 'HTML', value: 'html' },
  { text: 'HTTP', value: 'http' },
  { text: 'Java', value: 'java' },
  { text: 'JavaScript', value: 'js' },
  { text: 'JSON', value: 'json' },
  { text: 'JSX', value: 'jsx' },
  { text: 'Markdown', value: 'markdown' },
  { text: 'Objective-C', value: 'objectivec' },
  { text: 'Perl', value: 'perl' },
  { text: 'PHP', value: 'php' },
  { text: 'Python', value: 'python' },
  { text: 'Ruby', value: 'ruby' },
  { text: 'Scala', value: 'scala' },
  { text: 'Shell', value: 'shell' },
  { text: 'SQL', value: 'sql' },
  { text: 'Swift', value: 'swift' },
  { text: 'XML', value: 'xml' },
  { text: 'YAML', value: 'yaml' },
];
