import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import scrollWrapper from '@platform/components/ScrollWrapper/hoc';
import SortableTreeList from '@platform/components/SortableTreeList';

import { HubBranding } from '../HubBranding';

class HubSidebar extends React.Component {
  renderOverlay = () => {
    const { ui, updateUi } = this.props;

    return (
      <div
        key="overlay"
        role="presentation"
        className={cn('HubSidebar-overlay pin fixed sm:block hidden', {
          'isMobile-open': ui.showSidebar,
          'isMobile-closed': !ui.showSidebar,
        })}
        onClick={() => {
          updateUi('unset', 'showSidebar');
        }}
      />
    );
  };

  renderWrapper = ({ sidebarTree }) => {
    const {
      store,
      className = '',
      folderOpenIcon = 'chevron down',
      folderClosedIcon = 'chevron right',
      ui,
      updateUi,
      watchScroll,
    } = this.props;

    const {
      checkSidebarItemIsActive,
      checkSidebarChildIsActive,
      whitelabel,
      namespace,
      activePagePath,
    } = store;
    const id = activePagePath;

    if (!sidebarTree || _.isEmpty(sidebarTree)) {
      return null;
    }

    let navHeight = 0;
    let height = '100%';
    let maxHeight = 'auto';
    let pinned = false;

    if (watchScroll && this.wrapperElem && !ui.showSidebar) {
      const pageHeader = document.getElementById('HubPageHeader');
      if (pageHeader) {
        navHeight = pageHeader.offsetHeight;
      }

      const rect = this.wrapperElem.getBoundingClientRect();
      maxHeight = rect.height;
      height = window.innerHeight - Math.max(rect.top, navHeight);
      pinned = rect.top <= navHeight;
    }

    let sidebarStyles = {
      maxHeight,
    };

    if (pinned) {
      sidebarStyles = {
        ...sidebarStyles,
        height,
        position: 'fixed',
        left: 'auto',
        bottom: 0,
      };
    }

    return (
      <div
        key="wrapper"
        className={cn(
          'HubSidebar-wrapper print:hidden flex flex-col items-end bg-grey-lightest border-r border-grey-light',
          className,
          {
            'isMobile-open': ui.showSidebar,
            'isMobile-closed': !ui.showSidebar,
          }
        )}
        onClick={e => {
          // TODO (MM): Should close sidebar on route change, not with function below. This would work for folders, etc.
          // trigger based on class name since the sidebar is made up of only nested div
          // does not trigger on folder selection since there currently isn't a way to see if it contains children
          const trigger = _.intersection(e.target.classList, [
            'SortableTree-itemName',
            'TreeList-item',
            'TreeList-itemToken',
            'SortableTree-itemToken-inner',
          ]).length;

          if (trigger) {
            updateUi('unset', 'showSidebar');
          }
        }}
        ref={elem => {
          this.wrapperElem = elem;
        }}
      >
        {pinned && <div role="presentation" className="HubSidebar-spacer" />}

        <div className={cn('HubSidebar flex-1', className)} style={sidebarStyles}>
          <div className="HubSidebar-inner flex flex-col">
            <SortableTreeList
              id={id}
              data={_.get(sidebarTree, [0, 'treeData'], {})}
              currentPath={[activePagePath]}
              folderOpenIcon={folderOpenIcon}
              folderClosedIcon={folderClosedIcon}
              checkIsActive={checkSidebarItemIsActive}
              checkIsChildActive={checkSidebarChildIsActive}
              viewer
            />
          </div>
        </div>

        {!whitelabel && <HubBranding whitelabel={whitelabel} namespace={namespace} />}
      </div>
    );
  };

  render() {
    const { store } = this.props;

    const { sidebarTree } = store;

    if (!sidebarTree || _.isEmpty(sidebarTree)) {
      return null;
    }

    return [this.renderOverlay(), this.renderWrapper({ sidebarTree })];
  }
}

export default inject(stores => {
  const { appStore } = stores;

  // Inject HubSidebar so we can control the mobile sidebar
  return {
    ...appStore.injectUi(`Hub`),
  };
})(scrollWrapper(observer(HubSidebar, { onResize: true })));
