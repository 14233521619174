import React from 'react';
import { Message } from 'semantic-ui-react';

import ScenarioOutput from './scenario';
import HttpOutput from './http';
import ScriptOutput from './script';
import RefOutput from './ref';

function Step(props) {
  const { id, type, result = {} } = props;

  let editorElem;
  switch (type) {
    case 'scenario':
      editorElem = <ScenarioOutput {...props} id={id} result={result} />;
      break;
    case 'http':
      editorElem = <HttpOutput {...props} id={id} result={result} />;
      break;
    case 'script':
      editorElem = <ScriptOutput {...props} id={id} result={result} />;
      break;
    case 'ref':
      editorElem = <RefOutput {...props} id={id} result={result} />;
      break;
    default:
      editorElem = <Message negative>Invalid step type {type}.</Message>;
  }

  return editorElem;
}

export default Step;
