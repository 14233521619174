/* eslint-disable */

import CodeMirror from 'codemirror';

// global required by json-lint code mirror lib imported below
import jsonlint from './jsonlint';
global.jsonlint = jsonlint;

// global required by javascript-lint code mirror lib imported below
// if (typeof window !== 'undefined') {
//   import('jshint' /* webpackChunkName: 'jshint' */)
//     .then(({ JSHINT }) => {
//       window.JSHINT = JSHINT;
//       require('codemirror/addon/lint/javascript-lint');
//     })
//     .catch(error => {
//       console.log('Error loading jshint:', error);
//     });
// }

import 'codemirror/addon/display/autorefresh';

import 'codemirror/mode/clike/clike';
import 'codemirror/mode/css/css';
import 'codemirror/mode/go/go';
import 'codemirror/mode/http/http';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/jsx/jsx';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/perl/perl';
import 'codemirror/mode/php/php';
import 'codemirror/mode/python/python';
import 'codemirror/mode/ruby/ruby';
import 'codemirror/mode/shell/shell';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/swift/swift';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/diff/diff';

import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/fold/markdown-fold';

import 'codemirror/addon/hint/show-hint';
import './custom-hint';

import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/scroll/annotatescrollbar';

import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/comment/comment';

import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';

import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';

import 'codemirror/keymap/sublime';
import 'codemirror/keymap/emacs';
import 'codemirror/keymap/vim';

// FOR SIMPLEMDE
import 'codemirror/addon/edit/continuelist';
import 'codemirror/addon/display/fullscreen';
import 'codemirror/addon/mode/overlay';
import 'codemirror/addon/selection/mark-selection';
import 'codemirror/mode/gfm/gfm';

export default CodeMirror;
