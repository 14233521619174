import React from 'react';
import _ from 'lodash';

import FormSearch from '../FormSearch';
import FormInputLabel from '../FormInputLabel';

class ConnectedOperationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      value: undefined,
    };
  }

  componentWillMount() {
    this.buildResults({ operations: this.props.operations, all: true });
  }

  resetComponent = () => {
    this.setState({ results: [] });
  };

  handleResultSelect = (e, result) => {
    const { handleResultSelect, operations, valueProp, onChange } = this.props;

    let value = result.title;
    const operation = operations[result.key];

    if (operation) {
      if (handleResultSelect) {
        handleResultSelect(operation);
      }

      if (valueProp) {
        value = operation[valueProp] || value;
      }
    }

    if (onChange && !handleResultSelect) {
      onChange(e, { value });
    }

    this.setState({ value });
  };

  handleSearchChange = (e, value) => {
    const { operations, onChange, protectedExpression } = this.props;

    if (onChange) {
      onChange(e, { value });
    }

    if (protectedExpression) {
      const re1 = new RegExp(protectedExpression, 'i');

      if (re1.test(value)) {
        return;
      }
    }

    const re = new RegExp(_.escapeRegExp(value), 'i');

    const isMatch = (operation, result) => {
      return (
        re.test(operation.path) ||
        re.test(operation.url) ||
        re.test(operation.method) ||
        re.test(operation.summary) ||
        re.test(operation.operationId) ||
        re.test(result.title) ||
        re.test(result.description)
      );
    };

    this.buildResults({ operations, isMatch });
  };

  buildResults = ({ operations, isMatch, all }) => {
    let results;

    for (let key in operations) {
      if (!operations[key]) continue;

      const operation = operations[key];

      const result = {
        key,
        title: `${_.toUpper(operation.method)} ${operation.path}`,
        description: `${
          operation.operationId ? `${operation.operationId} - ` : ''
        }${operation.summary || operation.description || ''}`,
      };

      // if connected specs/operations have ids, sort into categories by id
      if (operation.specId) {
        if (all || isMatch(operation, result)) {
          if (_.get(results, [operation.specId, 'results'])) {
            results[operation.specId].results.push(result);
          } else {
            if (!results) results = {};

            results[operation.specId] = {
              name: operation.specName,
              results: [result],
            };
          }
        }
      } else {
        if (!results) results = [];
        results.push(result);
      }
    }

    this.setState({ results });
  };

  render() {
    const { results, value: uncontrolledValue } = this.state;
    const {
      value: contolledValue,
      className,
      placeholder,
      label,
      required,
      tip,
      readOnly,
    } = this.props;

    const inputVal =
      contolledValue ||
      uncontrolledValue ||
      _.get(results, [0, 'title']) ||
      _.get(results, [Object.keys(results)[0], 'results', 0, 'title']);

    return (
      <div className={className}>
        <FormInputLabel label={label} required={required} tip={tip} />

        <FormSearch
          minCharacters={0}
          placeholder={placeholder}
          value={inputVal}
          results={results}
          onSearchChange={this.handleSearchChange}
          onResultSelect={this.handleResultSelect}
          readOnly={readOnly}
          showResultsOnClick
        />
      </div>
    );
  }
}

export default ConnectedOperationSearch;
