import React from 'react';
import { inject, observer } from 'mobx-react';
import { Menu, Segment } from 'semantic-ui-react';
import _ from 'lodash';

import ScenarioLog from './log';

const ScenarioLogs = ({ ui, updateUi, logs = [] }) => {
  const menuElems = [];
  const filtered = {
    fatal: {
      logs: _.filter(logs, { type: 'fatal' }),
      className: 'c-error',
      name: 'Fatal',
    },
    error: {
      logs: _.filter(logs, { type: 'error' }),
      className: 'c-error',
      name: 'Error',
    },
    warn: {
      logs: _.filter(logs, { type: 'warn' }),
      className: 'c-warn',
      name: 'Warning',
    },
    info: {
      logs: _.filter(logs, { type: 'info' }),
      className: 'text-active',
      name: 'Info',
    },
  };

  // first one with logs is default active
  let defaultActive;
  for (const k in filtered) {
    const f = filtered[k];
    if (!_.isEmpty(f.logs)) {
      defaultActive = k;
      break;
    }
  }

  const active = _.get(ui, 'isActive', defaultActive);
  _.forEach(filtered, (f, k) => {
    if (!_.isEmpty(f.logs)) {
      menuElems.push(
        <Menu.Item
          key={k}
          active={k === active}
          onClick={() => {
            updateUi('set', 'isActive', k);
          }}
        >
          <span className="mr-3">{f.name}</span>
          <b>
            <span className={f.className}> [{f.logs.length}]</span>
          </b>
        </Menu.Item>
      );
    }
  });

  const activeLogs = _.get(filtered, [active, 'logs']);
  const logElems = _.map(activeLogs, (l, i) => {
    return <ScenarioLog key={i} log={l} />;
  });

  return [
    <Menu key="1" id="tutorial-scenario-input" attached="top" tabular size="small">
      {menuElems}
    </Menu>,

    <Segment key="2" attached="bottom">
      {logElems}
    </Segment>,
  ];
};

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(`${id}:ScenarioLogs`),
  };
})(observer(ScenarioLogs));
