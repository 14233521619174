import React from 'react';
import _ from 'lodash';

import { Menu } from 'semantic-ui-react';

import FormInputMap from '../../../FormInputMap';

export const HeadersTabItem = ({ step, active, onClick }) => {
  const headers = _.get(step, 'input.headers');

  let headerName = 'Headers';
  if (!_.isEmpty(headers)) {
    headerName = (
      <span>
        Headers{' '}
        <b>
          <span className="ml-2 text-active">[{_.size(headers)}]</span>
        </b>
      </span>
    );
  }

  return (
    <Menu.Item name="headers" active={active} onClick={onClick}>
      {headerName}
    </Menu.Item>
  );
};

const Headers = ({ step, handleUpdate, readOnly }) => {
  return [
    <FormInputMap
      key="headersList"
      fields={_.get(step, 'headers')}
      handleUpdate={(t, p, v) => {
        handleUpdate(t, ['input', 'headers'].concat(p), v);
      }}
      addText="Add Header"
      namePlaceholder="header name"
      valuePlaceholder="header value"
      size="tiny"
      readOnly={readOnly}
    />,
  ];
};

export default Headers;
