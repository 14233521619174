import React from 'react';
import FormSelect from '@platform/components/FormSelect';

const Host = props => {
  const { value, hosts, label, onChange, readOnly } = props;

  const options = _.uniqBy(
    [{ text: value, value }].concat(
      hosts.map(p => ({
        text: p,
        value: p,
      }))
    ),
    'value'
  );

  return (
    <div className="pl-2 pr-2" style={{ maxWidth: 350 }}>
      <FormSelect
        className="ScenarioStepInput-hosts"
        label={label}
        placeholder="target host"
        value={value}
        options={options}
        onChange={host => onChange(host)}
        disabled={readOnly}
        allowAdditions
        search
      />
    </div>
  );
};

export default Host;
