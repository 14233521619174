import _ from 'lodash';
import { observable } from 'mobx';
import { safeStringify } from '@platform/utils/json';

export const initCollectionEditor = (props, { force } = {}) => {
  const { id, data } = props;

  const input = data || {
    method: 'get',
    url: '',
  };

  const entity = {
    _id: id,
    id,
    data: {
      scenarios: {
        dummy: {
          steps: [
            {
              id,
              type: 'http',
              input,
            },
          ],
        },
      },
    },
  };

  const editor = getCollectionEditor(props);
  // only init the editor if its new or the original data has changed
  if (editor.id) {
    const entityString = safeStringify(entity.data);
    if (editor.original === entityString) {
      return;
    }
  }

  props.collectionEditorStore.initEditor(
    {
      entityType: 'collections',
      editPath: `#/scenarios/dummy/steps/0`,
      embedded: true,
      entity,
      stepPathParams: observable.map({ 'dummy-0': _.get(input, 'stepPathParams', {}) }),
    },
    { force: true }
  );
};

export const getCollectionEditor = props => {
  const { id, collectionEditorStore } = props;

  return (
    collectionEditorStore.getEditor({
      id,
    }) || {}
  );
};

export const stopStep = props => {
  const editor = getCollectionEditor(props);
  if (editor && editor.stopStep) {
    editor.stopStep();
  }
};

export const runStep = props => {
  const editor = getCollectionEditor(props);
  if (editor && editor.runStep) {
    editor.runStep();
  }
};
