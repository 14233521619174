import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Menu, Segment, Icon } from 'semantic-ui-react';

import { safeStringify } from '@platform/utils/json';

import HttpBodyViewer from '../../HttpBodyViewer';
import CodeEditor from '../../CodeEditor';
import Highlight from '../../Highlight';
import InputResultList from '../../InputResultList';

const HttpOutput = props => {
  const { id, ui, updateUi, className, result = {}, tabWhitelist, noTypeSelector } = props;

  if (_.isEmpty(result)) {
    return null;
  }

  let { activeResultTab = 'body' } = ui;
  const request = result.input || {};
  const response = result.output || {};
  const assertions = _.get(result, 'before.assertions', []).concat(
    _.get(result, 'after.assertions', [])
  );
  const logs = result.logs || [];

  if (tabWhitelist && !_.includes(tabWhitelist, activeResultTab)) {
    activeResultTab = 'body';
  }

  const handleItemClick = (e, { name }) => {
    updateUi('set', ['activeResultTab'], name);
  };

  const responseContentType = response.headers ? response.headers['Content-Type'] : '';

  // BUILD THE TAB CONTENT

  let contentElem;
  switch (activeResultTab) {
    case 'body':
      contentElem = (
        <HttpBodyViewer
          name={`${id}-body`}
          contentType={responseContentType}
          value={safeStringify(_.get(response, 'body', ''))}
          uiCache={ui.body}
          uiCacheScope={['body']}
          updateUiCache={updateUi}
          noTypeSelector={noTypeSelector}
        />
      );
      break;
    case 'headers':
      contentElem = <InputResultList fields={_.get(response, 'headers')} sort />;
      break;
    case 'request':
      const headerLines = _.map(request.headers || {}, (value, name) => `${name}: ${value}`).join(
        '\n'
      );
      const requestString = `${(request.method || 'get').toUpperCase()} ${request.url}

Headers:
  ${_.isEmpty(headerLines) ? '{}' : headerLines}

Body:
  ${safeStringify(_.get(request, 'body') || 'undefined')}
  `;
      contentElem = (
        <div className="p-3">
          <div className="c-muted mb-6">
            The http request as it was sent, after all transformations, scripts, and variable
            replacements were run.
          </div>

          <CodeEditor
            name="final-request"
            value={requestString}
            autogrow
            maxLines={50}
            noFill
            readOnly
            lint={false}
          />
        </div>
      );
      break;
    case 'tests':
      if (_.isEmpty(assertions)) {
        contentElem = (
          <div className="c-muted">
            {`No tests. Use the tests tab above to add assertions. Use the
            "tests" object in scripts for added flexibility.`}
          </div>
        );
      } else {
        contentElem = (
          <div>
            {assertions.map((a, i) => {
              let assertionClass;
              let icon = 'circle';
              if (a.pass) {
                assertionClass = 'passed';
                icon = 'check';
              } else if (a) {
                assertionClass = 'failed';
                icon = 'remove';
              }

              let expectedElem;
              if (typeof a.expected === 'object') {
                expectedElem = '[schema object not shown for now]';
              } else {
                expectedElem = a.expected;
              }

              return (
                <div key={i} className={cn('AssertionResult flex items-center', assertionClass)}>
                  <div className="AssertionResult-icon">
                    <Icon name={icon} />
                  </div>
                  <div className="AssertionResult-content flex-1">
                    <div>
                      {a.target} <b className="ml-3 mr-3">[{a.op}]</b> {expectedElem}
                    </div>
                    {a.msg ? <div className="AssertionResult-message">{a.msg}</div> : null}
                    {a.details ? <div className="AssertionResult-message">{a.details}</div> : null}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      break;
    case 'capture':
      contentElem = (
        <div>
          <div className="p-3">
            <div className="c-muted mb-6">
              $.ctx <i>after</i> step:
            </div>

            <Highlight mode="json" immediate>
              {_.get(result, 'ctx', '{}')}
            </Highlight>
          </div>

          <div className="pt-3 pl-3 pr-3">
            <div className="c-muted mb-6">
              $$.env <i>after</i> step:
            </div>

            <Highlight mode="json" immediate>
              {_.get(result, 'env', '{}')}
            </Highlight>
          </div>
        </div>
      );
      break;
    default:
      contentElem = <div>Please select a tab.</div>;
      break;
  }

  // BUILD THE TABS

  // BODY TAB

  const status = String(_.get(response, 'status') || 'na');
  const bodyChar = status.charAt(0);
  let bodyClass = 'text-active';
  if (bodyChar === '2') {
    bodyClass = 'c-positive';
  } else if (_.includes(['4', '5'], bodyChar)) {
    bodyClass = 'c-negative';
  }
  const bodyName = (
    <span>
      Body <span className={cn('ml-3', bodyClass)}>[{status}]</span>{' '}
      {response.time ? (
        <span className="c-muted ml-3">
          {response.time}
          ms
        </span>
      ) : (
        ''
      )}
    </span>
  );

  // HEADERS TAB

  const headerName = (
    <span>
      Headers{' '}
      <b>
        <span className="ml-2 text-active">[{_.size(_.get(response, 'headers') || {})}]</span>
      </b>
    </span>
  );

  // INPUT TAB

  const requestName = <span>Original Request</span>;

  // TESTS TAB

  let passElem;
  if (result.passCount > 0) {
    passElem = (
      <span className="c-positive ml-2">
        <Icon fitted name="check" /> {result.passCount}
      </span>
    );
  }
  let failedElem;
  if (result.failCount > 0) {
    failedElem = (
      <span className="c-negative ml-2">
        <Icon fitted name="remove" /> {result.failCount}
      </span>
    );
  }
  const testName = (
    <span>
      Tests{' '}
      <b>
        {passElem} {failedElem}
      </b>
    </span>
  );
  const testTitleElem = (
    <Menu.Item name="tests" active={activeResultTab === 'tests'} onClick={handleItemClick}>
      {testName}
    </Menu.Item>
  );

  // CAPTURE TAB

  let captureName = 'Capture';

  if (tabWhitelist && !_.includes(tabWhitelist, activeResultTab)) {
    activeResultTab = 'body';
  }

  const menuElem = (
    <Menu attached="top" tabular>
      {!tabWhitelist || _.includes(tabWhitelist, 'body') ? (
        <Menu.Item name="body" active={activeResultTab === 'body'} onClick={handleItemClick}>
          {bodyName}
        </Menu.Item>
      ) : null}

      {!tabWhitelist || _.includes(tabWhitelist, 'headers') ? (
        <Menu.Item name="headers" active={activeResultTab === 'headers'} onClick={handleItemClick}>
          {headerName}
        </Menu.Item>
      ) : null}

      {!tabWhitelist || _.includes(tabWhitelist, 'tests') ? testTitleElem : null}

      {!tabWhitelist || _.includes(tabWhitelist, 'capture') ? (
        <Menu.Item name="capture" active={activeResultTab === 'capture'} onClick={handleItemClick}>
          {captureName}
        </Menu.Item>
      ) : null}

      {!tabWhitelist || _.includes(tabWhitelist, 'request') ? (
        <Menu.Item name="request" active={activeResultTab === 'request'} onClick={handleItemClick}>
          {requestName}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <div id="tutorial-http-output" className={cn('HttpOutput', className)}>
      {menuElem}

      <Segment attached="bottom">{contentElem}</Segment>
    </div>
  );
};

export default inject(({ appStore }, { id }) => ({
  ...appStore.injectUi(`http-output-${id}`),
}))(observer(HttpOutput));
