import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button, Checkbox } from 'semantic-ui-react';

import { updateInputWithAuth } from '@platform/utils/authorization';

import FormInput from '../../../../FormInput';
import FormCheckbox from '../../../../FormCheckbox';
import FormSelect from '../../../../FormSelect';

import OAuth2 from './oauth2';

const OAuth1SignatureSelector = props => {
  return (
    <FormSelect
      {...props}
      header="Signature Method"
      options={[
        { text: 'HMAC-SHA1', value: 'HMAC-SHA1' },
        { text: 'HMAC-SHA256', value: 'HMAC-SHA256' },
        { text: 'PLAINTEXT', value: 'PLAINTEXT' },
      ]}
      defaultValue="HMAC-SHA1"
      placeholder="OAuth1 Signature Method"
      size="tiny"
    />
  );
};

const AuthSelector = props => {
  return (
    <FormSelect
      {...props}
      options={[
        { text: 'No Auth', value: 'none' },
        { text: 'Basic Auth', value: 'basic' },
        { text: 'Digest Auth', value: 'digest' },
        { text: 'OAuth 1.0', value: 'oauth1' },
        { text: 'OAuth 2.0', value: 'oauth2' },
        { text: 'AWS Signature', value: 'aws' },
      ]}
      placeholder="Auth Type"
      size="tiny"
    />
  );
};

class AuthTab extends React.Component {
  renderAws = () => {
    const { input = {}, handleUpdate, readOnly } = this.props;

    const { auth = {} } = input;

    return (
      <div className="text-sm">
        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['accessKey']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'accessKey'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Access Key',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['secretKey']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'secretKey'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Secret Key',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['region']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'region'], value);
              },
              readOnly,
            }}
            label={{
              content: 'AWS Region',
              basic: true,
              className: 'Label-fw',
            }}
            placeholder="for example, `us-east-1`"
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['service']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'service'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Service Name',
              basic: true,
              className: 'Label-fw',
            }}
            placeholder="for example, `sqs`"
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['sessionToken']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'sessionToken'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Session Token',
              basic: true,
              className: 'Label-fw',
            }}
            placeholder="optional"
            fluid
          />
        </div>
      </div>
    );
  };

  renderBasicAuth = () => {
    const { input = {}, handleUpdate, ui = {}, uiScope, updateUi, readOnly } = this.props;

    const { auth = {} } = input;

    return (
      <div className="text-sm max-w-md">
        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['username']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'username'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Username',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div className="mb-3">
          <FormInput
            input={{
              type: ui.showPassword ? 'text' : 'password',
              value: _.get(auth, ['password']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'password'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Password',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div>
          <Checkbox
            label="Show Password"
            value={String(ui.showPassword)}
            onChange={(name, e) => {
              updateUi('set', uiScope.concat(['showPassword']), e.checked);
            }}
            disabled={readOnly}
          />
        </div>
      </div>
    );
  };

  renderOAuth1 = () => {
    const { input = {}, handleUpdate, readOnly } = this.props;

    const { auth = {} } = input;

    return (
      <div className="text-sm max-w-md">
        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['consumerKey']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'consumerKey'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Consumer Key',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['consumerSecret']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'consumerSecret'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Consumer Secret',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['token']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'token'], value);
              },
              readOnly,
            }}
            label={{ content: 'Token', basic: true, className: 'Label-fw' }}
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['tokenSecret']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'tokenSecret'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Token Secret',
              basic: true,
              className: 'Label-fw',
            }}
            fluid
          />
        </div>

        <div className="mb-2">
          <FormInput
            input={{
              value: _.get(auth, ['nonceLength']),
              onChange(e, { value }) {
                handleUpdate('set', ['input', 'auth', 'nonceLength'], value);
              },
              readOnly,
            }}
            label={{
              content: 'Nonce Length',
              basic: true,
              className: 'Label-fw',
            }}
            placeholder="if blank, generates a 32 character nonce"
            fluid
          />
        </div>

        <div className="mb-3">
          <OAuth1SignatureSelector
            value={_.get(auth, ['signatureMethod'])}
            onChange={val => {
              handleUpdate('set', ['input', 'auth', 'signatureMethod'], val);
            }}
          />
        </div>

        <div className="mb-2">
          <FormCheckbox
            label="Add as header"
            disabled={readOnly}
            checked={_.get(auth, ['useHeader'])}
            onChange={checked => {
              if (checked) {
                handleUpdate('set', ['input', 'auth', 'useHeader'], checked);
              } else {
                handleUpdate('unset', ['input', 'auth', 'useHeader']);
              }
            }}
          />
        </div>

        <div>
          <FormCheckbox
            label="Encode OAuth Signature"
            disabled={readOnly}
            checked={_.get(auth, ['encode'])}
            onChange={checked => {
              if (checked) {
                handleUpdate('set', ['input', 'auth', 'encode'], checked);
              } else {
                handleUpdate('unset', ['input', 'auth', 'encode']);
              }
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      input = {},
      handleUpdate,
      submitting,
      currentVariables,
      readOnly,
      ui,
      uiScope,
      updateUi,
      userId,
      activeToken,
      editor,
      config,
    } = this.props;

    const { auth = {} } = input;

    // MM TODO: these auth sections should be in their own components

    let canUpdateAuth = false;

    let contentElem;

    switch (auth.type) {
      case 'basic':
        canUpdateAuth = true;
        contentElem = this.renderBasicAuth();
        break;

      case 'digest':
        contentElem = <div className="text-sm">Coming soon.</div>;
        break;

      case 'oauth1':
        canUpdateAuth = true;
        contentElem = this.renderOAuth1();
        break;

      case 'oauth2':
        contentElem = (
          <OAuth2
            ui={ui}
            uiScope={uiScope}
            updateUi={updateUi}
            userId={userId}
            input={input}
            config={config}
            editor={editor}
            activeToken={activeToken}
            currentVariables={currentVariables}
            handleUpdate={handleUpdate}
          />
        );
        break;

      case 'aws':
        canUpdateAuth = true;
        contentElem = this.renderAws();
        break;

      default:
        contentElem = null;
    }

    return (
      <div>
        <div className={`flex content-center ${auth.type ? 'mb-6' : ''}`}>
          <AuthSelector
            className="mr-6"
            value={auth.type}
            disabled={readOnly}
            onChange={e => {
              switch (e) {
                case 'basic':
                  handleUpdate('set', ['input', 'auth'], {
                    type: 'basic',
                    username: '',
                    password: '',
                  });
                  break;
                case 'digest':
                  handleUpdate('set', ['input', 'auth'], {
                    type: 'digest',
                  });
                  break;
                case 'oauth1':
                  handleUpdate('set', ['input', 'auth'], {
                    type: 'oauth1',
                    consumerKey: '',
                    consumerSecret: '',
                    token: '',
                    tokenSecret: '',
                    nonceLength: '',
                    signatureMethod: 'HMAC-SHA1',
                    encode: true,
                  });
                  break;
                case 'oauth2':
                  handleUpdate('set', ['input', 'auth'], {
                    type: 'oauth2',
                  });
                  break;
                case 'aws':
                  handleUpdate('set', ['input', 'auth'], {
                    type: 'aws',
                  });
                  break;
                default:
                  handleUpdate('unset', ['input', 'auth']);
              }
            }}
          />

          {canUpdateAuth ? (
            <Button
              disabled={submitting}
              onClick={() => {
                handleUpdate('set', ['input'], updateInputWithAuth(input, auth, currentVariables));
              }}
              compact
              basic
              size="tiny"
            >
              Update Request
            </Button>
          ) : null}
        </div>

        {contentElem}
      </div>
    );
  }
}

export default inject((stores, props) => {
  const { userService, projectService } = stores;

  return {
    projectId: _.get(projectService, 'current.id'),
    userId: _.get(userService, 'authorizedUser.id'),
  };
})(observer(AuthTab));
