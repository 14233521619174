import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Menu } from 'semantic-ui-react';
import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';

import BlockList from '../BlockList/viewer';

import { getActiveTab, handleChangeTab } from './index';

class TabsBlock extends React.Component {
  getActiveTab = () => {
    return getActiveTab(this.props);
  };

  handleChangeTab = (e, { name }) => {
    const { updateCache } = this.props;
    handleChangeTab({ id: name, updateCache });
  };

  renderTabs = () => {
    const { data = {}, childCacheIds, cache = {} } = this.props;
    const { children = [] } = data;

    if (!childCacheIds) {
      return null;
    }

    const leftItems = [];

    const tabs = _.map(children, (tab, index) => {
      const id = childCacheIds[index];

      return (
        <Menu.Item
          key={id}
          name={id}
          content={tab.title}
          className="HubBlock-tab hover:text-active"
          active={cache.activeTabId ? id === cache.activeTabId : Number(index) === 0}
          onClick={this.handleChangeTab}
        />
      );
    });

    const rightItems = [];

    return leftItems.concat(tabs, rightItems);
  };

  render() {
    const {
      WrapperFactory,
      editor,
      cache,
      updateCache,
      basePath,
      relativePath,
      isNested,
      slugger,
    } = this.props;

    const activeTab = this.getActiveTab();
    const { blocks = [] } = activeTab;

    const contentElem = [];
    contentElem.push(
      <Menu
        key="ti"
        attached="top"
        className="HubBlock-tabs"
        secondary={!isNested}
        pointing={!isNested}
        size={isNested ? 'tiny' : undefined}
      >
        {this.renderTabs()}
      </Menu>
    );

    if (activeTab.id) {
      contentElem.push(
        <div key="tc" className="HubBlock-tab-content">
          <BlockList
            store={editor}
            blocks={blocks}
            parentPath={activeTab.path}
            basePath={basePath}
            relativePath={relativePath}
            cache={cache}
            updateCache={updateCache}
            blockProps={{ isNested: true }}
            slugger={slugger}
          />
        </div>
      );
    }

    return <WrapperFactory {...this.props} contentElem={contentElem} />;
  }
}

TabsBlock.displayName = 'HubTabsBlock';

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'cacheKey',
  basePathProp: 'blockPath',
  path: ['data', 'children'],
  childrenProp: 'data.children',
})(observer(TabsBlock));
