import React from 'react';
import _ from 'lodash';

import BlockAction from '../BlockAction/viewer';

import { defaultTheme } from './index';
import { computeTheme } from '../../utils';

const BlockHero = props => {
  const { WrapperFactory, data = {}, theme = {}, basePath, editor } = props;
  const { title, body, actions = [] } = data;

  const computedTheme = computeTheme({ theme, defaultTheme });
  const baseBgStyle = _.get(computedTheme, 'styles.base.background');
  const baseTextStyle = _.get(computedTheme, 'styles.base.text');

  const contentElem = [
    <div key="t" className="HubBlock-content-title" style={baseTextStyle}>
      {title}
    </div>,

    <div key="b" className="HubBlock-content-body" style={baseTextStyle}>
      {body}
    </div>,

    <div key="a" className="HubBlock-content-actions text-center">
      {_.map(actions, (action, actionIndex) => {
        const actionTheme = _.get(computedTheme, ['styles', 'actions', actionIndex]);

        return (
          <BlockAction
            key={actionIndex}
            action={action}
            basePath={basePath}
            store={editor}
            theme={actionTheme}
          />
        );
      })}
    </div>,
  ];

  return (
    <WrapperFactory
      {...props}
      className="flex flex-col justify-center"
      headerElem={null}
      contentClassName="flex flex-col items-center"
      contentProps={{ style: baseBgStyle }}
      contentElem={contentElem}
    />
  );
};

export default BlockHero;
