import React from 'react';
import _ from 'lodash';
import pluralize from 'pluralize';
import { inject, observer } from 'mobx-react';

import { safeStringify, safeParse } from '@platform/utils/json';

import EntityEditorGroup from '../EntityEditorGroup';
import FormInput from '../FormInput';
import LearnMore from '../LearnMore';

function UsedCtxVariables({ name, editor = [], className = '' }) {
  const variables = editor.currentPopulatedCtxVariables;

  if (_.isEmpty(variables)) {
    return null;
  }

  let subtitle;
  let notSetCount = 0;
  let active;
  let warning;
  _.forEach(variables, v => {
    if (_.isUndefined(v) || (typeof v === 'string' && _.isEmpty(v))) {
      notSetCount += 1;
    }
  });
  if (notSetCount > 0) {
    warning = true;
    subtitle = `${notSetCount} ${pluralize('variable', notSetCount)} not set`;
  } else {
    active = true;
  }

  return (
    <EntityEditorGroup
      id="used-ctx"
      className={`CtxVariableEditor ${className}`}
      name={name || '$.ctx'}
      active={active}
      warning={warning}
      subtitle={subtitle}
      forceOpen={warning}
      defaultOpen
      tipFactory={() => (
        <div>
          $.ctx makes it easy to pass data between steps. These inputs will display the last $.ctx
          values saved in this scenario. You can also edit them and re-run this step for easy
          debugging.
          <br />
          <LearnMore className="mt-2 d-b" feature="$.ctx" />
        </div>
      )}
      contentFactory={() => (
        <div>
          {_.map(variables, (v, k) => {
            return (
              <FormInput
                key={k}
                value={safeStringify(v, 0)}
                className="EntityEditorGroup-input"
                placeholder="value"
                onChange={e => {
                  const newVal = safeParse(e.target.value, e.target.value);
                  const newVars = _.cloneDeep(variables);
                  _.set(newVars, k, _.isObject(newVal) && _.isEmpty(newVal) ? '' : newVal);
                  editor.updateCurrentCtxVariables(newVars);
                }}
                label={{ content: k, basic: true, className: 'Label-fw' }}
                fluid
              />
            );
          })}
        </div>
      )}
    />
  );
}

export default inject(({ collectionEditorStore }, { editorId }) => ({
  editor: collectionEditorStore.getEditor({ id: editorId }),
}))(observer(UsedCtxVariables));
