import React from 'react';
import _ from 'lodash';

import MarkdownViewer from '@platform/components/MarkdownViewer';

const BlockCallout = props => {
  const { WrapperFactory, data = {}, editor } = props;
  const { type = 'info', title, body } = data;

  const contentElem = [
    <div key="b" className="flex-1">
      {title ? <div className="HubBlock-content-title">{title}</div> : null}
      {body ? (
        <MarkdownViewer
          className="HubBlock-content-body"
          value={body || ''}
          context={{
            a({ href, title, target, children }) {
              const props = {};

              // pass internal paths through the store's buildViewPath function
              if (editor && (_.startsWith(href, '/') || _.startsWith(href, '#'))) {
                props.href = editor.buildViewPath(href);
              }

              return props;
            },
          }}
        />
      ) : null}
    </div>,
  ];

  return (
    <WrapperFactory
      {...props}
      headerElem={null}
      contentClassName="flex"
      contentElem={contentElem}
      className={`CalloutBlock--${type}`}
    />
  );
};

export default BlockCallout;
