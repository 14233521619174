import React from 'react';

import FormInputLabel from '../FormInputLabel';
import CodeEditor from '../CodeEditor';

const FormCodeEditor = props => {
  const { id, label, tip, required, mode = 'json', className = '', input, ...extraProps } = props;

  return (
    <div className={`FormCodeEditor ${className}`}>
      <FormInputLabel label={label} tip={tip} required={required} />

      <CodeEditor id={id} mode={mode} autogrow noFill {...input} {...extraProps} />
    </div>
  );
};

export default FormCodeEditor;
