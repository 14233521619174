import React from 'react';
import _ from 'lodash';

import FormSelect from '../../../FormSelect';

const LogicLocation = ({ locations, ...extraProps }) => {
  return (
    <FormSelect
      placeholder="choose a location"
      options={_.map(locations, l => ({ text: l, value: l }))}
      {...extraProps}
    />
  );
};

export default LogicLocation;
