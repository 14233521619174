import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { safeParse } from '@platform/utils/json';

import Highlight from '../Highlight';

const ScenarioLog = props => {
  const { className, log = {} } = props;

  let messages = log.msg || [];
  if (typeof messages === 'string') {
    messages = [messages];
  }

  return (
    <div className={cn('ScenarioLog flex items-center', className)}>
      <div className="ScenarioLog-meta">
        <div className={`c-${log.type} font-bold`}>{log.code ? log.code : log.type}</div>
        <div className="c-muted">{log.source}</div>
      </div>
      <div className="flex-1 ScenarioLog-messages">
        {_.map(messages, (m, i) => {
          let elem = m;
          const message = safeParse(m, m);
          if (typeof message === 'object') {
            elem = (
              <Highlight mode="json" immediate>
                {message}
              </Highlight>
            );
          }

          return (
            <div key={i} className="ScenarioLog-message">
              {elem}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScenarioLog;
