import React from 'react';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

import HttpInput from './http';
import RefInput from './ref';
import ScriptInput from './script';

function Step(props) {
  const { editor, editorId, type, step = {}, autocompleteScopes = {} } = props;

  const id = `${editorId}:${_.join(_.get(editor, 'currentPath', ['noop']), ':')}`;

  if (!step) {
    return (
      <Message negative compact>
        Step not found.
      </Message>
    );
  }

  let editorElem;
  switch (type || step.type) {
    case 'http':
      editorElem = (
        <HttpInput {...props} id={id} autocompleteScopes={{ step: true, ...autocompleteScopes }} />
      );
      break;
    case 'ref':
      editorElem = (
        <RefInput {...props} id={id} autocompleteScopes={{ step: true, ...autocompleteScopes }} />
      );
      break;
    case 'script':
      editorElem = (
        <ScriptInput
          {...props}
          id={id}
          autocompleteScopes={{ step: true, ...autocompleteScopes }}
        />
      );
      break;
    default:
      if (_.isEmpty(step)) {
        editorElem = <Message negative>Step does not exist.</Message>;
      } else {
        editorElem = <Message negative>Invalid step type {type || step.type}.</Message>;
      }
  }

  return editorElem;
}

export default Step;
