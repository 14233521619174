import React from 'react';
import cn from 'classnames';
import { Checkbox } from 'semantic-ui-react';

import FormInputLabel from '../FormInputLabel';

const FormCheckbox = props => {
  const {
    checked,
    onChange,
    label,
    labelPosition = 'left',
    labelClassname,
    labelStyle,
    tip,
    required,
    className = '',
    inline,
    checkboxProps,
    inverted,
    transparent,
    ...extraProps
  } = props;

  const labelComponent = (
    <FormInputLabel
      inline={inline}
      label={label}
      tip={tip}
      required={required}
      className={labelClassname}
      style={labelStyle}
      inverted={inverted || transparent}
    />
  );

  return (
    <div
      className={cn(
        'FormCheckbox',
        className,
        'flex',
        inline ? 'is-inline items-center' : 'flex-col',
        {
          transparent,
        }
      )}
    >
      {labelPosition === 'left' && labelComponent}

      <Checkbox
        checked={checked ? true : false}
        onChange={(name, e) => {
          if (onChange) onChange(e.checked);
        }}
        {...checkboxProps}
        {...extraProps}
      />

      {labelPosition === 'right' && labelComponent}
    </div>
  );
};

export default FormCheckbox;
