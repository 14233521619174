import React from 'react';
import _ from 'lodash';
import pluralize from 'pluralize';
import { inject, observer } from 'mobx-react';
import { safeStringify, safeParse } from '@platform/utils/json';

import EntityEditorGroup from '../EntityEditorGroup';
import FormInput from '../FormInput';

const UsedEnvironmentVariables = props => {
  const { name, currentEditor, className, showEmptyMessage } = props;

  const variables = currentEditor.currentPopulatedEnvVariables;

  // prepare list of env variables which should be obscured
  const obscuredFields = [];
  const auth = _.get(currentEditor, 'currentStep.data.input.auth', {});
  if (auth.type === 'basic') {
    const matches = auth.password.match(/{\$\$\.env\.(.+)}/);
    if (matches) {
      obscuredFields.push(matches[1]);
    }
  }

  const hasVariables = !_.isEmpty(variables);

  if (!hasVariables && !showEmptyMessage) return null;

  const newVars = _.cloneDeep(variables);
  let subtitle;
  let notSetCount = 0;
  let active;
  let warning;

  _.forEach(variables, v => {
    if (_.isUndefined(v) || (typeof v === 'string' && _.isEmpty(v))) {
      notSetCount += 1;
    }
  });

  if (notSetCount > 0) {
    warning = true;
    subtitle = `${notSetCount} ${pluralize('variable', notSetCount)} not set`;
  } else {
    active = true;
  }

  let contents;

  if (hasVariables) {
    contents = _.map(variables, (variable, key) => {
      return (
        <FormInput
          key={key}
          value={safeStringify(variable, 0)}
          className="EntityEditorGroup-input"
          placeholder="value"
          type={_.includes(obscuredFields, key) ? 'password' : 'text'}
          onChange={(e, { value }) => {
            let newVal = safeParse(value, value);

            if (_.isObject(newVal) && _.isEmpty(newVal)) {
              newVal = '';
            }

            _.set(newVars, key, newVal);

            currentEditor.updateVariables(newVars);
          }}
          label={{ content: key, basic: true, className: 'Label-fw' }}
          fluid
        />
      );
    });
  } else if (showEmptyMessage) {
    contents = <div className="p-4">{showEmptyMessage}</div>;
  }

  return (
    <EntityEditorGroup
      id="used-env"
      className={`EnvVariableEditor ${className}`}
      name={name || '$$.env'}
      active={active}
      warning={warning}
      subtitle={subtitle}
      forceOpen={warning}
      defaultOpen
      contentFactory={() => <div>{contents}</div>}
    />
  );
};

export default inject(({ collectionEditorStore }, { editorId }) => {
  const currentEditor = collectionEditorStore.getEditor({ id: editorId });

  return {
    currentEditor,
  };
})(observer(UsedEnvironmentVariables));
