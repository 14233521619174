import React from 'react';
import _ from 'lodash';
import { Menu, Segment, Message, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import FormCodeEditor from '../../FormCodeEditor';

function ScriptInput(props) {
  const {
    id,
    step = {},
    ui = {},
    updateUi,

    handleUpdate,

    autocompleteScopes = {},
    readOnly,
  } = props;

  const stepScript = _.get(step, ['input', 'script'], '');

  const inputTab = ui.hasOwnProperty('inputTab') ? ui.inputTab : 'script';
  const handleInputTabClick = (e, { name }) => {
    updateUi('set', ['inputTab'], name === inputTab ? null : name);
  };

  let contentElem;
  switch (inputTab) {
    case 'script':
      contentElem = (
        <FormCodeEditor
          name={id}
          mode="javascript"
          readOnly={readOnly}
          autocompleteScopes={{ script: true, ...autocompleteScopes }}
          input={{
            value: stepScript,
            onChange(value) {
              if (_.isEmpty(value)) {
                handleUpdate('unset', ['input', 'script']);
              } else {
                handleUpdate('set', ['input', 'script'], value);
              }
            },
          }}
        />
      );
      break;
    default:
      contentElem = <div>Please select a tab.</div>;
      break;
  }

  return (
    <div className="ScenarioStepInput type-script">
      {readOnly ? (
        <Message info size="small">
          <Icon name="info" /> This editor is in read only mode.
        </Message>
      ) : null}

      <Menu id="tutorial-scenario-input" attached="top" tabular size="small">
        <Menu.Item name="scripts" active>
          Script
        </Menu.Item>
      </Menu>

      <Segment attached="bottom">{contentElem}</Segment>
    </div>
  );
}

export default inject(({ routerStore, appStore, collectionEditorStore }) => ({
  routerStore,
  ...appStore.injectUi('script-step'),
}))(observer(ScriptInput));
