import React from 'react';

import DebounceContainer from '../DebounceContainer';

let CodeMirror;
if (typeof window !== 'undefined' && window.localStorage) {
  CodeMirror = require('../CodeMirror').default;
}

let SimpleMDE;
if (typeof window !== 'undefined' && window.localStorage) {
  SimpleMDE = require('./editor').default;
}

const SimpleMDEWrapper = props => {
  return SimpleMDE ? (
    <SimpleMDE codeMirrorInstance={CodeMirror} minHeight="0px" {...props} />
  ) : null;
};

export default DebounceContainer()(SimpleMDEWrapper);

// import React from 'react';
// import dynamic from 'next/dynamic';

// import DebounceContainer from '../DebounceContainer';
// import PageLoading from '../PageLoading';
// import CodeMirror from '../CodeMirror';

// const SimpleMDE = dynamic(import('./editor'), { ssr: false, loading: () => <PageLoading /> });

// const SimpleMDEWrapper = props => {
//   return (
//     <CodeMirror
//       render={codeMirrorInstance => {
//         return <SimpleMDE codeMirrorInstance={codeMirrorInstance} minHeight="0px" {...props} />;
//       }}
//     />
//   );
// };

// export default DebounceContainer()(SimpleMDEWrapper);
