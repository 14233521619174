import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import GithubSlugger from 'github-slugger';
import { inject, observer } from 'mobx-react';
import smoothscroll from 'smoothscroll-polyfill';

import JsonPathCacheContainer from '@platform/components/JsonPathCacheContainer';
import PageLoading from '@platform/components/PageLoading';

import { registerLogger } from '@platform/utils/logging';

import { buildTextBlockForPage } from '../../utils';

import Block from '../Block/viewer';
import BlockList from '../BlockList/viewer';
import PageSidebar from '../PageSidebar/viewer';
import PageNotFound from '../PageNotFound';
import BreadCrumbs from '../BreadCrumbs';

import { HubBranding } from '../HubBranding';

const log = registerLogger('components', 'HubPage');

const slugger = new GithubSlugger();

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

class HubPage extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { routerStore } = this.props;
    routerStore.scrollToHash();
  }

  componentWillUpdate(nextProps, nextState) {
    slugger.reset();
  }

  componentDidUpdate({ location: lastLocation }) {
    const { location, routerStore } = this.props;

    if (location.hash && location.hash !== lastLocation.hash) {
      // scroll to hash
      routerStore.scrollToHash(location.hash);
    } else if (
      this.wrapper &&
      (location.pathname !== lastLocation.pathname ||
        _.get(location.query, 'view') !== _.get(lastLocation.query, 'view'))
    ) {
      // Backout if polyfill fails
      if (!this.wrapper.scrollTo) return;

      // Scroll to top if the route has changed
      this.wrapper.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { store, cache, updateCache } = this.props;
    const {
      id,
      basePath,
      hasSidebar,
      crumbs,
      activePage = {},
      activeSubpage = {},
      activeBlocks = [],
      currentPath,
      currentParsedPath,
      currentRelativePath,
      entityStore = {},
      whitelabel,
      namespace,
    } = store;

    const { isDereferencing } = entityStore;

    let page = activePage.data || {};
    if (activeSubpage.data) {
      page = activeSubpage.data || {};
    }

    log.debug('Render', { page, basePath, currentRelativePath });

    const { $ref } = page.data || {};

    let content;
    if (_.isEmpty(page.data)) {
      content = <PageNotFound />;
    } else if ($ref && isDereferencing) {
      content = <PageLoading text="Building Page" inverted={false} />;
    } else if (_.isEmpty(activeBlocks)) {
      content = (
        <Block
          block={buildTextBlockForPage({
            pagePath: `/${_.trim(_.join(currentPath, '/'), '/')}`,
            page,
          })}
          basePath={basePath}
          relativePath={currentRelativePath}
          editor={store}
          slugger={slugger}
        />
      );
    } else {
      content = (
        <BlockList
          store={store}
          blocks={activeBlocks}
          blocksDereferenced={activeBlocks}
          parentPath={_.concat(currentParsedPath, ['data'])}
          basePath={basePath}
          relativePath={currentRelativePath}
          cache={cache}
          updateCache={updateCache}
          slugger={slugger}
        />
      );
    }

    return (
      <div
        className={cn('HubMain flex flex-1 relative', {
          'has-sidebar': hasSidebar,
        })}
      >
        {hasSidebar && <PageSidebar id={id} store={store} />}

        <div
          className="HubPage-wrapper flex-1 bg-white w-full overflow-y-scroll min-h-100"
          ref={elem => {
            this.wrapper = elem;
          }}
        >
          <div className="HubPage">
            <div className="HubPage-inner flex">
              <div className="HubPage-content flex-1 relative">
                {crumbs && crumbs.length > 1 && false && <BreadCrumbs crumbs={crumbs} />}
                <div className="HubPageBody">{content}</div>

                {!hasSidebar && (
                  <div className="HubPageFooter absolute pin-x pin-b">
                    <HubBranding whitelabel={whitelabel} namespace={namespace} centered />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const container = JsonPathCacheContainer({
  cacheKeyProp: 'store.id',
  pathProp: 'store.currentParsedPath',
  pure: false,
})(observer(HubPage));

export default inject((stores, props) => {
  const { routerStore } = stores;

  return {
    location: routerStore.location, // pass location in directly for componentDidUpdate comparison
    routerStore,
  };
})(container);
