import _ from 'lodash';

export const getActiveTab = props => {
  const { data, cache = {}, childCacheIds = [], blockPath = [] } = props;

  const { children = [] } = data;
  let activeIndex = Math.max(_.indexOf(childCacheIds, cache.activeTabId || 0), 0);
  const activeTab = children[activeIndex] || {};

  return {
    id: childCacheIds[activeIndex],
    index: activeIndex,
    path: blockPath.concat(['data', 'children', activeIndex]),
    ...activeTab,
  };
};

export const handleChangeTab = ({ id, updateCache }) => {
  updateCache('set', ['activeTabId'], id);
};
