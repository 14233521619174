import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import cn from 'classnames';

const EditorContentSection = props => {
  const { title, canToggle = true, children, ui, updateUi } = props;

  const classNames = cn('EditorContentSection', {
    'can-toggle': canToggle,
    'is-closed': ui.isClosed,
  });

  return (
    <div className={classNames}>
      {title ? (
        <div
          className="EditorContentSection-header flex items-center"
          onClick={() => {
            if (canToggle) {
              updateUi('set', 'isClosed', !ui.isClosed);
            }
          }}
        >
          <div className="EditorContentSection-title flex-1">
            <div className="EditorContentSection-title-inner">
              <Icon name={`caret ${ui.isClosed ? 'right' : 'down'}`} />
              {title}
              <Icon name={`caret ${ui.isClosed ? 'left' : 'down'}`} />
            </div>
          </div>
        </div>
      ) : null}

      {!ui.isClosed ? <div className="EditorContentSection-content">{children}</div> : null}
    </div>
  );
};

export default inject(({ appStore }, { id }) => ({
  ...appStore.injectUi(`${id}:EditorContentSection`),
}))(observer(EditorContentSection));
