const format = require('rehype-format');
const parse = require('rehype-parse');
const slug = require('rehype-slug');
const stringify = require('rehype-stringify');
const react = require('rehype-react');

import { ILangReader } from '@core/lang';

import * as Unified from '@core/unified';
import * as Unist from '@core/unist';

import { reactConfig } from './config';
import { sanitizeScript } from './sanitizeScript';

const parseOptions = { fragment: true };

interface IReactConfig {
  createElement(): void;
  components: {};
}

interface IToReactConfig {
  react?: IReactConfig;
  sanitize?: boolean;
}

export class HTMLReader implements ILangReader {
  public fromLang(html: string): Unist.IParent {
    return Unified.create()
      .use(parse)
      .parse(html);
  }

  public toLang(data: any): string {
    return Unified.create()
      .use(stringify)
      .stringify(data);
  }

  public toReact(html: string, config: IToReactConfig = {}): any {
    const processor = Unified.create().use(parse, parseOptions);

    if (config.sanitize) {
      processor.use(sanitizeScript);
    }

    processor
      .use(stringify)
      .use(react, Object.assign(reactConfig, config.react))
      .use(slug);

    return processor.processSync(html).contents;
  }

  public sanitize(html: string): string {
    return Unified.create()
      .use(parse, parseOptions)
      .use(sanitizeScript)
      .use(stringify)
      .use(slug)
      .processSync(html)
      .contents.trim();
  }

  public format(html: string): string {
    return Unified.create()
      .use(parse, parseOptions)
      .use(stringify)
      .use(format)
      .processSync(html)
      .contents.trim();
  }
}
