import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { modeObjs } from '@platform/utils/codeMirror';
// import { asyncComponent } from 'react-async-component';
import { registerLogger } from '@platform/utils/logging';
import { currentBrowser } from '@platform/utils/browser';

// import PageLoading from '../PageLoading';
import ErrorBoundary from '../ErrorBoundary';

import Editor from './editor';

const log = registerLogger('components', 'CodeEditor');

// NOTE: we have been using "name" as a unique identifier.. we are transitioning to use "id"

let CodeMirror;
if (typeof window !== 'undefined' && window.localStorage) {
  CodeMirror = require('../CodeMirror').default;
}
// const Editor = asyncComponent({
//   serverMode: 'defer',
//   resolve: () => System.import('./editor'),
//   LoadingComponent: () => <PageLoading inverted={false} />, // Optional
//   ErrorComponent: ({ error }) => <div>{error.message}</div>, // Optional
// });

class CodeEditor extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string, // DEPRECATED
    mode: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    keyMap: PropTypes.string,
    lineWrapping: PropTypes.bool,
    lineNumbers: PropTypes.bool,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    lint: PropTypes.bool,
    tabSize: PropTypes.number,
    highlightLines: PropTypes.object,
    preserveScrollPosition: PropTypes.bool,
    noDebounce: PropTypes.bool,
    theme: PropTypes.string,
    autoRefresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  };

  static defaultProps = {
    mode: 'json',
    keyMap: 'sublime',
    lineWrapping: true,
    lineNumbers: true,
    readOnly: false,
    placeholder: 'Type here...',
    lint: true,
    tabSize: 2,
  };

  state = {
    lastBroadcastedValue: null,
    broadcasting: false,
  };

  checkId(props) {
    if (!props.id && !props.name) {
      log.warn(
        'CodeEditor requires that an id or name be passed in (note that name is deprecated)'
      );
    }
  }

  componentWillMount() {
    this.checkId(this.props);
    if (!this.props.noDebounce) {
      this.onChange = _.debounce(this.onChange, 500, {
        leading: true,
      });
    }
  }

  onChange = (val, change) => {
    this.setState({
      lastBroadcastedValue: val,
      broadcasting: true,
    });

    if (this.props.onChange) {
      this.props.onChange(val, change);
    }
  };

  componentWillUpdate(nextProps, nextState) {
    this.checkId(nextProps);
    nextState.broadcasting = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      this.props.id !== nextProps.id ||
      this.props.name !== nextProps.name ||
      (!nextState.broadcasting && nextState.lastBroadcastedValue !== nextProps.value);

    nextState.broadcasting = false;

    return shouldUpdate;
  }

  parseValue = value => {
    const { mode = 'json' } = this.props;

    let val = value;
    if (mode === 'json') {
      if (typeof value === 'string') {
        try {
          val = JSON.parse(value);
        } catch (e) {}
      }

      if (typeof val === 'object') {
        val = JSON.stringify(val, null, 2);
      }
    } else {
      val = value;
    }

    if (val && typeof val !== 'string') {
      val = JSON.stringify(val, null, 2);
    }

    return val || '';
  };

  renderEditor = codeMirrorInstance => {
    const {
      mode = 'json',
      value,
      keyMap,
      tabSize,
      lineWrapping,
      lineNumbers,
      readOnly,
      placeholder,
      lint,
      autocompleteScopes,
      preserveScrollPosition,
      highlightPath,
      highlightRange,
      onFocus,
      onBlur,
      theme,
      autoRefresh,
    } = this.props;

    const modeObj = modeObjs[mode] || mode;
    let fullPlaceholder = placeholder;
    if (!_.isEmpty(autocompleteScopes)) {
      fullPlaceholder = `${placeholder} Press ctrl + spacebar to show autocomplete list.`;
    }

    return (
      <Editor
        codeMirrorInstance={codeMirrorInstance}
        value={this.parseValue(value)}
        onChange={this.onChange}
        onFocusChange={(v, focused) => {
          if (focused && onFocus) {
            onFocus(v);
          } else if (!focused && onBlur) {
            onBlur(v);
          }
        }}
        highlightPath={highlightPath}
        highlightRange={highlightRange}
        autocompleteScopes={autocompleteScopes}
        preserveScrollPosition={preserveScrollPosition}
        options={{
          mode: modeObj,
          keyMap,
          tabSize,
          lineWrapping,
          lineNumbers,
          readOnly,
          autoRefresh,
          // disable in safari, because it locks the browser for large strings
          lint: currentBrowser.safari ? false : lint,
          theme: theme || 'default',
          placeholder: fullPlaceholder,
          extraKeys: {
            'Alt-F': 'findPersistent',
          },
          styleSelectedText: true,
          matchBrackets: true,
          autoCloseBrackets: true,
          highlightSelectionMatches: true,
          foldGutter: true,
          gutters: ['CodeMirror-lint-markers', 'CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        }}
      />
    );
  };

  render() {
    const { autogrow, maxLines, className = '', styles = {}, noFill } = this.props;

    const classes = ['CodeEditor'];
    if (!noFill) {
      classes.push('fill-space');
    }
    if (autogrow) {
      classes.push('autogrow');
    }
    if (maxLines) {
      classes.push(`autogrow-${maxLines}`);
    }
    if (className) {
      classes.push(className);
    }

    return (
      <ErrorBoundary>
        <div className={classes.join(' ')} style={styles.root || {}}>
          {CodeMirror ? this.renderEditor(CodeMirror) : null}
        </div>
      </ErrorBoundary>
    );
  }
}

export default CodeEditor;
