import React from 'react';
import _ from 'lodash';

import { Menu } from 'semantic-ui-react';

import FormInputMap from '../../../FormInputMap';

export const QueryTabItem = ({ step, active, onClick }) => {
  const query = _.get(step, 'input.query');

  let headerName = 'Query';
  if (!_.isEmpty(query)) {
    headerName = (
      <span>
        Query{' '}
        <b>
          <span className="ml-2 text-active">[{_.size(query)}]</span>
        </b>
      </span>
    );
  }

  return (
    <Menu.Item name="query" active={active} onClick={onClick}>
      {headerName}
    </Menu.Item>
  );
};

const Query = ({ step, handleUpdate, readOnly }) => {
  return [
    <FormInputMap
      key="queryList"
      fields={_.get(step, 'query', {})}
      handleUpdate={(t, p, v) => {
        handleUpdate(t, ['input', 'query'].concat(p), v);
      }}
      addText="Add Query"
      namePlaceholder="query name"
      valuePlaceholder="query value"
      size="tiny"
      readOnly={readOnly}
    />,
  ];
};

export default Query;
