import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Menu, Segment, Icon } from 'semantic-ui-react';

import Highlight from '../../Highlight';

const ScriptOutput = props => {
  const { ui, updateUi, className = '', result = {}, tabWhitelist } = props;

  if (_.isEmpty(result)) {
    return null;
  }

  let { activeResultTab = 'tests' } = ui;
  const assertions = _.get(result, 'before.assertions', []).concat(
    _.get(result, 'after.assertions', [])
  );

  const handleItemClick = (e, { name }) => {
    updateUi('set', ['activeResultTab'], name);
  };

  // BUILD THE TAB CONTENT

  let contentElem;
  switch (activeResultTab) {
    case 'tests':
      if (_.isEmpty(assertions)) {
        contentElem = (
          <div className="c-muted">
            No tests. Use the "tests" object in your script to add test assertions.
          </div>
        );
      } else {
        contentElem = (
          <div>
            {assertions.map((a, i) => {
              let assertionClass;
              let icon = 'circle';
              if (a.pass) {
                assertionClass = 'passed';
                icon = 'check';
              } else if (a) {
                assertionClass = 'failed';
                icon = 'remove';
              }

              let expectedElem;
              if (typeof a.expected === 'object') {
                expectedElem = '[schema object not shown for now]';
              } else {
                expectedElem = a.expected;
              }

              return (
                <div key={i} className={cn('AssertionResult flex items-center', assertionClass)}>
                  <div className="AssertionResult-icon">
                    <Icon name={icon} />
                  </div>
                  <div className="AssertionResult-content flex-1">
                    <div>
                      {a.target} <b className="ml-3 mr-3">[{a.op}]</b> {expectedElem}
                    </div>
                    {a.msg ? <div className="AssertionResult-message">{a.msg}</div> : null}
                    {a.details ? <div className="AssertionResult-message">{a.details}</div> : null}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      break;
    case 'capture':
      contentElem = (
        <div>
          <div className="p-3">
            <div className="c-muted mb-6">
              $.ctx <i>after</i> step:
            </div>

            <Highlight mode="json" immediate>
              {_.get(result, 'ctx', '{}')}
            </Highlight>
          </div>

          <div className="pt-3 pl-3 pr-3">
            <div className="c-muted mb-6">
              $$.env <i>after</i> step:
            </div>

            <Highlight mode="json" immediate>
              {_.get(result, 'env', '{}')}
            </Highlight>
          </div>
        </div>
      );
      break;
    default:
      contentElem = <div>Please select a tab.</div>;
      break;
  }

  // BUILD THE TABS

  // TESTS TAB

  let passElem;
  if (result.passCount > 0) {
    passElem = (
      <span className="c-positive ml-2">
        <Icon fitted name="check" /> {result.passCount}
      </span>
    );
  }
  let failedElem;
  if (result.failCount > 0) {
    failedElem = (
      <span className="c-negative ml-2">
        <Icon fitted name="remove" /> {result.failCount}
      </span>
    );
  }
  const testName = (
    <span>
      Tests{' '}
      <b>
        {passElem} {failedElem}
      </b>
    </span>
  );
  const testTitleElem = (
    <Menu.Item name="tests" active={activeResultTab === 'tests'} onClick={handleItemClick}>
      {testName}
    </Menu.Item>
  );

  // CAPTURE TAB

  let captureName = 'Capture';
  // if (!_.isEqual(beforeState, afterState)) {
  //   captureName = (
  //     <span>
  //       Capture <b><span className='ml-2 text-active'><i className='fa fa-check' /></span></b>
  //     </span>
  //   );
  // }

  if (tabWhitelist && !_.includes(tabWhitelist, activeResultTab)) {
    activeResultTab = 'body';
  }

  const menuElem = (
    <Menu attached="top" tabular>
      {!tabWhitelist || _.includes(tabWhitelist, 'tests') ? testTitleElem : null}

      {!tabWhitelist || _.includes(tabWhitelist, 'capture') ? (
        <Menu.Item name="capture" active={activeResultTab === 'capture'} onClick={handleItemClick}>
          {captureName}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <div id="tutorial-script-output" className={cn('ScriptOutput', className)}>
      {menuElem}

      <Segment attached="bottom">{contentElem}</Segment>
    </div>
  );
};

export default inject(({ appStore }, { id }) => ({
  ...appStore.injectUi(`script-output-${id}`),
}))(observer(ScriptOutput));
