import React from 'react';
import PropTypes from 'prop-types';

/**
 * This will listen to the scroll event and pass details to the child.
 */
class ScrollWraper extends React.Component {
  static propTypes = {
    targetId: PropTypes.string,
    onResize: PropTypes.bool, // also trigger on resize
  };

  constructor(props) {
    super(props);

    this.state = {
      scroll: undefined,
    };
  }

  handleScroll = evt => {
    this.setState({ scroll: evt });
  };

  componentDidMount = () => {
    const { targetId, onResize } = this.props;
    const target = targetId ? document.getElementById(targetId) : window;
    target && target.addEventListener('scroll', this.handleScroll);
    target && onResize && target.addEventListener('resize', this.handleScroll);
  };

  componentWillUnmount = () => {
    const { targetId, onResize } = this.props;
    const target = targetId ? document.getElementById(targetId) : window;
    target && target.removeEventListener('scroll', this.handleScroll);
    target && onResize && target.removeEventListener('resize', this.handleScroll);
  };

  render() {
    const { scroll } = this.state;

    return this.props.render({ scroll });
  }
}

export default ScrollWraper;
