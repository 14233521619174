import React from 'react';
import _ from 'lodash';
import { Menu, Button, Icon, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import Highlight from '../../../Highlight';
import FormCodeEditor from '../../../FormCodeEditor';

export const ScriptsTabItem = ({ step, active, onClick }) => {
  let scriptName = 'Scripts';
  let hasScripts;
  if (!_.isEmpty(_.get(step, 'before.script'))) {
    hasScripts = true;
  }
  if (!_.isEmpty(_.get(step, 'after.script'))) {
    hasScripts = true;
  }
  if (hasScripts) {
    scriptName = (
      <span>
        {scriptName}{' '}
        <b>
          <span className="ml-2 text-active">
            <Icon name="check" />
          </span>
        </b>
      </span>
    );
  }

  return (
    <Menu.Item name="scripts" active={active} onClick={onClick}>
      {scriptName}
    </Menu.Item>
  );
};

const cheatsheet = `// save back to your current environment
$$.env.set('apiKey', output.body.get('apiKey'));

// add a test (you can also make assertions in the tests UI tab)
tests['it should return a 200 status code'] = output.status.get() === 200;

// use lodash
tests['test some object equality'] = _.isEqual({foo: 'bar'}, output.body.get());

// share temporary data with subsequent steps by setting on the ctx object
$.ctx.set('targetId', _.random(1, 100));

// manipulate the request in a before script
input.headers.set('Authorization', 'Bearer ' + $$.env.get('apiKey'));
input.method.set('post');`;

const Scripts = props => {
  const {
    scenarioId,
    stepIndex,
    step,
    handleUpdate,
    ui = {},
    updateUi,
    readOnly,
    autocompleteScopes,
  } = props;

  const { activeItem = 'after' } = ui;
  const beforeScript = _.get(step, 'before.script', '');
  const afterScript = _.get(step, 'after.script', '');

  const handleItemClick = (e, { name }) => {
    updateUi('set', ['activeItem'], name);
  };

  let contentElem;
  switch (activeItem) {
    case 'before':
      contentElem = (
        <FormCodeEditor
          name={`${scenarioId}-${stepIndex}-before`}
          mode="javascript"
          readOnly={readOnly}
          autocompleteScopes={autocompleteScopes}
          input={{
            value: beforeScript,
            onChange(value) {
              if (_.isEmpty(value)) {
                handleUpdate('unset', ['before', 'script']);
              } else {
                handleUpdate('set', ['before', 'script'], value);
              }
            },
          }}
        />
      );
      break;
    case 'after':
      contentElem = (
        <FormCodeEditor
          name={`${scenarioId}-${stepIndex}-after`}
          mode="javascript"
          readOnly={readOnly}
          autocompleteScopes={autocompleteScopes}
          input={{
            value: afterScript,
            onChange(value) {
              if (_.isEmpty(value)) {
                handleUpdate('unset', ['after', 'script']);
              } else {
                handleUpdate('set', ['after', 'script'], value);
              }
            },
          }}
        />
      );
      break;
    default:
      contentElem = <div>select an option above</div>;
  }

  let beforeName = 'Before';
  if (!_.isEmpty(beforeScript)) {
    beforeName = (
      <span>
        {beforeName}{' '}
        <span className="ml-2 text-active">
          <Icon name="check" />
        </span>
      </span>
    );
  }

  let afterName = 'After';
  if (!_.isEmpty(afterScript)) {
    afterName = (
      <span>
        {afterName}{' '}
        <span className="ml-2 text-active">
          <Icon name="check" />
        </span>
      </span>
    );
  }

  return (
    <div>
      <div className="mb-6 flex items-center">
        <div>
          <Menu secondary pointing size="tiny">
            <Menu.Item header>Step Stage</Menu.Item>

            <Menu.Item name="before" active={activeItem === 'before'} onClick={handleItemClick}>
              {beforeName}
            </Menu.Item>

            <Menu.Item name="after" active={activeItem === 'after'} onClick={handleItemClick}>
              {afterName}
            </Menu.Item>
          </Menu>
        </div>

        <div className="ml-6">
          <Button
            compact
            size="tiny"
            onClick={() => {
              updateUi('set', ['showCheatsheet'], !ui.showCheatsheet);
            }}
            basic
          >
            {ui.showCheatsheet ? 'Hide Cheatsheet' : 'Show Cheatsheet'}
          </Button>

          <span className="c-muted ml-2">
            <Popup
              trigger={<Icon name="question circle" />}
              size="small"
              position="top center"
              hoverable
              wide
            >
              <a
                className="d-b"
                href="https://help.stoplight.io/scenarios/overview/scripting"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="book" /> Learn more about scripting.
              </a>
            </Popup>
          </span>
        </div>
      </div>

      {ui.showCheatsheet ? (
        <Highlight className="ScenarioEditor-cheatsheet mb-6" mode="javascript" immediate>
          {cheatsheet}
        </Highlight>
      ) : null}

      {contentElem}
    </div>
  );
};

export default inject(({ appStore }) => ({
  ...appStore.injectUi('scenario-scripts-tab'),
}))(observer(Scripts));
