import React from 'react';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { inject } from 'mobx-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { SiteData, withRouteData } from '@stoplight/react-static';

import Page from '@platform/format-hubs/components/Page/viewer';

class HubPage extends React.Component {
  componentWillMount() {
    this.store().activate();

    if (this.authRequired()) {
      window.location.pathname = '/_/login';
    }
  }

  componentWillUpdate(nextProps) {
    const { page } = this.props;
    const nextPath = _.get(nextProps.page, 'path');
    const currentPath = _.get(page, 'path');

    if (nextPath !== currentPath) {
      this.store().deactivate();
      this.store(nextProps).activate();

      if (this.authRequired()) {
        window.location.pathname = '/_/login';
      }
    }
  }

  authRequired = () => {
    // Don't check auth when generating static HTML
    if (typeof window === 'undefined') return;

    const { isAuthRequired } = this.props;
    const isLoggedIn = Cookies.get('_session_token');

    return isAuthRequired && !isLoggedIn;
  };

  store = (props = this.props) => {
    return this.props.pageStore.lookup(_.get(props.page, 'path'), props);
  };

  render() {
    const store = this.store();

    if (!store || this.authRequired()) {
      // Store will only be null if the page doesn't exist, in which case we are likely redirecting
      return (
        <div className="flex-1 relative">
          <Dimmer active inverted>
            <Loader content="Loading..." />
          </Dimmer>
        </div>
      );
    }

    return <Page id={store.id} store={store} />;
  }
}

const InjectedHubPage = inject((stores, props) => {
  const { routerStore, pageStore } = stores;
  return {
    routerStore,
    pageStore,
  };
})(HubPage);

export default withRouteData(routeData => {
  return <SiteData {...routeData} component={InjectedHubPage} />;
});
