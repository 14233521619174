/* global vkbeautify */

import React from 'react';
import { Dropdown } from 'semantic-ui-react';

// import './vkBeautify';

import CodeEditor from '../CodeEditor';
import JsonTree from '../JsonTree';
import Frame from '../Frame';

const HttpBodyViewer = props => {
  const {
    className = '',
    name,
    value,
    contentType = '',
    uiCache = {},
    uiCacheScope = [],
    updateUiCache,
    shouldEncode,
    noTypeSelector,
  } = props;

  const isImage = contentType.match('image');
  const isHtml = contentType.match('html');
  const isXml = contentType.match('xml');

  let defaultRenderer = 'json';
  if (isXml) {
    defaultRenderer = 'xml';
  } else if (isHtml) {
    defaultRenderer = 'renderHtml';
  }

  const renderer = uiCache.renderer || defaultRenderer;

  let contentElem;
  let specialCase;

  if (isImage) {
    specialCase = true;
    const imgStr = shouldEncode
      ? btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
      : value;
    contentElem = (
      <div>
        <img src={`data:${contentType};base64,${imgStr}`} />
      </div>
    );
  } else if (isHtml && shouldEncode) {
    specialCase = true;
    contentElem = (
      <Frame
        id="body-viewer"
        src="about:blank"
        width="100%"
        height="1000"
        frameBorder="0"
        sandbox="allow-scripts allow-forms"
      >
        <div dangerouslySetInnerHTML={{ __html: value }} />
      </Frame>
    );
  } else {
    switch (renderer) {
      case 'jsonExplorer':
        contentElem = <JsonTree data={value} />;
        break;
      case 'renderHtml':
        contentElem = (
          <Frame
            id="body-viewer"
            src="about:blank"
            width="100%"
            height="1000"
            frameBorder="0"
            sandbox="allow-scripts allow-forms"
          >
            <div dangerouslySetInnerHTML={{ __html: value }} />
          </Frame>
        );
        break;
      default:
        contentElem = (
          <CodeEditor
            name={`${name}-${renderer}`}
            mode={renderer}
            value={value}
            autogrow
            maxLines={40}
            noFill
            readOnly
            wordWrap
            lint={false}
          />
        );
    }
  }

  let dropdownElem;
  if (!specialCase && !noTypeSelector) {
    dropdownElem = (
      <div className="mb-6">
        <Dropdown
          options={[
            {
              text: 'JSON',
              value: 'json',
            },
            {
              text: 'JSON Explorer',
              value: 'jsonExplorer',
            },
            {
              text: 'XML',
              value: 'xml',
            },
            {
              text: 'HTML',
              value: 'html',
            },
            {
              text: 'Rendered HTML',
              value: 'renderHtml',
            },
            {
              text: 'Plain',
              value: 'text',
            },
          ]}
          value={renderer}
          onChange={(e, options) => {
            updateUiCache('set', uiCacheScope.concat('renderer'), options.value);
          }}
          selection
          button
          basic
        />
      </div>
    );
  }

  return (
    <div className={`HttpBodyViewer ${className}`}>
      {dropdownElem}
      {contentElem}
    </div>
  );
};

export default HttpBodyViewer;
