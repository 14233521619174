import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';

import Prism from '@platform/utils/highlight';
import { safeParse, safeStringify } from '@platform/utils/json';

class Highlight extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    innerHTML: PropTypes.bool,
    immediate: PropTypes.bool,
    mode: PropTypes.string,
    style: PropTypes.object,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = {
    style: {},
    innerHTML: false,
    immediate: false,
    lineNumbers: true,
  };

  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode = () => {
    if (this.node) {
      const nodes = this.node.querySelectorAll('code');
      if (nodes.length > 0) {
        for (let i = 0; i < nodes.length; i++) {
          try {
            Prism.highlightElement(nodes[i]);
          } catch (error) {
            console.error('Error with Prism.highlightElement', error);
          }
        }
      }
    }
  };

  getDataLines = () => {
    const { highlightRange } = this.props;

    let dataLines = [];

    if (highlightRange) {
      if (_.isArray(highlightRange)) {
        _.forOwn(highlightRange, lineRange => {
          let dataLine = lineRange.from;

          if (lineRange.from !== lineRange.to) {
            dataLine = `${lineRange.from}-${lineRange.to}`;
          }

          if (dataLine) {
            dataLines.push(dataLine);
          }
        });
      } else if (typeof highlightRange === 'string') {
        dataLines = [highlightRange];
      }
    }

    return dataLines.join(',');
  };

  getPrettyJson = () => {
    const { content, children, immediate } = this.props;

    const target = children || content;

    let pretty = safeStringify(safeParse(target, target));

    if (immediate && pretty) {
      try {
        pretty = Prism.highlight(pretty, Prism.languages.json);
      } catch (error) {
        console.error('Error with Prism.highlight', error);
      }
    }

    return pretty;
  };

  render() {
    const { className, mode, lineNumbers, immediate, innerHTML, style } = this.props;

    // Check if it's json, if it is, make sure it's indented.
    // Else just pass the response to the highlighter
    const pretty = this.getPrettyJson();

    // Get the extra highlighted lines
    const dataLines = this.getDataLines();

    const codeClassNames = cn(className, `language-${mode || 'json'}`);
    const preClassNames = cn({
      'line-numbers': lineNumbers,
    });

    let code = null;
    if (immediate) {
      if (pretty) {
        code = (
          <pre className={preClassNames} data-line={dataLines}>
            <code dangerouslySetInnerHTML={{ __html: pretty }} className={codeClassNames} />
          </pre>
        );
      }
    } else if (innerHTML) {
      code = (
        <div>
          <div dangerouslySetInnerHTML={{ __html: pretty }} className={codeClassNames} />
        </div>
      );
    } else {
      code = (
        <pre className={preClassNames} data-line={dataLines}>
          <code className={codeClassNames}>{pretty}</code>
        </pre>
      );
    }

    return (
      <div
        className={cn('Highlight', { 'line-numbers': lineNumbers })}
        style={style}
        ref={node => {
          this.node = node;
        }}
      >
        {code}
      </div>
    );
  }
}

export default Highlight;
