import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Menu, Segment, Icon } from 'semantic-ui-react';

import FormInput from '@platform/components/FormInput';
import FormSelect from '@platform/components/FormSelect';
import FormHttpBodyEditor from '@platform/components/FormHttpBodyEditor';

import SettingsTab from './settings';
import AuthTab from './auth';
import CapturesTab, { CapturesTabItem } from './captures';
import CodeGenerationTab from './code-generation';
import HeadersTab, { HeadersTabItem } from './headers';
import QueryTab, { QueryTabItem } from './query';
import ScriptsTab, { ScriptsTabItem } from './scripts';
import TestsTab, { TestsTabItem } from './tests';

const defaultLogicLocations = [
  'output.headers',
  'output.body',
  'output.status',
  'output.time',
  '$.ctx',
  '$$.env',
  '$$.response.headers',
  '$$.response.body',
  '$$.response.status',
];

let Items = props => {
  const { editor, step, config = {}, currentTab, onClick, whitelist } = props;
  const { input } = step || {};
  const { auth = {}, body, method } = input || {};

  let authorizationName = 'Auth';
  if (auth.type && auth.type !== 'none') {
    authorizationName = (
      <span>
        Auth
        <span className="ml-2 text-active font-bold">[{auth.type}]</span>
      </span>
    );
  }

  let bodyName = 'Body';
  if (!_.isEmpty(body)) {
    bodyName = (
      <span>
        Body
        <span className="ml-2 text-active">
          <Icon name="check" />
        </span>
      </span>
    );
  }

  return (
    <Menu style={{ marginTop: 0 }} tabular attached="top" size="tiny">
      {_.includes(whitelist, 'settings') && (
        <Menu.Item name="settings" active={currentTab === 'settings'} onClick={onClick} />
      )}

      {_.includes(whitelist, 'headers') && (
        <HeadersTabItem step={step} active={currentTab === 'headers'} onClick={onClick} />
      )}

      {_.includes(whitelist, 'query') && (
        <QueryTabItem step={step} active={currentTab === 'query'} onClick={onClick} />
      )}

      {_.includes(whitelist, 'body') && (
        <Menu.Item name="body" active={currentTab === 'body'} onClick={onClick}>
          {bodyName}
        </Menu.Item>
      )}

      {_.includes(whitelist, 'auth') && (
        <Menu.Item name="auth" className="auth" active={currentTab === 'auth'} onClick={onClick}>
          {authorizationName}
        </Menu.Item>
      )}

      {_.includes(whitelist, 'code-generation') && (
        <Menu.Item
          name="code-generation"
          active={currentTab === 'code-generation'}
          onClick={onClick}
        >
          Code Generation
        </Menu.Item>
      )}

      {_.includes(whitelist, 'tests') && (
        <TestsTabItem step={step} active={currentTab === 'tests'} onClick={onClick} />
      )}

      {_.includes(whitelist, 'captures') && (
        <CapturesTabItem step={step} active={currentTab === 'captures'} onClick={onClick} />
      )}

      {_.includes(whitelist, 'scripts') && (
        <ScriptsTabItem step={step} active={currentTab === 'scripts'} onClick={onClick} />
      )}
    </Menu>
  );
};
Items = inject((stores, props) => {
  const { collectionEditorStore } = stores;
  const { editorId } = props;

  return {
    editor: collectionEditorStore.getEditor({ id: editorId }),
  };
})(observer(Items));

let Content = props => {
  const {
    id,
    editorId,
    collectionId,
    scenarioId,
    step = {},
    stepIndex,
    editor,
    modeling,
    handleUpdate,
    ui = {},
    updateUi,
    connectedSpecs = [],
    autocompleteScopes = {},
    config = {},
    readOnly,
    standalone,
    currentTab,
  } = props;

  let contentElem;

  switch (currentTab) {
    case 'headers':
      contentElem = (
        <HeadersTab step={step.input} modeling={modeling} handleUpdate={handleUpdate} />
      );
      break;
    case 'query':
      contentElem = <QueryTab step={step.input} editorId={editorId} handleUpdate={handleUpdate} />;
      break;
    case 'settings':
      contentElem = (
        <SettingsTab
          editorId={editorId}
          oauth2={_.get(step.input, 'auth.type') === 'oauth2' && config.oauth2}
          currentEnv={editor.currentEnv}
          updateVariables={variables => editor.updateVariables(variables)}
          modeling={modeling}
          standalone={standalone}
        />
      );
      break;
    case 'auth':
      contentElem = (
        <AuthTab
          editor={editor}
          currentVariables={editor.currentEnv}
          activeToken={_.get(editor.currentPopulatedEnvVariables, 'oauth_access_token')}
          config={config}
          ui={ui.auth}
          uiScope={['auth']}
          input={step.input}
          updateUi={updateUi}
          handleUpdate={handleUpdate}
        />
      );
      break;
    case 'body':
      contentElem = <FormHttpBodyEditor id={id} input={step.input} handleUpdate={handleUpdate} />;
      break;
    case 'tests':
      contentElem = (
        <TestsTab
          step={step}
          editorId={editorId}
          handleUpdate={handleUpdate}
          collectionId={collectionId}
          scenarioId={scenarioId}
          connectedSpecs={connectedSpecs}
          logicLocations={defaultLogicLocations}
          readOnly={readOnly}
        />
      );
      break;
    case 'captures':
      contentElem = (
        <CapturesTab
          fields={_.get(step, 'after.transforms')}
          handleUpdate={(t, p, v) => {
            handleUpdate(t, ['after', 'transforms'].concat(p), v);
          }}
          logicLocations={defaultLogicLocations}
          readOnly={readOnly}
        />
      );
      break;

    case 'scripts':
      contentElem = (
        <ScriptsTab
          scenarioId={scenarioId}
          stepIndex={stepIndex}
          step={step}
          handleUpdate={handleUpdate}
          readOnly={readOnly}
          autocompleteScopes={{ http: true, ...autocompleteScopes }}
        />
      );
      break;
    case 'code-generation':
      contentElem = <CodeGenerationTab editorId={editorId} step={step} />;
      break;
    default:
      contentElem = <div className="c-muted">Choose a tab to add request input.</div>;
      break;
  }

  return <Segment attached="bottom">{contentElem}</Segment>;
};

Content = inject((stores, props) => {
  const { collectionEditorStore } = stores;
  const { editorId } = props;

  return {
    editor: collectionEditorStore.getEditor({ id: editorId }),
  };
})(observer(Content));

const Tabs = props => {
  const {
    id,
    editorId,
    collectionId,
    scenarioId,
    step = {},
    stepIndex,
    editor,
    standalone,
    modeling,
    handleUpdate,
    ui = {},
    updateUi,
    connectedSpecs = [],
    autocompleteScopes = {},
    config = {},
    readOnly,
    whitelist = [],
    currentTab,
    handleChangeTab,
  } = props;

  return [
    <Items
      key="items"
      editorId={editorId}
      step={step}
      config={config}
      whitelist={whitelist}
      currentTab={currentTab}
      onClick={(e, { name }) => handleChangeTab(name)}
    />,
    <Content
      key="content"
      id={id}
      editorId={editorId}
      collectionId={collectionId}
      scenarioId={scenarioId}
      standalone={standalone}
      step={step}
      stepIndex={stepIndex}
      modeling={modeling}
      handleUpdate={handleUpdate}
      ui={ui}
      updateUi={updateUi}
      connectedSpecs={connectedSpecs}
      autocompleteScopes={autocompleteScopes}
      config={config}
      readOnly={readOnly}
      whitelist={whitelist}
      currentTab={currentTab}
    />,
  ];
};

export default observer(Tabs);
