import React from 'react';
import _ from 'lodash';
import { Menu, Button, Icon, Popup } from 'semantic-ui-react';
import { combinePathSelectors } from '@platform/utils/general';
import { splitLogicPath } from '@platform/utils/collections';

import FormInput from '../../../FormInput';
import LearnMore from '../../../LearnMore';

import LogicLocation from './logic-location';

export const CapturesTabItem = ({ step, active, onClick }) => {
  const captures = _.get(step, 'after.transforms');
  let captureName = 'Captures';
  if (!_.isEmpty(captures)) {
    captureName = (
      <span>
        Captures{' '}
        <b>
          <span className="ml-2 text-active">[{captures.length}]</span>
        </b>
      </span>
    );
  }

  return (
    <Menu.Item name="captures" active={active} onClick={onClick}>
      {captureName}
    </Menu.Item>
  );
};

const Captures = ({ logicLocations, fields = [], handleUpdate, readOnly }) => {
  return (
    <div>
      <div className={`${!_.isEmpty(fields) ? 'mb-3' : ''}`}>
        <Button
          onClick={() => handleUpdate('push', [], { target: '$.ctx', source: 'output.body' })}
          basic
          disabled={readOnly}
          size="tiny"
        >
          Add Capture
        </Button>

        <span className="c-muted ml-2">
          <Popup
            trigger={<Icon name="question circle" />}
            size="small"
            position="top center"
            hoverable
            wide
          >
            Save output values to the temporary $.ctx object to re-use them in subsequent steps,
            within a single run.
            <br />
            <LearnMore className="mt-2 d-b" feature="referencing-other-scenarios" />
          </Popup>
        </span>
      </div>

      {_.map(fields, (field, index) => {
        const { location: targetLocation, path: targetPath } = splitLogicPath({
          locations: logicLocations,
          path: field.target,
        });
        const { location: sourceLocation, path: sourcePath } = splitLogicPath({
          locations: logicLocations,
          path: field.source,
        });

        return (
          <div key={index} className="mt-2 text-sm">
            <div className="flex items-center">
              <LogicLocation
                className="pr-2"
                disabled={readOnly}
                value={targetLocation}
                onChange={value => {
                  handleUpdate('set', [index, 'target'], combinePathSelectors([value, targetPath]));
                }}
                placeholder="target location"
                locations={logicLocations}
                search
              />

              <FormInput
                className="flex-1"
                input={{
                  value: targetPath,
                  readOnly,
                  onChange(e, { value }) {
                    handleUpdate(
                      'set',
                      [index, 'target'],
                      combinePathSelectors([targetLocation, value])
                    );
                  },
                }}
                placeholder="path to property to save, ie `userId`"
                fluid
              />

              <div className="ml-3 mr-3 font-bold">=</div>

              <LogicLocation
                className="pr-2"
                disabled={readOnly}
                value={sourceLocation}
                onChange={value => {
                  handleUpdate('set', [index, 'source'], combinePathSelectors([value, sourcePath]));
                }}
                placeholder="source location"
                locations={logicLocations}
                search
              />

              <FormInput
                className="flex-1"
                input={{
                  value: sourcePath,
                  readOnly,
                  onChange(e, { value }) {
                    handleUpdate(
                      'set',
                      [index, 'source'],
                      combinePathSelectors([sourceLocation, value])
                    );
                  },
                }}
                placeholder="path to property to capture, ie `users[0].id`"
                fluid
              />

              <div className="ml-3">
                <Button
                  icon="trash"
                  disabled={readOnly}
                  onClick={() => {
                    if (fields.length === 1) {
                      handleUpdate('unset', []);
                    } else {
                      handleUpdate('pull', [], index);
                    }
                  }}
                  size="tiny"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Captures;
