import React from 'react';
import FormInput from '@platform/components/FormInput';
import ConnectedOperationSearch from '@platform/components/ConnectedOperationSearch';

const url = props => {
  const { value, label, placeholder, endpoints, onChange, handleResultSelect, readOnly } = props;

  let contentElem;
  if (_.isEmpty(endpoints)) {
    return (
      <div className="flex-1 pl-2">
        <FormInput
          className="flex-1"
          label={label}
          value={value}
          readOnly={readOnly}
          placeholder={placeholder}
          onChange={(e, { value }) => onChange(value)}
          fluid
        />
      </div>
    );
  }

  return (
    <div className="flex-1 pl-2">
      <ConnectedOperationSearch
        label={label}
        value={value}
        valueProp="url"
        operations={endpoints}
        placeholder={placeholder}
        protectedExpression={/\?/}
        onChange={(e, { value }) => onChange(value)}
        handleResultSelect={value => handleResultSelect(value)}
        readOnly={readOnly}
      />
    </div>
  );
};

export default url;
