import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Menu, Segment, Icon } from 'semantic-ui-react';

import FormCheckbox from '@platform/components/FormCheckbox';
import OAuth2TokenFormModal from '@platform/components/OAuth2TokenFormModal';
import UsedPathParams from '@platform/components/UsedPathParams';
import UsedCtxVariables from '@platform/components/UsedCtxVariables';
import UsedEnvironmentVariables from '@platform/components/UsedEnvironmentVariables';
import LearnMore from '@platform/components/LearnMore';

const Settings = props => {
  const { editorId, standalone, currentEnv, updateVariables, oauth2, modeling } = props;

  const elems = [];

  if (standalone) {
    elems.push(
      <FormCheckbox
        key="skipPrism"
        className="mt-4"
        checked={currentEnv.skipPrism}
        onChange={() => {
          updateVariables({
            ...currentEnv,
            skipPrism: !currentEnv.skipPrism,
          });
        }}
        label="Send requests directly from the browser (CORS must be enabled)"
        labelPosition="left"
        labelStyle={{ color: 'black' }}
        tip={
          <div>
            Disabling this option will proxy HTTP requests through Prism in order to bypass Cross
            Origin Resource Sharing (CORS). <LearnMore feature="sending-http-requests" />
          </div>
        }
        inline
      />
    );
  }

  elems.push(
    <UsedPathParams key="1" editorId={editorId} name="Path Params" className="text-sm mt-4" />
  );

  if (!modeling) {
    elems.push(
      <UsedCtxVariables key="2" editorId={editorId} name="$.ctx" className="text-sm mt-4" />
    );
  }

  elems.push(
    <UsedEnvironmentVariables
      key="3"
      editorId={editorId}
      name="$$.env"
      className="text-sm mt-4"
      showEmptyMessage="No $$.env variables are being used in this request."
    />
  );

  if (oauth2) {
    elems.push(
      <div key="4" className="mt-4">
        <OAuth2TokenFormModal oauth2={oauth2} readOnly />
      </div>
    );
  }

  return elems;
};

export default Settings;
