import React from 'react';
import _ from 'lodash';
import { Input } from 'semantic-ui-react';

const InputResultList = props => {
  const { className = '', fields = {}, sort } = props;

  let processed = fields;
  if (sort) {
    processed = {};
    _.forEach(_.keys(fields).sort(), key => {
      processed[key] = fields[key];
    });
  }

  return (
    <div className={`InputResultList ${className}`}>
      {_.map(processed, (value, key) => {
        return (
          <div key={key} className="mb-2">
            <div className="flex">
              <Input defaultValue={key} className="flex-1" readOnly />
              <Input defaultValue={value} className="flex-1" readOnly />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InputResultList;
