import React from 'react';
import _ from 'lodash';
import { Button, Modal } from 'semantic-ui-react';

export default class SLModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { modalOpen: props.initialOpen };
  }

  handleOpen = e => {
    this.props.onOpen ? this.props.onOpen() : null;

    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    if (this.props.onClose) this.props.onClose();

    if (!this.props.forceOpen) {
      this.setState({
        modalOpen: false,
      });
    }
  };

  render() {
    const {
      className = '',
      title,
      content,
      contentFactory,
      forceOpen,
      triggerFactory,
      triggerButton,
      cancelButton,
      confirmButton,
      size = 'small',
      contentProps = {},
      ...otherProps
    } = this.props;

    const modalProps = _.omit(otherProps, ['initialOpen']);
    const { modalOpen } = this.state;

    const open = modalOpen || forceOpen;

    let triggerElem;
    if (triggerButton) {
      triggerElem = (
        <Button
          {...triggerButton}
          onClick={e => {
            triggerButton.onClick ? triggerButton.onClick(e, this) : this.handleOpen(e);
          }}
        />
      );
    } else if (triggerFactory) {
      triggerElem = triggerFactory({ handleOpen: this.handleOpen });
    }

    const modalActions = [];

    if (cancelButton) {
      modalActions.push(
        <Button
          key="modal-cancelButton"
          {...cancelButton}
          onClick={e => {
            cancelButton.onClick ? cancelButton.onClick(e, this) : this.handleClose(e);
          }}
        />
      );
    }

    if (confirmButton) {
      modalActions.push(
        <Button
          key="modal-confirmButton"
          {...confirmButton}
          onClick={e => {
            confirmButton.onClick ? confirmButton.onClick(e, this) : this.handleClose(e);
          }}
        />
      );
    }

    return (
      <Modal
        {...modalProps}
        className={className}
        trigger={triggerElem}
        open={open || false}
        onClose={this.handleClose}
        size={size}
        style={{ top: '10%' }}
      >
        {title && <Modal.Header>{title}</Modal.Header>}

        <Modal.Content {...contentProps}>
          <Modal.Description>{open ? contentFactory(this) : content}</Modal.Description>
        </Modal.Content>

        {modalActions.length > 0 && <Modal.Actions>{modalActions}</Modal.Actions>}
      </Modal>
    );
  }
}
