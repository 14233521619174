import React from 'react';
import _ from 'lodash';

import MarkdownViewer from '@platform/components/MarkdownViewer';
import ErrorMessage from '@platform/components/ErrorMessage';

import { safeStringify } from '@platform/utils/json';

const TextBlock = props => {
  const { WrapperFactory, header, data, editor } = props;

  const { token, title } = header || {};

  let headerElem;
  if (token || title) {
    headerElem = (
      <div className="TextBlock-header">
        <div className="flex flex-wrap items-center">
          {token ? (
            <div className={`TextBlock-header-token token--${_.toLower(token)}`}>{token}</div>
          ) : null}

          {title ? <h1 className="TextBlock-header-title wb-bw">{title}</h1> : null}
        </div>
      </div>
    );
  }

  let contentElem;

  // Don't render if undefined or null
  if (!_.isNil(data)) {
    // Only render data as object if it contains an error
    if (typeof data === 'object') {
      if (data.__error) {
        contentElem = <ErrorMessage error={data.__error} />;
      }
    } else {
      contentElem = (
        <MarkdownViewer
          value={data}
          context={{
            a({ href }) {
              const props = {};

              // pass internal paths through the store's buildViewPath function
              if (editor && (_.startsWith(href, '/') || _.startsWith(href, '#'))) {
                props.href = editor.buildViewPath(href);
              }

              return props;
            },
          }}
        />
      );
    }
  }

  return <WrapperFactory {...props} headerElem={headerElem} contentElem={contentElem} />;
};

export default TextBlock;
