import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { BaseMarkdown } from '@platform/utils/markdown';

import HtmlViewer from '../HtmlViewer';

const MarkdownViewer = ({
  value,
  className = '',
  innerClassName = '',
  style = {},
  options = {},
  context = {},
}) => {
  // Don't render undefined, null or empty objects
  if (_.isNil(value) || (typeof value === 'object' && _.isEmpty(value))) return null;

  let markdown = value;
  if (typeof markdown !== 'string') {
    // Stringify objects and numbers
    markdown = JSON.stringify(markdown);
  }

  // Don't render if empty string
  if (_.isEmpty(markdown)) return null;

  return (
    <div className={cn('MarkdownViewer markdown-body', className)} style={style}>
      <HtmlViewer
        className={innerClassName}
        value={BaseMarkdown.render(markdown)}
        options={Object.assign({ anchors: true }, options)}
        context={context}
      />
    </div>
  );
};

export default MarkdownViewer;
