import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';

import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import MarkdownEditor from '../MarkdownEditor';

const typeOptions = [
  { text: 'HTTP', value: 'http' },
  { text: 'Script', value: 'script' },
  { text: 'Reference', value: 'ref' },
  // {text: 'Lambda', value: 'lambda'},
];

function StepTypeSelector({ typeWhitelist, ...extraProps }) {
  const options = [];
  for (const o of typeOptions) {
    if (_.isEmpty(typeWhitelist) || typeWhitelist[o.value]) {
      options.push(o);
    }
  }

  return (
    <FormSelect options={options} defaultValue="http" placeholder="choose one" {...extraProps} />
  );
}

const createScriptFromHTTP = (before, after) => {
  let script = '';

  if (!_.isEmpty(before)) {
    const beforeReg = new RegExp('// COPIED FROM BEFORE SCRIPT');
    if (!beforeReg.test(before)) {
      script = '// COPIED FROM BEFORE SCRIPT \n';
    }

    script = `${script} ${before} \n`;
  }

  if (!_.isEmpty(after)) {
    const afterReg = new RegExp('// COPIED FROM AFTER SCRIPT');
    if (!afterReg.test(after)) {
      script = `${script} \n // COPIED FROM AFTER SCRIPT \n`;
    }

    script = `${script} ${after} \n`;
  }

  return script;
};

const StepBasics = props => {
  const { ui, updateUi, step = {}, onChange, typeWhitelist = [], readOnly } = props;

  return (
    <div className="StepBasics">
      <div className="flex">
        <div className="StepBasics-docs">
          <Button
            basic={!ui.docsOpen}
            secondary={ui.docsOpen || !_.isEmpty(step.description)}
            icon="book"
            onClick={() => {
              updateUi('set', 'docsOpen', !ui.docsOpen);
            }}
          />
        </div>

        <StepTypeSelector
          typeWhitelist={typeWhitelist}
          disabled={readOnly}
          value={step.type}
          onChange={value => {
            if (value === step.type) {
              return;
            }

            const before = _.get(step, 'before.script', '');
            const after = _.get(step, 'after.script', '');
            const script = _.get(step, 'input.script', '');

            if (step.type === 'script' && value !== 'script' && !_.isEmpty(script)) {
              let newAfterScript = script;
              if (!_.isEmpty(after)) {
                newAfterScript = `${after} \n ${script}`;
              }

              onChange('set', ['after', 'script'], newAfterScript);
            }

            onChange('set', ['type'], value);
            switch (value) {
              case 'http':
                onChange('set', ['input'], { method: 'get', url: '' });
                break;
              case 'script':
                onChange('set', ['input'], {
                  script: createScriptFromHTTP(before, after),
                });
                onChange('unset', ['before']);
                onChange('unset', ['after']);
                break;
              case 'ref':
                onChange('set', ['input'], { ref: '' });
                onChange('unset', ['before', 'assertions']);
                onChange('unset', ['after', 'assertions']);
                break;
              default:
                break;
            }
          }}
        />

        <FormInput
          className="flex-1 ml-2"
          placeholder="step name"
          input={{
            value: _.get(step, 'name', ''),
            onChange(e) {
              onChange('set', ['name'], e.target.value);
            },
            readOnly,
          }}
          fluid
        />
      </div>

      {ui.docsOpen ? (
        <div className="mt-4">
          <MarkdownEditor
            className="StepBasics-notes"
            placeholder="Optional step notes..."
            value={_.get(step, 'description', '')}
            readOnly={readOnly}
            onChange={markdownVal => {
              onChange('set', ['description'], markdownVal);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default inject(({ appStore }) => ({
  ...appStore.injectUi('basics-step'),
}))(observer(StepBasics));
