/* eslint-disable */

// adapted from
// https://github.com/JedWatson/react-codemirror/blob/930600810e5ceb4ad426a66098ecf1404d9e32c3/src/Codemirror.js

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import _ from 'lodash';

import LineNumberWorker from '@platform/utils/json/lineNumbers-worker';

const findDOMNode = ReactDOM.findDOMNode;

function normalizeLineEndings(str) {
  if (!str) return str;
  return str.replace(/\r\n|\r/g, '\n');
}

const setterFunc = () => {};
_.set(setterFunc, 'prototype.displayText', 'set(key: String, val: any) OR set(val: any)');

const getterFunc = () => {};
_.set(getterFunc, 'prototype.displayText', 'get(key: String) OR get()');

const buildRequest = (root, scopes) => {
  let displayText;

  if (!root && scopes.step) {
    displayText = 'input    : The HTTP request this step will send.';
  } else if (scopes.proxy) {
    displayText =
      'request  : The incoming HTTP request, before it is proxied to your upstream URL.';
  } else {
    displayText =
      'request  : The incoming HTTP request. Only applicable when triggering the collection from URL.';
  }

  const obj = {
    prototype: {
      displayText,
    },

    method: {
      get: getterFunc,
      set: setterFunc,
    },
    url: {
      get: getterFunc,
      set: setterFunc,
    },
    body: {
      get: getterFunc,
      set: setterFunc,
    },
    headers: {
      get: getterFunc,
      set: setterFunc,
    },
    set: setterFunc,
    get: getterFunc,
  };

  return obj;
};

const buildResponse = (root, scopes) => {
  let displayText;

  if (!root && scopes.step) {
    displayText = 'output   : The HTTP response returned from the request sent in this step.';
  } else if (scopes.proxy) {
    displayText = 'response : The HTTP response. This is sent back to the downstream consumer.';
  } else {
    displayText =
      'response : The HTTP response. Only applicable when triggering the collection from URL.';
  }

  const obj = {
    prototype: {
      displayText,
    },

    status: {
      get: getterFunc,
      set: setterFunc,
    },
    body: {
      get: getterFunc,
      set: setterFunc,
    },
    headers: {
      get: getterFunc,
      set: setterFunc,
    },
    set: setterFunc,
    get: getterFunc,
  };

  return obj;
};

const buildDoubleDollar = scopes => {
  let displayText;
  let envDisplayText;

  if (scopes.proxy) {
    displayText = '$$       : Properties and functions to work with the current HTTP request.';
    envDisplayText = "env      : The instance's current environment properties.";
  } else {
    displayText = '$$       : Properties and functions to work with the current collection run.';
    envDisplayText = "env      : The collection's current environment properties.";
  }

  const obj = {
    prototype: {
      displayText,
    },
    env: {
      prototype: {
        displayText: envDisplayText,
      },
      get: getterFunc,
      set: setterFunc,
    },
    request: buildRequest(true, scopes),
    response: buildResponse(true, scopes),
  };

  return obj;
};

const buildSingleDollar = scopes => {
  let displayText;

  const obj = {
    prototype: {
      displayText: '$        : Properties and functions to work with the current scenario.',
    },

    ctx: {
      prototype: {
        displayText:
          'ctx      : The scenario context. Store temporary values here. They can be accessed in subsequent steps.',
      },

      get: getterFunc,
      set: setterFunc,
    },
  };

  return obj;
};

const buildInput = scopes => {
  let obj = {};

  if (scopes.http) {
    obj = buildRequest(false, scopes);
  }

  return obj;
};

const buildOutput = scopes => {
  let obj = {};

  if (scopes.http) {
    obj = buildResponse(false, scopes);
  }

  return obj;
};

const buildGlobalScope = scopes => {
  const globalScope = {
    $$: buildDoubleDollar(scopes),
    $: buildSingleDollar(scopes),
  };

  if (scopes.step) {
    const input = buildInput(scopes);
    if (!_.isEmpty(input)) {
      globalScope.input = input;
    }

    const output = buildOutput(scopes);
    if (!_.isEmpty(output)) {
      globalScope.output = output;
    }

    if (scopes.conductor) {
      globalScope.tests = {
        prototype: {
          displayText:
            "tests    : Set this object's properties to true or false to add assertions.",
        },
      };
    }
  }

  // helpers
  globalScope.SL = {
    prototype: {
      displayText: 'SL       : Various helper functions.',
    },

    specs: {
      prototype: {
        displayText: 'specs : Functions to work with connected API specifications.',
      },

      buildResponse: {
        prototype: {
          displayText:
            'buildResponse(method: String, url: String, statusCode: Number, contentType: String, exampleKey?: String) response',
        },
      },
    },
  };

  // lodash
  globalScope._ = {
    prototype: {
      displayText: '_        : Lodash library functions.',
    },

    // ..._, // DANGER, this causes ALL of lodash to be loaded into our bundle
  };

  return globalScope;
};

function buildOptions(props) {
  const newOptions = props.options || {};

  if (!_.isEmpty(props.autocompleteScopes)) {
    const { autocompleteScopes } = props;

    _.set(newOptions, ['extraKeys', 'Ctrl-Space'], 'autocomplete');
    _.set(newOptions, ['extraKeys', "'.'"], cm => {
      setTimeout(() => {
        cm.execCommand('autocomplete');
      }, 100);
      return props.codeMirrorInstance.Pass; // tell CodeMirror we didn't handle the key
    });

    newOptions.hintOptions = {
      completeSingle: false,
      globalScope: buildGlobalScope(autocompleteScopes),
    };
  }

  return newOptions;
}

class CodeMirrorComponent extends React.Component {
  static displayName = 'CodeMirror';

  static propTypes = {
    className: PropTypes.any,
    codeMirrorInstance: PropTypes.func,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onFocusChange: PropTypes.func,
    onScroll: PropTypes.func,
    options: PropTypes.object,
    path: PropTypes.string,
    value: PropTypes.string,
    preserveScrollPosition: PropTypes.bool,
    scopes: PropTypes.object,
  };

  static defaultProps = {
    preserveScrollPosition: false,
  };

  state = {
    isFocused: false,
  };

  currentHighlightLineNumbers = null;

  getCodeMirrorInstance = () => {
    return this.props.codeMirrorInstance;
  };

  componentWillMount() {
    this.highlightLines = _.debounce(this.highlightLines, 500, {
      leading: true,
    });
  }

  componentDidMount() {
    const { highlightPath, highlightRange, value, defaultValue } = this.props;

    var textareaNode = findDOMNode(this.refs.textarea);
    var codeMirrorInstance = this.getCodeMirrorInstance();
    this.codeMirror = codeMirrorInstance.fromTextArea(textareaNode, buildOptions(this.props));
    this.codeMirror.on('change', this.codemirrorValueChanged);
    this.codeMirror.on('focus', this.focusChanged.bind(this, true));
    this.codeMirror.on('blur', this.focusChanged.bind(this, false));
    this.codeMirror.on('scroll', this.scrollChanged);
    this.codeMirror.setValue(defaultValue || value || '');

    if (highlightPath) {
      this.highlightLines(value, highlightPath, { focus: true });
    }

    if (highlightRange) {
      this.highlightLineNumbers(highlightRange);
    }
  }

  componentWillUnmount() {
    // is there a lighter-weight way to remove the cm instance?
    if (this.codeMirror) {
      this.codeMirror.toTextArea();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.codeMirror &&
      nextProps.value !== undefined &&
      normalizeLineEndings(this.codeMirror.getValue()) !== normalizeLineEndings(nextProps.value)
    ) {
      if (this.props.preserveScrollPosition) {
        var prevScrollPosition = this.codeMirror.getScrollInfo();
        this.codeMirror.setValue(nextProps.value);
        this.codeMirror.scrollTo(prevScrollPosition.left, prevScrollPosition.top);
      } else {
        this.codeMirror.setValue(nextProps.value);
      }
    }

    if (typeof nextProps.options === 'object') {
      const options = buildOptions(nextProps);
      for (var optionName in options) {
        if (options.hasOwnProperty(optionName)) {
          this.codeMirror.setOption(optionName, options[optionName]);
        }
      }
    }

    // if the highlightPath has changed, we need to recalculate
    if (nextProps.highlightPath && !_.isEqual(this.props.highlightPath, nextProps.highlightPath)) {
      this.highlightLines(nextProps.value, nextProps.highlightPath, { focus: true });
    }

    // if the highlightRange has changed, we need to recalculate
    if (
      nextProps.highlightRange &&
      !_.isEqual(this.props.highlightRange, nextProps.highlightRange)
    ) {
      this.unHighlightLineNumbers(this.props.highlightRange);
      this.highlightLineNumbers(nextProps.highlightRange);
    }
  }

  highlightLines(data, propPath, opts = {}) {
    if (_.isEmpty(propPath)) {
      return;
    }

    const { focus } = opts;

    LineNumberWorker.postMessage({ data, propPath })
      .then(lineNumbers => {
        if (!lineNumbers || _.get(lineNumbers, 'error')) {
          // can't highlight if we can't parse the JSON in the first place
          return;
        }
        const highlightStart = _.get(lineNumbers, 'from', 0);
        const highlightEnd = _.get(lineNumbers, 'to', 0);

        if (focus) {
          const y = this.codeMirror.charCoords({ line: highlightStart - 1, ch: 0 }, 'local').top;
          this.codeMirror.scrollTo(null, Math.max(y - 33, 0));
        }

        if (_.isEqual(this.currentHighlightLineNumbers, lineNumbers)) {
          // if line numbers have not changed, we're set!
          return;
        }

        if (this.currentHighlightLineNumbers) {
          const prevHighlightStart = this.currentHighlightLineNumbers.from;
          const prevHighlightEnd = this.currentHighlightLineNumbers.to;
          this.unHighlightLineNumbers([{ from: prevHighlightStart, to: prevHighlightEnd }]);
        }

        this.highlightLineNumbers([{ from: highlightStart, to: highlightEnd }]);

        this.currentHighlightLineNumbers = lineNumbers;
      })
      .catch(e => {
        console.error('LineNumberWorker:error', { error: e, data, propPath });
      });
  }

  unHighlightLineNumbers = lineNumbers => {
    _.forEach(lineNumbers, lineNumber => {
      this.codeMirror.eachLine(Math.max(lineNumber.from - 1, 0), lineNumber.to, line => {
        this.codeMirror.removeLineClass(line, 'wrap', 'CodeMirror-highlightedText');
      });
    });
  };

  highlightLineNumbers = lineNumbers => {
    _.forEach(lineNumbers, lineNumber => {
      this.codeMirror.eachLine(Math.max(lineNumber.from - 1, 0), lineNumber.to, line => {
        this.codeMirror.addLineClass(line, 'wrap', 'CodeMirror-highlightedText');
      });
    });
  };

  getCodeMirror = () => {
    return this.codeMirror;
  };

  focus = () => {
    if (this.codeMirror) {
      this.codeMirror.focus();
    }
  };

  focusChanged = focused => {
    const { onFocusChange } = this.props;

    this.setState({
      isFocused: focused,
    });

    onFocusChange && onFocusChange(this.getCodeMirror().doc.getValue(), focused);
  };

  scrollChanged = cm => {
    const { onScroll } = this.props;
    onScroll && onScroll(cm.getScrollInfo());
  };

  codemirrorValueChanged = (doc, change) => {
    const { highlightPath, onChange } = this.props;

    const value = doc.getValue();

    this.highlightLines(value, highlightPath);

    if (onChange && change.origin !== 'setValue') {
      onChange(value, change);
    }
  };

  render() {
    const { path, value, className } = this.props;
    const { isFocused } = this.state;

    var editorClassName = cn('ReactCodeMirror', className, {
      'ReactCodeMirror--focused': isFocused,
    });

    return (
      <div className={editorClassName}>
        <textarea ref="textarea" name={path} defaultValue={value} autoComplete="off" />
      </div>
    );
  }
}

export default CodeMirrorComponent;
