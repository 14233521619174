import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { deepCompare } from '@platform/utils/hoc';
import { Label } from 'semantic-ui-react';

import FormInputLabel from '../FormInputLabel';
import FormDropdown from '../FormDropdown';

// import './styles.styl';

class FormSelect extends React.Component {
  onChange = (e, option) => {
    const { input = {}, onChange } = this.props;

    if (input.onChange) {
      input.onChange(onChange ? onChange(option.value, option) : option.value, option);
    } else if (onChange) {
      onChange(option.value, option);
    }
  };

  render() {
    const {
      value,
      input = {},
      big,
      size,
      loc,
      defaultValue = '',
      className = '',
      label,
      tip,
      required,
      fluid,
      options = [],
      multiple,
      allowAdditions,
      search,
      placeholder,
      inline,
      disabled,
      readOnly,
    } = this.props;

    if (!_.isEmpty(input)) {
      console.warn("Depricating prop 'input' in <FormSelect />");
    }

    const classNames = ['FormSelect'];

    if (big || ((size && size === 'big') || size === 'large')) {
      classNames.push('is-big');
    } else if (size) {
      classNames.push(`is-${size}`);
    }

    if (loc) {
      classNames.push(`is-${loc}`);
    }

    let v = input.value || value || defaultValue;
    let computedOptions = options;
    if (multiple) {
      // remove null / undefined values, and always make sure current values are part of options
      // use without instead of compact to keep zero values
      v = _.without(_.isArray(v) ? v : [v], false, null, '', undefined, NaN);
      computedOptions = _.uniq(
        computedOptions.concat(
          _.map(v, v2 => ({
            text: v2,
            value: v2,
          }))
        )
      );
    }

    let labelElem;
    let isFixedWidth;

    if (_.isObject(label)) {
      isFixedWidth = label.className === 'Label-fw';

      labelElem = <Label {...label} className={cn(label.className, 'flex-1')} />;
    } else {
      labelElem = <FormInputLabel inline={inline} label={label} required={required} tip={tip} />;
    }

    return (
      <div
        className={cn(
          'FormSelect',
          className,
          isFixedWidth ? 'flex items-center' : 'flex',
          inline ? 'is-inline items-center' : 'flex-col'
        )}
      >
        {labelElem}

        <div className="flex-1">
          <FormDropdown
            selection
            fluid={fluid}
            options={computedOptions}
            multiple={multiple}
            allowAdditions={allowAdditions}
            search={search}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            {...input}
            className={classNames.join(' ')}
            value={v}
            onChange={this.onChange}
            clearSearchOnSelect
          />
        </div>
      </div>
    );
  }
}

export default deepCompare()(FormSelect);
