import React from 'react';
import JsonSchemaViewer from '@platform/components/JsonSchemaViewer';

class JsonSchemaBlock extends React.Component {
  getSchema = () => {
    const { data = {}, dereferencedBlock = {} } = this.props;
    return dereferencedBlock.data || data;
  };

  render = () => {
    const { WrapperFactory, config = {} } = this.props;
    const schema = this.getSchema();

    const contentElem = (
      <JsonSchemaViewer
        schema={schema}
        dereferencedSchema={schema}
        hideRoot={config.hideRoot}
        hideInheritedFrom
        defaultExpandedDepth={config.expandedDepth || 0}
      />
    );

    return <WrapperFactory {...this.props} contentElem={contentElem} />;
  };
}

export default JsonSchemaBlock;
