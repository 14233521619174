import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import CodeGenerator from '../../../CodeGenerator';

import { buildQueryString } from '@platform/utils/url';

function CodeGen(props) {
  const { step, vars, specs } = props;

  let data = _.cloneDeep(step.input);

  if (!data) {
    return null;
  }

  const newUrl = buildQueryString({ url: data.url, params: data.query }).url;

  const params = {};

  _.forEach(vars, (sub, type) => {
    if (!_.isEmpty(sub)) {
      _.forEach(sub, (value, param) => {
        let p;
        switch (type) {
          case 'ctx':
            p = `$.ctx.${param}`;
            break;
          case 'env':
            p = `$$.env.${param}`;
            break;
          default:
            p = param;
        }

        params[p] = value;
      });
    }
  });

  _.set(data, 'params', params);
  _.set(data, 'url', newUrl);

  const schemes = specs.reduce((schemes, spec) => {
      (spec.data.schemes || []).forEach(scheme =>
          !schemes.includes(scheme) && schemes.push(scheme));
      return schemes;
  }, []);

  _.set(data, 'schemes', schemes);

  return <CodeGenerator className="text-sm" data={data} />;
}

export default inject(({ collectionEditorStore }, { editorId }) => {
  const editor = collectionEditorStore.getEditor({ id: editorId });

  const vars = {
    env: editor.currentPopulatedEnvVariables,
    ctx: editor.currentPopulatedCtxVariables,
    path: editor.currentStepPathParams,
  };

  return {
    vars,
    specs: editor.connectedSpecs,
  };
})(observer(CodeGen));
