import React from 'react';
import cn from 'classnames';

// import './styles.styl';

const BarListItem = ({ index, parts = [], loading, withBorder, className }) => {
  return (
    <div
      className={cn('BarListItem flex items-center', className, {
        'is-loading': loading,
        'with-border': withBorder,
      })}
    >
      {index ? <div className="BarListItem-index">{index}</div> : null}

      <div className="BarListItem-loading fill-space" />

      {parts.map((part, i) => {
        const style = {};
        if (part.bgColor) {
          style.backgroundColor = part.bgColor;
          style.color = 'white';
        }

        return (
          <div
            key={i}
            className={cn('BarListItem-part', part.className, {
              'd-n': part.hide,
              'is-padded': part.padded,
              'with-bg': part.bg,
              'with-border': part.border,
              'flex-1': part.fill,
            })}
            style={style}
          >
            {part.elem()}
          </div>
        );
      })}
    </div>
  );
};

export default BarListItem;
