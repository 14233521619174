import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import TimeAgo from 'react-timeago';
import { inject, observer } from 'mobx-react';
import { Button, Table, Popup, Dropdown } from 'semantic-ui-react';

import { alert } from '@platform/utils/alert';

import OAuth2TokenFormModal from '../../../../OAuth2TokenFormModal';
import EntityEditorGroup from '../../../../EntityEditorGroup';

import { updateInputWithAuth } from '@platform/utils/authorization';

const OAuth2 = props => {
  const {
    ui = {},
    uiScope,
    updateUi,

    userId,
    handleUpdate,
    currentVariables,
    activeToken,
    input = {},
    config = {},
    editor,
    tokenStore,
  } = props;

  const tokens = tokenStore.tokenList;

  return (
    <div>
      <OAuth2TokenFormModal oauth2={config.oauth2} />

      <EntityEditorGroup
        id="oath-Tokens"
        className="text-sm mt-6"
        name="Tokens"
        active={activeToken}
        error={!activeToken}
        forceOpen={!activeToken}
        subtitle={!activeToken ? 'No active token' : null}
        contentFactory={() =>
          !_.isEmpty(tokens) ? (
            <div className="p-4">
              <Table
                basic="very"
                tableData={tokens}
                compact="very"
                headerRow={
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                    <Table.HeaderCell>Scope</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                }
                renderBodyRow={(token, index) => {
                  const active = activeToken === token.access_token;

                  return (
                    <Table.Row key={index}>
                      <Table.Cell className="oauth-add">
                        <Dropdown
                          className={active ? 'active-token' : null}
                          trigger={
                            <div
                              disabled={true}
                              className={cn(
                                'text-sm px-2 py-1/2 text-white rounded',
                                active
                                  ? 'bg-green cursor-default'
                                  : 'bg-blue cursor-pointer hover:opacity-100 opacity-85'
                              )}
                            >
                              {active ? 'Active' : 'Set Active'}
                            </div>
                          }
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              text="Add To Headers"
                              onClick={() => {
                                handleUpdate(
                                  'set',
                                  ['input'],
                                  updateInputWithAuth(
                                    input,
                                    {
                                      access_token: token.access_token,
                                      useHeader: true,
                                      type: 'oauth2',
                                    },
                                    currentVariables
                                  )
                                );

                                if (editor) {
                                  editor.updateVariables(
                                    { oauth_access_token: token.access_token },
                                    { preserveShared: true }
                                  );
                                }

                                alert.success('Access token added to headers.');
                              }}
                            />
                            <Dropdown.Item
                              text="Add To Query"
                              onClick={() => {
                                handleUpdate(
                                  'set',
                                  ['input'],
                                  updateInputWithAuth(
                                    input,
                                    {
                                      access_token: token.access_token,
                                      type: 'oauth2',
                                    },
                                    currentVariables
                                  )
                                );

                                if (editor) {
                                  editor.updateVariables(
                                    { oauth_access_token: token.access_token },
                                    { preserveShared: true }
                                  );
                                }

                                alert.success('Access token added to query.');
                              }}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                      <Table.Cell>{token.name}</Table.Cell>
                      <Table.Cell>
                        <Popup
                          size="mini"
                          trigger={
                            <a
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                copy(token.access_token);
                                updateUi('set', uiScope.concat(['copy']), 'Copied!');
                              }}
                            >
                              {`****${token.access_token.slice(-4)}`}{' '}
                            </a>
                          }
                          content={ui.copy}
                          onClose={() =>
                            updateUi(
                              'set',
                              uiScope.concat(['copy']),
                              'Click to copy token value to clipboard'
                            )
                          }
                          onOpen={() =>
                            updateUi(
                              'set',
                              uiScope.concat(['copy']),
                              'Click to copy token value to clipboard'
                            )
                          }
                          inverted
                        />
                      </Table.Cell>
                      <Table.Cell>{token.scope}</Table.Cell>
                      <Table.Cell>
                        <TimeAgo date={token.created_at} minPeriod={60} />
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button
                          icon="trash"
                          color="red"
                          size="tiny"
                          onClick={() => {
                            if (!tokenStore) return;

                            const r = window.confirm('Are you sure you want to delete this token?');
                            if (!r) return;

                            const remove = tokenStore.remove(token.id, {
                              userId,
                            });

                            if (window.Electron && remove) {
                              remove
                                .then(() => {
                                  alert.warning('Token removed');
                                })
                                .catch(e => {
                                  alert.error(`Error removing token: ${String(e)}`);
                                });
                            }
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                }}
              />
            </div>
          ) : (
            <div className="text-sm p-4 text-muted">You have no tokens</div>
          )
        }
      />
    </div>
  );
};

export default inject((stores, props) => {
  const { oauthTokenStore, userService, projectService } = stores;

  const userId = _.get(userService, 'authorizedUser.id');
  const projectId = _.get(projectService, 'current.id');

  const tokenStore = oauthTokenStore.getOrRegisterInstance(userId, projectId);

  return {
    tokenStore,
  };
})(observer(OAuth2));
