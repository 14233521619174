import Browser from 'bowser';

export const currentBrowser = Browser;

export const check = ({ browsers = {}, userAgent } = {}) => {
  return Browser.check(browsers, userAgent);
};

export const browser = ({ userAgent } = {}) => {
  return Browser._detect(userAgent);
};

/**
 * Minimum browser version supported
 * If a browser is not listed then all its versions are supported
 * To test this, just change the minimum version to one higher than yours
 */
export const supportedBrowsers = () => {
  return {
    chrome: '50',
    firefox: '45',
    msie: '12', // IE 12 doesn't exist so we aren't supporting and IE versions
    msedge: '14',
    safari: '10',
    opera: '32',
  };
};

export const isSupportedBrowser = ({ userAgent } = {}) => {
  const re = new RegExp('electron', 'i');
  const isElectron = re.test(userAgent);

  return isElectron || !Browser.isUnsupportedBrowser(supportedBrowsers(), userAgent);
};
