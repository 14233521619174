import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Menu, Icon, Button, Header, Popup } from 'semantic-ui-react';

import { safeStringify, safeParse } from '@platform/utils/json';
import { splitLogicPath } from '@platform/utils/collections';
import { combinePathSelectors } from '@platform/utils/general';
import { getEndpointFromSpecs } from '@platform/utils/specs/swagger';

import FormSelect from '../../../FormSelect';
import FormInput from '../../../FormInput';
import LearnMore from '../../../LearnMore';
import ScenarioContractTest from '../../../ScenarioContractTest';

import LogicLocation from './logic-location';

const AssertionOpSelector = props => {
  return (
    <FormSelect
      placeholder="choose an operation"
      options={[
        { text: 'equals', value: 'eq' },
        { text: 'greater than', value: 'gt' },
        { text: 'greater than or equals', value: 'gte' },
        { text: 'less than', value: 'lt' },
        { text: 'less than or equals', value: 'lte' },
        { text: 'not equal', value: 'ne' },
        { text: 'exists', value: 'exists' },
        { text: 'length equals', value: 'length' },
        { text: 'contains', value: 'contains' },
        { text: 'validate pass', value: 'validate.pass' },
        { text: 'validate fail', value: 'validate.fail' },
      ]}
      {...props}
    />
  );
};

export const TestsTabItem = ({ step, active, onClick }) => {
  const after = _.get(step, 'after') || {};
  let testName = 'Tests';
  const testIcons = [];
  if (!_.isEmpty(after.assertions)) {
    const contractTest = _.find(after.assertions, { op: 'validate.contract' });

    testIcons.push(<span key="1">[{after.assertions.length}]</span>);
    if (contractTest) {
      testIcons.push(
        <span key="2" className="ml-2 text-active">
          <Icon name="flask" />
        </span>
      );
    }
  }
  if (!_.isEmpty(testIcons)) {
    testName = (
      <span>
        Tests{' '}
        <b>
          <span className="ml-2 text-active">{testIcons}</span>
        </b>
      </span>
    );
  }

  return (
    <Menu.Item name="tests" active={active} onClick={onClick}>
      {testName}
    </Menu.Item>
  );
};

export const AssertionList = props => {
  const {
    scenarioId,
    stepId,
    input = {},
    fields = [],
    handleUpdate,
    connectedSpecs,
    variables,
    logicLocations,
    readOnly,
  } = props;

  // let statusField;
  // let statusIndex;

  // Build basic assertions

  const assertionElems = fields.map((field, index) => {
    // if (field.target === 'output.status') {
    //   statusField = field;
    //   statusIndex = index;
    //   return null;
    // }

    const { location, path } = splitLogicPath({ locations: logicLocations, path: field.target });
    const locationDisabled = location.match(/status|time/) ? true : false;
    const expectedDisabled = field && field.op && field.op.match(/exists/) ? true : false;

    let expected = _.isUndefined(field.expected) ? '' : field.expected;
    // if this is a number that they are forcing as a string, add quotes to indicate that
    if (typeof expected === 'string' && _.isNumber(safeParse(expected))) {
      expected = `"${expected}"`;
    }

    // Cast value to string to allow 0 number
    if (_.isNumber(safeParse(expected))) {
      expected = String(expected);
    }

    expected = safeStringify(expected);

    return (
      <div key={index} className="mt-2">
        <div className="flex">
          <LogicLocation
            className="pr-2"
            disabled={readOnly}
            value={location}
            onChange={value => {
              let newPath;
              if (value.match(/status|time/)) {
                newPath = [value];
              } else {
                newPath = [value, path];
              }

              handleUpdate('set', [index, 'target'], combinePathSelectors(newPath));
            }}
            locations={logicLocations}
            search
          />

          <FormInput
            className="flex-1 pr-2"
            input={{
              value: `${path}`,
              readOnly,
              onChange(e, { value }) {
                handleUpdate('set', [index, 'target'], combinePathSelectors([location, value]));
              },
            }}
            disabled={locationDisabled}
            placeholder={locationDisabled ? 'n/a' : 'path to property ie `data[0].id`'}
            fluid
          />

          <AssertionOpSelector
            className="pr-2"
            disabled={readOnly}
            value={field.op}
            onChange={value => {
              handleUpdate('set', [index, 'op'], value);

              if (value.match(/exists/)) {
                handleUpdate('unset', [index, 'expected']);
              }
            }}
          />

          <FormInput
            className="flex-1"
            input={{
              value: `${expected}`,
              readOnly,
              onChange(e, { value }) {
                let val = value ? safeParse(value, value) : '';

                // stringify if they are trying to force string
                if (value && value.charAt(0) === '"') {
                  val = String(val);
                }

                handleUpdate('set', [index, 'expected'], val);
              },
            }}
            disabled={expectedDisabled}
            placeholder={expectedDisabled ? 'n/a' : 'expected value'}
            fluid
          />

          <div className="ml-3">
            <Button
              icon="trash"
              disabled={readOnly}
              onClick={() => {
                if (fields.length === 1) {
                  handleUpdate('unset', []);
                } else {
                  handleUpdate('pull', [], index);
                }
              }}
              size="tiny"
            />
          </div>
        </div>
      </div>
    );
  });

  // Build contract assertion

  let definedResponseCodes;
  const method = _.get(input, 'method');
  const url = _.get(input, 'url');

  const endpoint = getEndpointFromSpecs({
    specs: connectedSpecs,
    method,
    url,
    variables: {
      env: variables,
    },
  });

  if (endpoint) {
    definedResponseCodes = endpoint.responses || [];
  }

  return (
    <div>
      <div className={`${!_.isEmpty(fields) ? 'mb-3' : ''}`}>
        <Button
          onClick={() =>
            handleUpdate('push', [], { target: 'output.status', op: 'eq', expected: 200 })
          }
          basic
          disabled={readOnly}
          size="tiny"
        >
          Add Assertion
        </Button>
      </div>

      <div className="mb-4 text-sm">{assertionElems}</div>

      <div className="AssertionList-contract text-sm">
        <div className="flex content-center mb-6">
          <Header as="h4">Contract Testing</Header>

          <div
            className="c-muted ml-2"
            style={{
              position: 'relative',
              top: -1,
            }}
          >
            <Popup
              trigger={<Icon name="question circle" />}
              size="small"
              position="top center"
              hoverable
              wide
            >
              {!_.isEmpty(connectedSpecs) ? (
                <div>
                  <div>
                    When this step is run, Stoplight will search your connected API Specs for an
                    endpoint that matches this request.
                  </div>

                  <div className="mt-2">
                    If found, Stoplight will use the JSON schema responses defined on the endpoint
                    to contract test your API response automatically.
                  </div>
                </div>
              ) : (
                <div>You must connect an API spec to use this feature.</div>
              )}

              <LearnMore className="d-b mt-2" feature="contract-testing" />
            </Popup>
          </div>
        </div>

        <ScenarioContractTest
          stepId={stepId}
          endpoint={endpoint}
          method={method}
          url={url}
          codes={definedResponseCodes}
          connectedSpecs={connectedSpecs}
          scenarioId={scenarioId}
        />
      </div>
    </div>
  );
};

const Tests = props => {
  const {
    collectionId,
    scenarioId,
    step,
    connectedSpecs,
    handleUpdate,
    variables,
    logicLocations,
    readOnly,
  } = props;

  return (
    <AssertionList
      variables={variables}
      collectionId={collectionId}
      scenarioId={scenarioId}
      stepId={step.id}
      input={step.input}
      fields={_.get(step, 'after.assertions')}
      connectedSpecs={connectedSpecs}
      handleUpdate={(t, p, v) => {
        handleUpdate(t, ['after', 'assertions'].concat(p), v);
      }}
      logicLocations={logicLocations}
      readOnly={readOnly}
    />
  );
};

export default inject(({ collectionEditorStore }, { editorId }) => {
  const editor = collectionEditorStore.getEditor({ id: editorId });

  return {
    variables: editor.currentEnv,
  };
})(observer(Tests));
