import React from 'react';
import _ from 'lodash';
import HtmlViewer from '@platform/components/HtmlViewer';
import ErrorMessage from '@platform/components/ErrorMessage';
import { getConfigVar } from '@platform/utils/config';

const HtmlBlock = props => {
  const { WrapperFactory, config = {}, data = '', editor } = props;

  let contentClassName;
  if (!config.plain) {
    contentClassName = 'MarkdownViewer markdown-body';
  }

  let contentElem;
  if (!_.isEmpty(data)) {
    const err = _.get(data, '__error');
    if (err) {
      contentElem = <ErrorMessage error={err} />;
    } else {
      contentElem = (
        <HtmlViewer
          value={
            _.isObject(data) ? 'block.data must be a string for html blocks' : data || 'no content'
          }
          context={{
            a({ href, title, target, children }) {
              const props = {};

              // pass internal paths through the store's buildViewPath function
              if (editor && (_.startsWith(href, '/') || _.startsWith(href, '#'))) {
                props.href = editor.buildViewPath(href);
              }

              return props;
            },
          }}
        />
      );
    }
  }

  return (
    <WrapperFactory {...props} contentClassName={contentClassName} contentElem={contentElem} />
  );
};

export default HtmlBlock;
