import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Icon, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { Link } from '@platform/utils/router';
import { isExternalLink } from '@platform/utils/url';
import { stringToColor } from '@platform/utils/colors';

const buildMetaElems = meta => {
  const metaElems = [];

  if (meta) {
    for (const i in meta) {
      const m = meta[i];

      if (m.icon) {
        metaElems.push(
          <div key={`m-${m.id || i}`} className={cn('TreeList-metaItem', m.className || 'c-muted')}>
            <Icon name={m.icon} />
          </div>
        );
      } else {
        metaElems.push(
          <div key={`m-${m.id || i}`} className={cn('TreeList-metaItem', m.className || 'c-muted')}>
            {m.name}
          </div>
        );
      }
    }
  }

  return metaElems;
};

const buildActionElems = actions => {
  return _.map(actions, (action = {}, i) => {
    const { id, href, className, type, icon, loading, onClick, tip } = action;

    let elem;

    const classNames = cn('TreeList-action', className || 'c-muted', ' flex items-center', {
      [`is-${type}`]: type,
      'is-loading': loading,
    });

    if (href) {
      elem = (
        <Link key={`a-${id || i}`} className={classNames} to={href}>
          <div>
            <Icon name={icon} loading={loading} className="TreeList-actionIcon" />
          </div>
        </Link>
      );
    } else {
      elem = (
        <div key={`a-${id || i}`} className={classNames} onClick={onClick}>
          <div>
            <Icon name={icon} loading={loading} className="TreeList-actionIcon" />
          </div>
        </div>
      );
    }

    if (tip) {
      elem = <Popup key={`a-${id}`} trigger={elem} content={tip} size="tiny" />;
    }

    return elem;
  });
};

const Item = ({ item = {}, depth, parentActive, path, currentPath, ItemFactory, isActive }) => {
  const { name, href, onClick, isMuted, isDivider, meta, actions, icon } = item;
  const token = item.token || {};

  let contentElem;
  if (ItemFactory) {
    contentElem = <ItemFactory index={0} item={item} />;
  } else {
    const metaElems = buildMetaElems(meta);
    const actionElems = buildActionElems(actions);
    const itemIcon = icon || token.icon;

    let tokenElem;

    if (token.name) {
      tokenElem = (
        <div className="TreeList-itemToken  flex items-center justify-center">
          <div
            style={{ color: stringToColor(_.toLower(token.name)) }}
            className={cn(
              'SortableTree-itemToken-inner',
              token.className || `token--${token.name}`,
              'flex items-center'
            )}
          >
            {token.name}
          </div>
        </div>
      );
    }

    contentElem = (
      <div className="flex" title={name}>
        {!_.isEmpty(itemIcon) ? (
          <div className="TreeList-itemIcon flex items-center leading-none">
            <Icon name={itemIcon} />
          </div>
        ) : null}

        <div className="SortableTree-itemName flex-1">{name}</div>

        {tokenElem}

        {!_.isEmpty(metaElems) ? (
          <div className="TreeList-meta  flex items-center">{metaElems}</div>
        ) : null}

        {!_.isEmpty(actionElems) ? (
          <div className="TreeList-actions  flex">{actionElems}</div>
        ) : null}
      </div>
    );
  }

  const itemHref = typeof href === 'string' ? href : _.get(href, 'pathname');

  const classNames = cn('TreeList-item hover:text-active', item.className, {
    'text-active': isActive,
    'is-active': isActive,
    'is-muted': isMuted,
    'is-divider': isDivider,
    'is-link': isExternalLink(itemHref),
  });

  if (itemHref) {
    return (
      <Link
        to={href}
        className={classNames}
        onClick={e => {
          // if we're clicking on an action, don't navigate
          const targetClass = _.get(e, 'target.className');
          if (_.includes(targetClass, 'TreeList-action')) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        }}
      >
        {contentElem}
      </Link>
    );
  }

  return (
    <div
      className={classNames}
      onClick={e => {
        if (onClick) onClick(e);
      }}
    >
      {contentElem}
    </div>
  );
};

const Folder = props => {
  const {
    folder,
    path = [],
    currentPath = [],
    depth,
    parent = {},
    parentActive,
    handleToggleFolder,
    ui,
    folderOpenIcon,
    folderClosedIcon,
    checkIsActive,
    checkIsChildActive,
    ...extra
  } = props;

  let childActive =
    folder.childActive ||
    (parentActive &&
      _.isEqual(path, currentPath.slice(0, path.length)) &&
      _.has(currentPath, depth + 1));

  if (!childActive && checkIsChildActive) {
    childActive = checkIsChildActive(folder);
  }

  let isActive = folder.isActive;
  if (!isActive && checkIsActive) {
    isActive = checkIsActive(folder);
  }

  let isOpen = isActive || childActive || folder.openByDefault;

  const toggledPath = currentPath.concat(path, 'toggled');
  if (!folder.noToggle && _.has(ui, toggledPath)) {
    isOpen = _.get(ui, toggledPath);
  }

  const metaElems = buildMetaElems(folder.meta);
  const actionElems = buildActionElems(folder.actions);

  const token = _.get(folder, 'token', {});

  const folderIcon = folder.icon || (isOpen ? folderOpenIcon : folderClosedIcon) || 'folder';
  const iconOnLeft = folderIcon === 'folder';

  const toggleElem = !folder.noToggle ? (
    <div
      className={cn('TreeList-folderToggler ', {
        'opacity-35': !isOpen,
        'text-right': !iconOnLeft,
      })}
      onClick={e => {
        e.preventDefault();
        handleToggleFolder(path, !isOpen);
        return false;
      }}
    >
      <Icon
        className={cn(folderIcon, {
          open: isOpen,
          'mr-3': iconOnLeft,
        })}
      />
    </div>
  ) : null;

  let folderInner = (
    <div className="flex items-center">
      {iconOnLeft && toggleElem}

      <div
        className={cn('TreeList-folderName flex-1 truncate font-bold', {
          'select-none': !folder.noToggle,
          'mb-3 lg:mb-2 text-grey-dark uppercase tracking-wide text-sm lg:text-xs': folder.noToggle,
        })}
      >
        {folder.name}
      </div>

      {_.get(token, 'name') ? (
        <div className="TreeList-itemToken" style={{ color: stringToColor(_.toLower(token.name)) }}>
          {token.name}
        </div>
      ) : null}

      {!_.isEmpty(metaElems) ? (
        <div className="TreeList-meta  flex items-center">{metaElems}</div>
      ) : null}
      {!_.isEmpty(actionElems) ? <div className="TreeList-actions  flex">{actionElems}</div> : null}

      {!iconOnLeft && toggleElem}
    </div>
  );

  const classNames = cn('TreeList-folderInner hover:text-active', folder.className, {
    'has-content': folder.hasContent,
    'has-children': !_.isEmpty(folder.children),
    'is-active text-active': isActive,
  });

  if (folder.hasOwnProperty('href')) {
    folderInner = (
      <Link
        className={classNames}
        to={folder.href}
        onClick={e => {
          const targetClass = _.get(e, 'target.className');

          if (_.includes(targetClass, 'action')) {
            handleToggleFolder(path, true);
            e.preventDefault();
          } else if (isActive) {
            handleToggleFolder(path, !isOpen);
          } else if (!_.includes(targetClass, 'icon')) {
            handleToggleFolder(path, true);
          }
        }}
      >
        {folderInner}
      </Link>
    );
  } else {
    folderInner = <div className={classNames}>{folderInner}</div>;
  }

  // if we can't toggle the parent, don't nest the children which makes it seem like we can
  let depthClass = depth;
  if (parent.noToggle) {
    depthClass = Math.max(depthClass - 1, 0);
  }

  return (
    <div
      className={cn('TreeList-folder', `depth--${depthClass}`, {
        'is-active': isActive,
        'is-muted': folder.isMuted,
        'is-divider': folder.isDivider,
        'child-active': childActive,
        'no-toggle': folder.noToggle,
      })}
    >
      <div
        onClick={e => {
          if (
            !_.includes(_.get(e, 'target.className'), 'TreeList-action') &&
            !folder.hasOwnProperty('href') &&
            !folder.noToggle &&
            !isActive
          ) {
            handleToggleFolder(path, !isOpen);
          }
        }}
      >
        {folderInner}
      </div>

      {isOpen ? (
        <Items
          items={folder.children}
          path={path}
          currentPath={currentPath}
          depth={depthClass + 1}
          handleToggleFolder={handleToggleFolder}
          parentActive={childActive}
          parent={folder}
          folderOpenIcon={folderOpenIcon}
          folderClosedIcon={folderClosedIcon}
          checkIsActive={checkIsActive}
          checkIsChildActive={checkIsChildActive}
          ui={ui}
          {...extra}
        />
      ) : null}
    </div>
  );
};

const Items = props => {
  const {
    items,
    depth = 0,
    parentActive,
    path,
    ui,
    handleToggleFolder,
    ItemFactory,
    currentPath,
    parent = {},
    folderOpenIcon,
    folderClosedIcon,
    checkIsActive,
    checkIsChildActive,
  } = props;

  let elem;
  if (!_.isEmpty(items)) {
    elem = _.map(items, (item, i) => {
      if (item.children) {
        return (
          <Folder
            key={i}
            folder={item}
            depth={depth}
            parent={parent}
            path={path.concat([i])}
            parentActive={parentActive}
            handleToggleFolder={handleToggleFolder}
            ui={ui}
            ItemFactory={ItemFactory}
            currentPath={currentPath}
            folderOpenIcon={folderOpenIcon}
            folderClosedIcon={folderClosedIcon}
            checkIsActive={checkIsActive}
            checkIsChildActive={checkIsChildActive}
          />
        );
      }

      let isActive = item.isActive;
      if (checkIsActive) {
        isActive = checkIsActive(item);
      }

      return (
        <Item
          key={i}
          depth={depth}
          path={path.concat(i)}
          parentActive={parentActive}
          item={item}
          isActive={isActive}
          ItemFactory={ItemFactory}
          currentPath={currentPath}
          folderOpenIcon={folderOpenIcon}
          folderClosedIcon={folderClosedIcon}
        />
      );
    });
  } else {
    elem = (
      <div key="none" className="TreeList-none">
        Nothing to show here.
      </div>
    );
  }

  // if we can't toggle the parent, don't nest the children which makes it seem like we can
  let depthClass = depth;
  if (parent.noToggle) {
    depthClass = Math.max(depthClass - 1, 0);
  }

  return (
    <div key="items" className={`TreeList-items depth--${depthClass}`}>
      {elem}
    </div>
  );
};

class SortableTreeList extends React.Component {
  handleToggleFolder = (p, toggled) => {
    const { updateUi, currentPath = [] } = this.props;

    updateUi('set', currentPath.concat(p, 'toggled'), toggled);
  };

  render() {
    const {
      data = {},
      ui = {},
      ItemFactory,
      actions = [],
      htmlId = 'tree-list',
      currentPath = [],
      viewer,
      inverted,
      folderOpenIcon,
      folderClosedIcon,
      checkIsActive,
      checkIsChildActive,
    } = this.props;

    return (
      <div
        id={htmlId}
        className={cn('TreeList', {
          'TreeList--viewer': viewer,
          'is-inverted': inverted,
        })}
      >
        {!_.isEmpty(actions) ? <div className="TreeList-topActions">{actions}</div> : null}

        <Items
          path={[]}
          currentPath={currentPath}
          items={data}
          handleToggleFolder={this.handleToggleFolder}
          ItemFactory={ItemFactory}
          ui={ui}
          folderOpenIcon={folderOpenIcon}
          folderClosedIcon={folderClosedIcon}
          checkIsActive={checkIsActive}
          checkIsChildActive={checkIsChildActive}
          parentActive
        />
      </div>
    );
  }
}

export default inject(({ appStore }, { id }) => ({
  ...appStore.injectUi(`sortable-tree-list-${id}`),
}))(observer(SortableTreeList));
