import React from 'react';
import cn from 'classnames';

const PageNotFound = props => {
  const { title, className } = props;

  return (
    <div className={cn('HubPageNotFound', className)}>
      <h1 className="HubPageNotFound-title">{title || 'Page Not Found'}</h1>
    </div>
  );
};

export default PageNotFound;
