import React from 'react';
import _ from 'lodash';
import { Icon, Menu } from 'semantic-ui-react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { getResponseSchemaFromOperation } from '@platform/utils/specs/swagger';

import JsonSchemaViewer from '../JsonSchemaViewer';

class ScenarioContractTest extends React.Component {
  render() {
    const {
      method = '',
      url,
      codes = [],
      endpoint,
      connectedSpecs,
      ui = {},
      updateUi = {},
    } = this.props;

    const activeCode = ui.activeCode || codes[0];
    const responseElems = [];
    let schema;

    if (activeCode && endpoint) {
      const connectedSpec = _.find(connectedSpecs, { id: endpoint.specId }) || {};

      if (connectedSpec) {
        schema = getResponseSchemaFromOperation({
          spec: toJS(connectedSpec),
          method,
          path: endpoint.path,
          code: activeCode,
        });

        const linkToSpec = (
          <b>{connectedSpec.local ? connectedSpec.filePath : connectedSpec.name}</b>
        );

        responseElems.push(
          <div key="response">
            <span className="mr-2 c-positive">
              <Icon name="check" />
            </span>
            Response codes found in {linkToSpec}
          </div>
        );
      }
    } else if (_.isEmpty(connectedSpecs)) {
      responseElems.push(
        <div key="endpoint">
          <span className="mr-2 c-muted">
            <Icon name="circle outline" />
          </span>
          <b>No OAS2 files connected.</b>
          <br />
          <br />
          Connect a file in the <b>Contract Test Settings</b> for this scenario collection,
          accessible via the Swagger/OAS link in the editor sidebar.
        </div>
      );
    } else {
      responseElems.push(
        <div key="endpoint">
          <span className="mr-2 c-negative">
            <Icon name="remove" />
          </span>
          <b>
            {_.toUpper(method)} {url}
          </b>{' '}
          did NOT match any operations in the connected OAS2 specifications.
        </div>
      );
    }

    if (_.isEmpty(codes)) {
      return <div>{responseElems}</div>;
    }

    return (
      <div>
        {responseElems}

        <Menu
          items={codes.map(c => ({
            key: c,
            name: c,
            active: c === activeCode,
            onClick() {
              updateUi('set', 'activeCode', c);
            },
          }))}
          secondary
        />

        <JsonSchemaViewer name="contract-test" schema={schema} />
      </div>
    );
  }
}

export default inject(({ appStore }, { scenarioId, stepId }) => ({
  ...appStore.injectUi(`scenario-step-contract-test-${scenarioId}-${stepId}`),
}))(observer(ScenarioContractTest));
