export const defaultColor = '#000000';
export const defaultBackgroundColor = '#FAFAFA';

export const defaultTheme = {
  styles: {
    base: {
      text: {
        color: defaultColor,
      },
      background: {
        backgroundColor: defaultBackgroundColor,
      },
    },
  },
};
