import React from 'react';
import _ from 'lodash';

import { shallowEqual } from '../json';

function pureCompare({ skipFunctions = true, debug } = {}) {
  return WrappedComponent => {
    return class extends React.Component {
      componentWillMount = () => {
        if (debug) {
          console.log('pureCompare.componentWillMount', this.props);
        }
      };

      shouldComponentUpdate = nextProps => {
        const same = shallowEqual(this.props, nextProps);

        if (debug) {
          console.log('pureCompare.shouldComponentUpdate?', !same, this.props, nextProps);
          const props = _.uniq(_.keys(this.props).concat(_.keys(nextProps)));
          for (const p of props) {
            if (this.props[p] === nextProps[p]) {
              console.log(p, 'isEqual');
            } else {
              console.log(p, 'isNotEqual', this.props[p], nextProps[p]);
            }
          }
          console.log('pureCompare.shouldComponentUpdate.end');
        }

        return !same;
      };

      render = () => {
        return <WrappedComponent {...this.props} />;
      };
    };
  };
}

export default pureCompare;
