import React from 'react';
import cn from 'classnames';
import { Radio } from 'semantic-ui-react';

import FormInputLabel from '../FormInputLabel';

const FormRadio = props => {
  const {
    checked,
    onChange,
    className = '',
    label,
    tip,
    required,
    labelProps,
    ...extraProps
  } = props;

  return (
    <div
      className={cn('FormRadio flex', className, {
        'FormRadio-labeled': label,
      })}
    >
      <Radio
        className={cn({ 'pr-2': label })}
        checked={checked}
        onChange={onChange}
        {...extraProps}
      />

      <FormInputLabel label={label} tip={tip} required={required} {...labelProps} />
    </div>
  );
};

export default FormRadio;
