import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { Icon } from 'semantic-ui-react';
import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';
import Accordion from '@platform/components/Accordion';
import shortid from '@platform/utils/shortid';

import BlockList from '../BlockList/viewer';

class AccordionBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { accordionId: shortid() };
  }

  renderHeader = () => {
    const { header = {} } = this.props;
    const { token, title, subtitle } = header;

    if (!_.isEmpty(token) || !_.isEmpty(title) || !_.isEmpty(subtitle)) {
      return (
        <div className="pl-2 pr-2 pb-3">
          {title ? <div className="HubBlock-header-name">{title}</div> : null}
          <div className="HubBlock-header-title flex items-center">
            {subtitle ? <div className="HubBlock-header-subtitle">{subtitle}</div> : null}
            {token ? <div className="HubBlock-header-token  ml-a">{token}</div> : null}
          </div>
        </div>
      );
    }
  };

  renderPanels = () => {
    const {
      basePath,
      blockPath,
      cache,
      childCacheIds,
      data = {},
      editor,
      relativePath,
      updateCache,
      slugger,
    } = this.props;

    const { children = [] } = data;

    if (!childCacheIds) {
      return null;
    }

    const panels = _.map(children, (child, index) => {
      return {
        key: index,
        defaultOpen: _.get(child, 'config.defaultOpen'),
        title: () => (
          <div className="flex items-center">
            <Icon name="dropdown" />
            <div>{child.title}</div>
          </div>
        ),
        content: _.isEmpty(child.blocks) ? (
          <div className="p-6">No existing blocks</div>
        ) : (
          <BlockList
            store={editor}
            blocks={child.blocks}
            parentPath={_.concat(blockPath, ['data', 'children', index])}
            basePath={basePath}
            relativePath={relativePath}
            cache={cache}
            updateCache={updateCache}
            blockProps={{ isNested: true }}
            slugger={slugger}
          />
        ),
      };
    });

    return panels;
  };

  render() {
    const { WrapperFactory } = this.props;
    const { accordionId } = this.state;

    const contentElem = [];
    contentElem.push(
      <Accordion
        key={`Accordion-Viewer-${accordionId}`}
        id={`Accordion-Viewer-${accordionId}`}
        fluid
        exclusive={false}
        panels={this.renderPanels()}
      />
    );

    return (
      <WrapperFactory {...this.props} headerElem={this.renderHeader()} contentElem={contentElem} />
    );
  }
}

AccordionBlock.displayName = 'HubAccordionBlock';

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'cacheKey',
  basePathProp: 'blockPath',
  path: ['data', 'children'],
  childrenProp: 'data.children',
})(observer(AccordionBlock));
