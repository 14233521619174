import React from 'react';

import ScrollWrapper from './index';

export default (Component, opts = {}) => {
  return props => (
    <ScrollWrapper
      {...opts}
      render={(scrollProps = {}) => <Component {...props} {...scrollProps} />}
    />
  );
};
