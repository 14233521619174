import React from 'react';
import cn from 'classnames';

import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';

import { Icon } from '@core/ui';

import { Link } from '@platform/utils/router';

const featuresToArticles = {
  releasing: '/platform/versioning/releases',
  versioning: '/platform/versioning/versions',
  embedding: '/documentation/embed-your-hub',
  auth0: '/documentation/authorizations/auth0',
  '/documentation/variables-hubs': '/documentation/variables-hubs',
  'custom-css': '/documentation/design/custom-css',
  '$.ctx': '/testing/using-variables/context',
  'contract-testing': '/testing/leveraging-openapi/contract-testing',
  'referencing-other-scenarios': '/testing/referencing-other-scenarios',
  'fair-use-billing': '/platform/getting-started/billing#fair-use-billing-policy',
  'run-a-simple-mock-server': '/mocking/introduction#run-a-simple-mock-server',
  'run-in-terminal': '/testing/running-tests/in-the-terminal',
  'sending-http-requests': '/modeling/modeling-with-openapi/sending-http-requests',
};

const LearnMore = props => {
  const { className, feature, children } = props;

  if (!feature || !featuresToArticles[feature]) {
    return null;
  }

  const to = 'https://docs.stoplight.io' + (featuresToArticles[feature] || '');

  return (
    <Link className={cn('truncate', className)} to={to}>
      {children ? (
        children
      ) : (
        <span>
          <Icon className="text-sm" icon={faBook} />
          <span className="ml-1">Learn More</span>
        </span>
      )}
    </Link>
  );
};

export default LearnMore;
