import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Popup, Icon } from 'semantic-ui-react';

import { authUrlOptions, accessUrlOptions } from '@platform/utils/authorization';
import { alert } from '@platform/utils/alert';

import ErrorMessage from '../ErrorMessage';
import Modal from '../Modal';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

class OAuth2TokenFormModal extends React.Component {
  render() {
    const { readOnly, tokenStore, className, oauth2 = {} } = this.props;

    const {
      credentials = {},
      authorizationMethod,
      authConfig = {},
      isValid,
      isCreatingToken,
      getToken,
      updateCredentials,
      updateIsCreatingToken,
      error,
      clearError,
    } = tokenStore;

    const { flow = 'accessCode' } = credentials;

    return (
      <Modal
        title="Get Access Token"
        size="tiny"
        triggerButton={{ secondary: true, content: 'Get Access Token', size: 'tiny' }}
        cancelButton={{
          negative: true,
          content: 'Cancel',
        }}
        onClose={() => {
          updateIsCreatingToken(false);
          clearError();
        }}
        confirmButton={{
          positive: true,
          disabled: isCreatingToken || !isValid,
          loading: isCreatingToken,
          content: 'Get Access Token',
          onClick: (e, modal) => {
            clearError();

            getToken({
              onSuccess: () => {
                modal.handleClose();
                alert.success('Access Token Created.');
              },
            });
          },
        }}
        contentFactory={() => (
          <div className={cn('text-sm', className)}>
            {error && (
              <ErrorMessage
                className="mb-6"
                error="Error getting access token, check the developer console for more details."
              />
            )}

            {!readOnly && (
              <div className="mb-2">
                <FormInput
                  value={_.get(credentials, 'name', '')}
                  placeholder="My OAuth token"
                  onChange={(e, { value }) => {
                    updateCredentials('set', 'name', value);
                  }}
                  label={{
                    content: 'Token Name',
                    basic: true,
                    className: 'Label-fw',
                  }}
                  fluid
                />
              </div>
            )}

            {!readOnly && (
              <div className="mb-2">
                <FormSelect
                  value={flow}
                  onChange={(e, { value }) => {
                    updateCredentials('set', 'flow', value);
                  }}
                  options={[
                    {
                      text: 'Authorization Code',
                      value: 'accessCode',
                    },
                    {
                      text: 'Implicit',
                      value: 'implicit',
                    },
                    {
                      text: 'Password Credentials',
                      value: 'password',
                    },
                    {
                      text: 'Client Credentials',
                      value: 'application',
                    },
                  ]}
                  label={{
                    content: 'Grant Type',
                    basic: true,
                    className: 'Label-fw',
                  }}
                  inline
                  fluid
                />
              </div>
            )}

            {flow === 'password' && [
              <div className="mb-2" key="username">
                <FormInput
                  value={_.get(credentials, 'username', '')}
                  placeholder="username"
                  onChange={(e, { value }) => {
                    updateCredentials('set', 'username', value);
                  }}
                  label={{
                    content: 'Username*',
                    basic: true,
                    className: 'Label-fw',
                  }}
                  fluid
                />
              </div>,

              <div className="mb-2" key="password">
                <FormInput
                  type="password"
                  value={_.get(credentials, 'password', '')}
                  placeholder="****************************************"
                  onChange={(e, { value }) => {
                    updateCredentials('set', 'password', value);
                  }}
                  label={{
                    content: 'Password*',
                    basic: true,
                    className: 'Label-fw',
                  }}
                  fluid
                />
              </div>,
            ]}

            <div className="mb-2">
              <FormInput
                value={_.get(credentials, 'client_id', '')}
                placeholder="12345123451234512345"
                onChange={(e, { value }) => {
                  updateCredentials('set', 'client_id', value);
                }}
                label={{
                  content: 'Client ID*',
                  basic: true,
                  className: 'Label-fw',
                }}
                fluid
              />
            </div>

            <div className="mb-2">
              <FormInput
                type="password"
                value={_.get(credentials, 'client_secret', '')}
                placeholder="****************************************"
                onChange={(e, { value }) => {
                  updateCredentials('set', 'client_secret', value);
                }}
                label={{
                  content: 'Client Secret*',
                  basic: true,
                  className: 'Label-fw',
                }}
                fluid
              />
            </div>

            {!readOnly && (
              <div className="mb-2">
                <FormInput
                  value={_.get(credentials, 'scope', '')}
                  placeholder="scope1, scope2"
                  onChange={(e, { value }) => {
                    updateCredentials('set', 'scope', value);
                  }}
                  label={{
                    basic: true,
                    className: 'Label-fw',
                    children: (
                      <div>
                        Scopes (optional)
                        <span className="FormInputTip">
                          <Popup
                            trigger={<Icon name="question" size="small" />}
                            content="Seperate scopes by a comma. Ex &quot;scope1, scope2&quot;"
                            size="small"
                            wide="very"
                          />
                        </span>
                      </div>
                    ),
                  }}
                  fluid
                />
              </div>
            )}

            {(flow === 'accessCode' || flow === 'authorizationCode' || flow === 'implicit') &&
              // on readOnly we want to hide the drop down options and still make the entry editable
              (readOnly ? (
                <div className="mb-2">
                  <FormInput
                    value={_.get(credentials, 'authorize_url', '')}
                    placeholder="https://provider.com/oauth/authorize"
                    onChange={(e, { value }) => {
                      updateCredentials('set', 'authorize_url', value);
                    }}
                    label={{
                      content: 'Authorize URL*',
                      basic: true,
                      className: 'Label-fw',
                    }}
                    fluid
                  />
                </div>
              ) : (
                <div className="mb-2">
                  <FormSelect
                    value={_.get(credentials, 'authorize_url', '')}
                    placeholder="https://provider.com/oauth/authorize"
                    onChange={(e, { value }) => {
                      updateCredentials('set', 'authorize_url', value);
                    }}
                    label={{
                      content: 'Authorize URL*',
                      basic: true,
                      className: 'Label-fw',
                    }}
                    options={_.uniqBy(
                      _.concat(authUrlOptions.map(u => ({ text: u, value: u })), [
                        { text: credentials.authorize_url, value: credentials.authorize_url },
                      ]),
                      'value'
                    )}
                    inline
                    fluid
                  />
                </div>
              ))}

            {flow !== 'implicit' &&
              // on readOnly we want to hide the drop down options and still make the entry editable
              (readOnly ? (
                <div className="mb-2">
                  <FormInput
                    value={_.get(credentials, 'access_token_url', '')}
                    placeholder="https://provider.com/oauth/access_token"
                    onChange={(e, { value }) => {
                      updateCredentials('set', 'access_token_url', value);
                    }}
                    label={{
                      content: 'Access Token URL*',
                      basic: true,
                      className: 'Label-fw',
                    }}
                    fluid
                  />
                </div>
              ) : (
                <div className="mb-2">
                  <FormSelect
                    value={_.get(credentials, 'access_token_url', '')}
                    placeholder="https://provider.com/oauth/access_token"
                    onChange={(e, { value }) => {
                      updateCredentials('set', 'access_token_url', value);
                    }}
                    label={{
                      content: 'Access Token URL*',
                      basic: true,
                      className: 'Label-fw',
                    }}
                    options={_.uniqBy(
                      _.concat(accessUrlOptions.map(u => ({ text: u, value: u })), [
                        { text: credentials.access_token_url, value: credentials.access_token_url },
                      ]),
                      'value'
                    )}
                    inline
                    fluid
                  />
                </div>
              ))}

            {(flow === 'accessCode' || flow === 'authorizationCode' || flow === 'implicit') &&
              !readOnly && (
                <div className="mb-4">
                  <FormInput
                    value={authConfig.redirectUri}
                    label={{
                      basic: true,
                      className: 'Label-fw',
                      children: (
                        <div>
                          Callback URL{' '}
                          <span className="FormInputTip">
                            <Popup
                              trigger={<Icon name="question" size="small" />}
                              content="Enter this in the application settings of your API provider."
                              size="small"
                              wide="very"
                            />
                          </span>
                        </div>
                      ),
                    }}
                    readOnly
                    fluid
                  />
                </div>
              )}

            <div className="mb-2">
              <FormSelect
                value={authorizationMethod}
                onChange={(e, { value }) => {
                  updateCredentials('set', 'authorization_method', value);
                }}
                label={{
                  content: 'Client Authentication',
                  basic: true,
                  className: 'Label-fw',
                }}
                options={[
                  {
                    text: 'Send as Basic Auth header',
                    value: 'header',
                  },
                  {
                    text: 'Send client credentials in body',
                    value: 'body',
                  },
                ]}
                inline
                fluid
              />
            </div>
          </div>
        )}
        onOpen={() => {
          if (!_.isEmpty(oauth2.credentials)) {
            updateCredentials('set', [], oauth2.credentials || {});
          }
        }}
      />
    );
  }
}

export default inject(stores => {
  const { oauthTokenStore, userService, projectService } = stores;

  const userId = _.get(userService, 'authorizedUser.id');
  const projectId = _.get(projectService, 'current.id');

  const tokenStore = oauthTokenStore.getOrRegisterInstance(userId, projectId);

  return {
    tokenStore,
  };
})(observer(OAuth2TokenFormModal));
