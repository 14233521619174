import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Button } from 'semantic-ui-react';

import { Link } from '@platform/utils/router';
import { isExternalLink } from '@platform/utils/url';

import HubSearch from '../HubSearch';

const BlockAction = ({ action, onChangeData, basePath, store, theme = {}, className }) => {
  const { type = 'none', name, content, route = {} } = action;

  let to = route.path;
  if (!isExternalLink(to)) {
    to = store.buildViewPath(to);
  }

  let actionElem = null;

  const actionBgStyle = _.get(theme, ['background'], {});
  const actionTextStyle = _.get(theme, ['text'], {});

  switch (type) {
    case 'button':
      actionElem = (
        <Link to={to}>
          <Button
            primary
            size="large"
            content={name || 'Button text'}
            style={{ ...actionBgStyle, ...actionTextStyle }}
          />
        </Link>
      );
      break;
    case 'html':
      actionElem = <div dangerouslySetInnerHTML={{ __html: content }} />;
      break;
    case 'search':
      actionElem = <HubSearch />;
      break;
    default:
      break;
  }

  return (
    <div className={cn(`HubBlockAction HubBlockAction--${type}`, className)}>{actionElem}</div>
  );
};

export default BlockAction;
