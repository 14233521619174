import React, { createElement } from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';

import { HTMLReader, sanitizeConfig } from '@core/lang-html';
import { Icon } from '@core/ui';

import Link from '@platform/utils/router/Link';
import { getConfigVar } from '@platform/utils/config';
import { isHubBuilder } from '@platform/format-hubs/utils';

// Only allow raw HTML in published Hubs
const allowRawHTML = isHubBuilder();

const htmlReader = new HTMLReader();

const inputFieldTypes = [
  'text',
  'email',
  'password',
  'number',
  'range',
  'date',
  'time',
  'datetime-local',
  'month',
  'color',
  'search',
  'tel',
  'url',
  'week',
];

const Anchor = ({ id }) => {
  const query = typeof window !== 'undefined' ? _.get(window, 'location.search') : '';

  return (
    <Link href={`${query}#${id}`} aria-hidden="true" className="anchor" name={id} id={id}>
      <Icon icon={faLink} />
    </Link>
  );
};

const anchoredComponents = {
  h1: ({ id, children, ...props }) => {
    return (
      <h1 {...props}>
        <Anchor id={id} />
        {children}
      </h1>
    );
  },

  h2: ({ id, children, ...props }) => {
    return (
      <h2 {...props}>
        <Anchor id={id} />
        {children}
      </h2>
    );
  },

  h3: ({ id, children, ...props }) => {
    return (
      <h3 {...props}>
        <Anchor id={id} />
        {children}
      </h3>
    );
  },
};

class Script extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { children, ...scriptProps } = this.props;

    let script = document.createElement('script');
    script = Object.assign(script, scriptProps || {});
    script.type = 'text/javascript';

    if (children) {
      script.innerHTML = children;
    }

    this.instance.appendChild(script);
  }

  render() {
    return <div style={{ display: 'none' }} ref={el => (this.instance = el)} />;
  }
}

const HtmlViewer = ({ value, className, context = {}, options = {} } = {}) => {
  if (_.isNil(value)) return null;

  const classNames = cn('HtmlViewer', className);

  if (typeof window === 'undefined') {
    return <div className={classNames} dangerouslySetInnerHTML={{ __html: value }} />;
  }

  const config = {
    sanitize: !allowRawHTML,
    react: {
      createElement,
      components: {
        a: ({ children, ...props }) => {
          const computed = Object.assign({}, props, context.a ? context.a(props) : {});

          return <Link {...computed}>{children}</Link>;
        },
        // We need this since rehype-parse doesn't support "viewBox"
        svg: ({ children, ...props }) => {
          return <svg {...props}>{children}</svg>;
        },
        // We need this since rehype-parse doesn't support "fill-rule"
        path: ({ children, ...props }) => {
          return <path {...props}>{children}</path>;
        },

        script: ({ children, ...props }) => {
          return <Script {...props}>{children}</Script>;
        },
        // Changing value attribute to defaultValue for input fields to make element controlled
        input: ({ children, ...props }) => {
          const { type, value, checked } = props;
          if (inputFieldTypes.includes(type)) {
            props.defaultValue = value;
            delete props.value;
          }
          if (['checkbox', 'radio'].includes(type)) {
            props.defaultChecked = checked;
            delete props.value;
          }
          return <input {...props} />;
        },
        textarea: ({ children, ...props }) => {
          const { value } = props;
          props.defaultValue = value;
          delete props.value;
          return <textarea {...props} />;
        },
        select: ({ children, ...props }) => {
          const { value } = props;
          props.defaultValue = value;
          delete props.value;
          return <select {...props}>{children}</select>;
        },
      },
    },
  };

  if (options.anchors) {
    config.react.components = {
      ...config.react.components,
      ...anchoredComponents,
    };
  }

  return htmlReader.toReact(`<div class="${classNames}">${value}</div>`, config);
};

export default HtmlViewer;
