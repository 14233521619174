import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Message, Icon, Loader } from 'semantic-ui-react';

import JsonPathCacheContainer from '@platform/components/JsonPathCacheContainer';
import ErrorMessage from '@platform/components/ErrorMessage';

import { registerLogger } from '@platform/utils/logging';
import { stringToColor } from '@platform/utils/colors';
import { replaceVariables } from '@platform/utils/variables';

import barList from '../BlockBarList/viewer';
import callout from '../BlockCallout/viewer';
import cardList from '../BlockCardList/viewer';
import code from '../BlockCode/viewer';
import hero from '../BlockHero/viewer';
import html from '../BlockHtml/viewer';
import image from '../BlockImage/viewer';
import jsonSchema from '../BlockJsonSchema/viewer';
import ref from '../BlockRef/viewer';
import tabs from '../BlockTabs/viewer';
import container from '../BlockContainer/viewer';
import text from '../BlockText/viewer';
import http from '../BlockHttp/viewer';
import accordion from '../BlockAccordion/viewer';
import { buildBlockClassNames } from '../../utils';
import { blockConfig } from '../../utils/viewer';

const log = registerLogger('components', 'HubBlock');

const BlockMap = {
  barList,
  callout,
  cardList,
  code,
  hero,
  html,
  image,
  jsonSchema,
  ref,
  tabs,
  container,
  text,
  http,
  accordion,
};

export const HubBlockHeader = ({ className, header = {} }) => {
  const { token, title, subtitle } = header;

  const hasToken = !_.isEmpty(token);
  const hasTitle = !_.isEmpty(title);
  const hasSubtitle = !_.isEmpty(subtitle);

  if (!hasToken && !hasTitle && !hasSubtitle) return null;

  let titleElem;
  if (hasTitle || hasToken) {
    titleElem = (
      <div className="HubBlock-header-title flex items-center">
        {hasTitle && <div className="HubBlock-header-name">{title}</div>}
        {hasToken && (
          <div className="HubBlock-header-token ml-3" style={{ color: stringToColor(token) }}>
            {token}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={cn('HubBlock-header', className)}>
      {titleElem}
      {hasSubtitle && <div className="HubBlock-header-subtitle">{subtitle}</div>}
    </div>
  );
};

const HubBlockContent = props => {
  const {
    className,
    headerElem,
    contentClassName,
    contentProps = {},
    contentElem,
    block = {},
    slugger,
  } = props;

  let header;
  // allow for blocks to override the header, can pass null to completely hide header
  if (headerElem) {
    header = headerElem;
  } else if (!_.isEmpty(block.header) && _.isUndefined(headerElem)) {
    header = <HubBlockHeader header={block.header} />;
  }

  let anchorElem = null;
  const anchor = _.toLower(_.get(block, 'header.anchor'));

  if (anchor) {
    const slug = slugger.slug(anchor);

    anchorElem = (
      <a href={`#${slug}`} aria-hidden="true" className="block-anchor" name={slug} id={slug}>
        <Icon name="linkify" />
      </a>
    );
  }

  return (
    <div className={cn('HubBlock-inner flex-1 w-full', className)}>
      {anchorElem}
      {header}

      {contentElem && (
        <div
          className={cn('HubBlock-content', contentClassName, { 'has-anchor': !!anchorElem })}
          {...contentProps}
        >
          {contentElem}
        </div>
      )}
    </div>
  );
};

const HubBlockViewer = props => {
  const {
    id,
    blockIndex,
    blockPath,
    block = {},
    blockDereferenced = {},
    className,
    isNested,
    isSolo,
    isLast,
    basePath,
    relativePath,
    slugger,
    editor,
  } = props;

  log.debug('Render', {
    id,
    blockIndex,
    blockPath,
    basePath,
    relativePath,
    block,
    blockDereferenced,
  });

  let contentElem;

  const $ref = _.get(block, 'data.$ref');
  const error = _.get(block, 'data.__error');
  const RenderedBlock = BlockMap[block.type];

  // TODO ADD page/subpage config so there can be a trickling effect for settings
  let config = _.cloneDeep(_.get(editor.config, block.type, {}));

  const elemConfig = _.get(block, 'config', {});

  _.merge(config, elemConfig);

  let isPadded;

  if (error) {
    contentElem = (
      <div className="HubBlock-inner flex-1 w-full">
        <ErrorMessage error={error} />
      </div>
    );
    isPadded = true;
  } else if ($ref) {
    contentElem = (
      <div className="HubBlock-inner flex-1 w-full relative h-32">
        <div className="absolute pin bg-lighten-500 z-20 opacity-1">
          <Loader active />
        </div>
      </div>
    );
    isPadded = true;
  } else if (RenderedBlock) {
    contentElem = (
      <RenderedBlock
        {...props}
        basePath={basePath}
        relativePath={relativePath}
        block={block}
        blockPath={blockPath}
        header={block.header}
        config={config}
        theme={block.theme}
        data={blockDereferenced.data || block.data}
        WrapperFactory={HubBlockContent}
        dereferencedBlock={blockDereferenced}
        slugger={slugger}
      />
    );
  } else {
    contentElem = (
      <div className="HubBlock-inner flex-1 w-full">
        {block.type} viewer has not been implemented.
      </div>
    );
    isPadded = true;
  }

  const classNames = buildBlockClassNames({
    block,
    config: blockConfig[block.type],
    classNames: [`HubBlock HubBlock--${block.type} flex is-viewing`, className],
    isNested,
    isSolo,
    isLast,
  });

  return <div className={cn(classNames, { 'is-padded': isPadded })}>{contentElem}</div>;
};

export default JsonPathCacheContainer({
  cacheKeyProp: 'editor.id',
  pathProp: 'blockPath',
})(HubBlockViewer);
