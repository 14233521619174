import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { registerLogger } from '@platform/utils/logging';

import SimpleMdEditor from '../SimpleMdEditor';

const log = registerLogger('components', 'MarkdownEditor');

log.info('Load');

class MarkdownEditor extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    readOnly: PropTypes.bool,
    spellCheck: PropTypes.bool,
    toolbarHidden: PropTypes.bool,
    toolbarOnFocus: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
  };

  onChange = value => {
    this.props.onChange(value);
  };

  render() {
    const {
      id,
      className,
      borderless,
      size,
      value = '',
      preview = false,
      onChange,
      ...editorProps
    } = this.props;

    log.debug('Render', { value });

    return (
      <div
        className={cn('MarkdownEditor', className, {
          [`is-${size}`]: size,
          'is-borderless': borderless,
        })}
      >
        <SimpleMdEditor
          id={id}
          placeholder="Write markdown here..."
          value={value}
          onChange={({ value: newValue }) => {
            if (value !== newValue) {
              onChange(newValue);
            }
          }}
          preview={preview}
          {...editorProps}
        />
      </div>
    );
  }
}

export default MarkdownEditor;
