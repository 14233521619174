import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Popup } from 'semantic-ui-react';

import shortid from '@platform/utils/shortid';
import { fileFormat } from '@platform/utils/url';
import { isExternalLink } from '@platform/utils/url';

import { URI } from '@core/uri';

import FormDropdown from '../FormDropdown';
import FormInput from '../FormInput';
import FormSearch from '../FormSearch';

class RefBuilder extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    $ref: PropTypes.string,
    localData: PropTypes.object,
    context: PropTypes.bool,
    fileFilter: PropTypes.object,
    routeDataTargets: PropTypes.object,
    store: PropTypes.object,
    initialSource: PropTypes.oneOf(['local', 'external', 'currentProject']),
    targets: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  componentWillMount() {
    this.init(this.props);
  }

  componentWillUpdate(nextProps) {
    const { $ref, store, onComplete } = nextProps;
    const { builtRef, dirty } = store;

    if (this.props.id !== nextProps.id) {
      this.init(nextProps);
    } else if (!_.isEqual($ref, builtRef)) {
      if (dirty) {
        this.onChange({ builtRef, onComplete });
      }
    }
  }

  init = props => {
    const {
      id,
      $ref,
      localData,
      fileFilter,
      routeDataTargets,
      store,
      initialSource,
      targets,
      projectStore,
      context,
      force,
    } = props;

    const currentProjectId = _.get(projectStore, 'current.id');
    const currentFilePath = _.get(projectStore, 'current.currentFilePathname', '');
    const currentProjectPath = _.dropRight(_.split(currentFilePath, '/'));

    store.initial({
      id,
      $ref,
      fileFilter,
      routeDataTargets,
      initialSource,
      targets,
      localData,
      currentProjectId,
      currentProjectPath,
      currentFilePath,
      context,
      force,
    });
  };

  onChange = ({ builtRef, confirmed, onComplete, force }) => {
    const { confirm, store } = this.props;

    if (!force) {
      if (!onComplete || (confirm && !confirmed) || !store.isComplete) return;
    }

    store.pristine();
    onComplete(builtRef);
  };

  renderConfirm = () => {
    const { store, $ref, confirm, onComplete, onRemove } = this.props;
    const {
      isUrlLoading,
      isUrlValid,
      isComplete,
      builtRef,
      urlError,
      initializing,
      selectedSource,
    } = store;

    let color = 'grey';
    let content = '';
    let popup = '';
    let icon;

    if (isUrlLoading || initializing) {
      color = 'grey';
      content = 'Loading';
      icon = 'spinner';
    } else if (isComplete) {
      color = 'green';
      content = 'Confirm';
      if (!confirm) {
        icon = 'check';
        popup = '';
      }
    } else {
      color = 'red';

      if (confirm) {
        content = 'Incomplete';
      } else {
        icon = 'warning';
      }

      popup = 'Ref selection not complete.';
      if (urlError) {
        popup = urlError;
        content = 'URL Error';
      } else if (!isUrlValid && selectedSource === 'external') {
        popup = 'URL is invalid';
        content = 'Invalid Url';
      }
    }

    const buttonProps = {
      className: cn('RefBuilder-button', {
        'is-confirm': confirm,
        'is-simple': icon === 'check' || icon === 'warning' || content === 'Incomplete',
        'is-negative': icon === 'warning' || content === 'Incomplete',
        'is-positive': icon === 'check',
      }),
      icon,
      color,
      loading: isUrlLoading,
    };

    if (confirm) {
      buttonProps.content = content;
      buttonProps.tabIndex = 2;
      buttonProps.disabled = !isComplete || builtRef === $ref;
      buttonProps.onClick = () => this.onChange({ builtRef, confirmed: true, onComplete });
    }

    const buttons = [];

    const b = <Button key="cb" {...buttonProps} />;
    if (!_.isEmpty(popup)) {
      buttons.push(
        <Popup key="cp" trigger={b} on="hover" position="top right">
          {popup}
        </Popup>
      );
    } else {
      buttons.push(b);
    }

    buttons.push(this.renderReferenceLink());

    if (onRemove) {
      buttons.push(
        <Button key="delete" className="RefBuilder-button" icon="trash" onClick={onRemove} />
      );
    }

    return buttons;
  };

  renderCurrentProject = () => {
    const { store, size } = this.props;
    const { targets } = store;

    const searchParts = [];
    if (size === 'small') {
      searchParts.push(this.renderConfirm());
      searchParts.push(this.renderTargetSearch());
    } else {
      searchParts.push(this.renderTargetSearch());
      searchParts.push(this.renderConfirm());
    }

    return (
      <div
        className={cn('RedBuilder-groups flex flex-wrap', {
          'flex-col': size === 'small',
        })}
      >
        <div className="RedBuilder-group flex-1 flex">
          {this.renderSourceDropdown()}
          {this.renderFileSearch()}
        </div>

        <div className={cn('RedBuilder-group flex', { 'flex-1': targets })}>{searchParts}</div>
      </div>
    );
  };

  renderExternal = () => {
    const { store, size } = this.props;
    const { externalUrl, handleChange, fetchExternalUrl, targets } = store;

    const searchParts = [];
    if (size === 'small') {
      searchParts.push(this.renderConfirm());
      searchParts.push(this.renderTargetSearch());
    } else {
      searchParts.push(this.renderTargetSearch());
      searchParts.push(this.renderConfirm());
    }

    return (
      <div
        className={cn('RedBuilder-groups flex flex-wrap', {
          'flex-col': size === 'small',
        })}
      >
        <div className="RedBuilder-group flex-1 flex">
          {this.renderSourceDropdown()}

          <FormInput
            className="RefBuilder-search flex-1"
            placeholder="https://next.stoplight.io/"
            onChange={(e, { value }) => {
              handleChange('externalUrl', value);
              fetchExternalUrl();
            }}
            value={externalUrl || ''}
            labelPosition="left"
            input={{ tabIndex: 1 }}
            fluid
          />
        </div>
        <div
          className={cn('RedBuilder-group flex', {
            'flex-1': targets,
          })}
        >
          {searchParts}
        </div>
      </div>
    );
  };

  renderFileSearch = () => {
    const { store } = this.props;
    const { file, fileQuery, fileResults, loadFiles, handleChange, setQuery } = store;

    return (
      <FormSearch
        className="RefBuilder-search flex-1"
        placeholder={_.get(file, 'title') || 'choose a file'}
        value={fileQuery || ''}
        results={fileResults}
        onResultSelect={(e, data) => {
          handleChange('file', data);
        }}
        onSearchChange={(e, value) => {
          setQuery('file', value);
        }}
        onBlur={() => {
          setTimeout(() => {
            if (_.isEmpty(store.fileQuery)) {
              setQuery('file', _.get(file, 'title'));
            }
          }, 200);
        }}
        inputProps={{
          onFocus: () => {
            setQuery('file', '');
            loadFiles();
          },
          tabIndex: 1,
        }}
        minCharacters={0}
        category
        showNoResults
      />
    );
  };

  renderLocal = () => {
    return (
      <div className="flex flex-wrap">
        {this.renderSourceDropdown()}
        {this.renderTargetSearch({ force: true })}
        {this.renderConfirm()}
      </div>
    );
  };

  renderReferenceLink = () => {
    const { store, routerStore } = this.props;
    const { isComplete, initializing, referenceLink } = store;

    const disabled = initializing || !isComplete;

    const buttonProps = {
      className: 'RefBuilder-button reference-link',
      icon: 'book',
      tabIndex: 2,
      onClick: () => {
        if (isExternalLink(referenceLink)) {
          window.open(referenceLink, '_blank');
        } else {
          const uri = URI.parse(referenceLink);
          routerStore.push({
            pathname: uri.path,
            search: uri.query,
          });
        }
      },
      disabled,
    };

    const b = <Button key="rb" {...buttonProps} />;
    return (
      <Popup key="rp" trigger={b} on="hover" position="top right">
        Go to reference.
      </Popup>
    );
  };

  renderSource = () => {
    const { store } = this.props;
    const { source } = store;

    let component;

    switch (source) {
      case 'local':
      case 'shared':
        component = this.renderLocal();
        break;
      case 'currentProject':
        component = this.renderCurrentProject();
        break;
      case 'external':
        component = this.renderExternal();
        break;
      default:
    }

    return component;
  };

  renderSourceDropdown = () => {
    const { store } = this.props;
    const { source, sourceOptions, handleChange } = store;

    return (
      <FormDropdown
        key="source-dropdown"
        className="RefBuilder-dropdown"
        value={source}
        options={sourceOptions}
        onChange={(e, { value }) => {
          if (value !== source) {
            handleChange('source', value);
          }
        }}
        fluid
        selection
      />
    );
  };

  renderTargetSearch = ({ force } = {}) => {
    const { store, targets } = this.props;
    if (!targets && !force) return;

    const {
      file,
      externalUrl,
      fileQuery,
      handleChange,
      isTargetLoading,
      isUrlValid,
      loadSpecData,
      setQuery,
      source,
      target,
      targetResults,
      targetQuery,
    } = store;

    const disabled =
      (source === 'currentProject' && !fileQuery) ||
      (source === 'external' && (!externalUrl || !isUrlValid));

    if (fileFormat({ file: _.get(file, 'title') }).language === 'md') return null;

    return (
      <FormSearch
        key="target-search"
        className="RefBuilder-search flex-1"
        placeholder={_.get(target, 'title') || 'choose a target'}
        value={targetQuery || ''}
        results={targetResults}
        onResultSelect={(e, data) => {
          handleChange('target', data);
        }}
        onSearchChange={(e, value) => {
          setQuery('target', value);
        }}
        onBlur={() => {
          setTimeout(() => {
            if (_.isEmpty(store.targetQuery)) {
              setQuery('target', _.get(target, 'title'));
            }
          }, 200);
        }}
        inputProps={{
          onFocus: () => {
            setQuery('target', '');
            loadSpecData();
          },
          tabIndex: 1,
        }}
        noResultsMessage={isTargetLoading ? 'Loading Targets ...' : 'No results found.'}
        disabled={disabled}
        minCharacters={0}
        showNoResults
        category
        fluid
      />
    );
  };

  render() {
    const { store, className, size = 'large' } = this.props;
    const { source, errorMessage } = store;

    let error;
    if (errorMessage) {
      error = <p className="RefBuilder-error pt-3">{errorMessage}</p>;
    }

    return (
      <div className={cn('RefBuilder', `is-${size}`, `is-${source}`, className)}>
        {this.renderSource()}
        {error}
      </div>
    );
  }
}

const InjectedRefBuilder = inject(({ projectStore, routerStore, refBuilderStore }, { id }) => {
  let store = refBuilderStore.register(id);

  return {
    projectStore,
    routerStore,
    store,
  };
})(observer(RefBuilder));

class RefBuilderWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: this.props.id || shortid() };
  }

  render() {
    const { id } = this.state;

    return <InjectedRefBuilder {...this.props} id={this.props.id || id} />;
  }
}

export default RefBuilderWrapper;
