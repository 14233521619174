// adapted from the official javascript-hint addon

/* eslint-disable */

import CodeMirror from 'codemirror';

let Pos = CodeMirror.Pos;

function arrayContains(arr, item) {
  if (!Array.prototype.indexOf) {
    let i = arr.length;
    while (i--) {
      if (arr[i] === item) {
        return true;
      }
    }
    return false;
  }
  return arr.indexOf(item) != -1;
}

function scriptHint(editor, keywords, getToken, options) {
  // Find the token at the cursor
  let cur = editor.getCursor();
  let token = getToken(editor, cur);
  if (/\b(?:string|comment)\b/.test(token.type)) return;
  token.state = CodeMirror.innerMode(editor.getMode(), token.state).state;

  // If it's not a 'word-style' token, ignore the token.
  if (!/^[\w$_]*$/.test(token.string)) {
    token = {
      start: cur.ch,
      end: cur.ch,
      string: '',
      state: token.state,
      type: token.string == '.' ? 'property' : null,
    };
  } else if (token.end > cur.ch) {
    token.end = cur.ch;
    token.string = token.string.slice(0, cur.ch - token.start);
  }

  let tprop = token;
  // If it is a property, find out what it is a property of.
  while (tprop.type == 'property') {
    tprop = getToken(editor, Pos(cur.line, tprop.start));
    if (tprop.string != '.') return;
    tprop = getToken(editor, Pos(cur.line, tprop.start));
    if (!context) var context = [];
    context.push(tprop);
  }
  return {
    list: getCompletions(token, context, keywords, options),
    from: Pos(cur.line, token.start),
    to: Pos(cur.line, token.end),
  };
}

function javascriptHint(editor, options) {
  return scriptHint(
    editor,
    [],
    function(e, cur) {
      return e.getTokenAt(cur);
    },
    options
  );
}
CodeMirror.registerHelper('hint', 'javascript', javascriptHint);

function forAllProps(obj, callback) {
  for (const name in obj) {
    if (name === 'prototype') {
      continue;
    }

    let displayText;
    if (obj[name] && obj[name].prototype && obj[name].prototype.displayText) {
      displayText = obj[name].prototype.displayText;
    }
    callback(name, displayText);
  }
}

function getCompletions(token, context, keywords, options) {
  let found = [],
    start = token.string,
    global = (options && options.globalScope) || window;
  function maybeAdd(str, displayText) {
    if (str.lastIndexOf(start, 0) == 0 && !arrayContains(found, str))
      found.push({ text: str, displayText });
  }
  function gatherCompletions(obj) {
    forAllProps(obj, maybeAdd);
  }

  if (context && context.length) {
    // If this is a property, see if it belongs to some object we can
    // find in the current environment.
    let obj = context.pop(),
      base;
    if (obj.type && obj.type.indexOf('variable') === 0) {
      if (options && options.additionalContext) base = options.additionalContext[obj.string];
      if (!options || options.useGlobalScope !== false) base = base || global[obj.string];
    } else if (obj.type == 'string') {
      base = '';
    } else if (obj.type == 'atom') {
      base = 1;
    } else if (obj.type == 'function') {
      if (
        global.jQuery != null &&
        (obj.string == '$' || obj.string == 'jQuery') &&
        typeof global.jQuery == 'function'
      )
        base = global.jQuery();
      else if (global._ != null && obj.string == '_' && typeof global._ == 'function')
        base = global._();
    }
    while (base != null && context.length) base = base[context.pop().string];
    if (base != null) gatherCompletions(base);
  } else {
    if (!options || options.useGlobalScope !== false) gatherCompletions(global);
  }
  return found;
}
