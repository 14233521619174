import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Menu, Segment, Icon } from 'semantic-ui-react';

import { createStepFromOperation } from '@platform/utils/specs/swagger';
import { createURL, removeQueryString, getQueryParams, cleanSlashes } from '@platform/utils/url';

import FormInput from '@platform/components/FormInput';

import Tabs from './tabs';
import Path from './path';
import Method from './method';
import Host from './host';

class HttpEditor extends React.Component {
  static defaultProps = {
    tabWhitelist: [
      'settings',
      'headers',
      'query',
      'auth',
      'body',
      'tests',
      'captures',
      'scripts',
      'code-generation',
    ],
  };

  componentWillMount() {
    const { step, handleUpdate } = this.props;

    const { currentUrl } = this.currentTarget();
    const urlQuery = getQueryParams(currentUrl);

    if (!_.isEmpty(urlQuery)) {
      const newTarget = removeQueryString(currentUrl).url;
      handleUpdate('set', ['input', 'url'], newTarget);
      handleUpdate('set', ['input', 'query'], {
        ...urlQuery,
        ..._.get(step, 'input.query', {}),
      });
    }
  }

  handleChangeTab = name => {
    const { ui, updateUi, tabWhitelist } = this.props;

    let tab = name;
    if (!name || !_.includes(tabWhitelist, name)) {
      tab = _.first(tabWhitelist);
    }

    updateUi('set', 'currentTab', tab);
  };

  currentTarget = () => {
    const { hosts, step } = this.props;
    const target = {};

    const targetUrl = _.get(step, 'input.url', '');

    if (!_.isEmpty(hosts)) {
      const processed = createURL(targetUrl);

      if (!_.isEmpty(processed)) {
        target.currentHost = `${processed.protocol}//${processed.host}`;
        target.currentPath = `${processed.pathname}${processed.search}`;
      }
    }

    target.currentUrl = target.currentHost ? target.currentPath : targetUrl;

    return target;
  };

  renderHeaderElems = () => {
    const { headerElems = [] } = this.props;

    const headers = headerElems.map((elem, i) => (
      <div key={elem.key || i} className="flex mr-3">
        {elem.render()}
      </div>
    ));

    return headers;
  };

  handleAddQuery = () => {
    const { handleUpdate, ui = {}, updateUi, step } = this.props;

    this.handleChangeTab('query');

    const stepQuery = _.get(step, 'input.query') || {};
    const newQueryIndex = _.keys(stepQuery).length;

    handleUpdate('set', ['input', 'query'], {
      ...stepQuery,
      '': '',
    });

    // setTimeout needed to allow the formInputMap element to render before calling the focus
    setTimeout(() => {
      const elem = document.getElementById(`FormInputMapKey-${newQueryIndex}`);

      if (elem) {
        elem.focus();
      }
    }, 200);
  };

  handleOperationSelect = operation => {
    const {
      connectedSpecs,
      editor,
      modeling,
      readOnly,
      handleUpdate,
      updateHostOnSearch = true,
      standalone,
      step = {},
    } = this.props;

    if (readOnly) {
      handleUpdate('set', ['input.url'], operation.path);
      return;
    }

    let spec = _.find(connectedSpecs, { id: operation.specId });

    if (!spec) {
      spec = _.first(connectedSpecs);
    }

    if (!spec) return;

    const { input, name } =
      createStepFromOperation({
        spec,
        updateHostOnSearch,
        basePath: _.get(spec, 'data.basePath'),
        method: operation.method,
        path: operation.path,
        pathParamsToCtx: !standalone,
      }) || {};

    if (!_.isEmpty(input)) {
      handleUpdate('set', ['input'], input);
    }

    if (name && _.isEmpty(step.name)) {
      handleUpdate('set', ['name'], name);
    }

    editor.updateCurrentStepPathParams(input.params);

    if (modeling) {
      editor.resetStepResults();
    }

    this.handleChangeTab();
  };

  render() {
    const {
      id,
      hosts,
      editorId,
      collectionId,
      scenarioId,
      step,
      stepIndex,
      modeling,
      handleUpdate,
      ui,
      updateUi,
      connectedSpecs,
      autocompleteScopes,
      config,
      readOnly,
      tabWhitelist,
      hideMethod,
      standalone,
      endpoints,
    } = this.props;

    const { currentHost, currentPath, currentUrl } = this.currentTarget();
    const isEmptyHosts = _.isEmpty(hosts);
    const isEditingPath = isEmptyHosts || modeling;

    return (
      <div id="tutorial-http-input" className="ScenarioStepInput type-http">
        <div id="tutorial-http-url" className="ScenarioStepInput-methodUrl flex">
          {this.renderHeaderElems()}

          <div className="flex flex-1">
            {!hideMethod && (
              <Method
                label={standalone ? null : 'method'}
                value={_.get(step, 'input.method')}
                readOnly={readOnly}
                onChange={value => handleUpdate('set', ['input', 'method'], value)}
              />
            )}

            {isEmptyHosts || modeling ? null : (
              <Host
                label={standalone ? null : 'host'}
                value={currentHost}
                hosts={hosts}
                readOnly={readOnly}
                onChange={value => {
                  handleUpdate('set', ['input', 'url'], cleanSlashes(`${value}/${currentPath}`));
                }}
              />
            )}

            <Path
              value={isEditingPath ? _.get(step, 'input.url') : currentUrl}
              label={standalone ? null : isEditingPath ? 'path' : 'url'}
              placeholder={isEditingPath ? '/path' : 'http://api.myco.com/path'}
              endpoints={endpoints}
              onChange={value => {
                // change tab on typing ?
                if (_.last(value) === '?') {
                  this.handleAddQuery();
                  return;
                }

                handleUpdate(
                  'set',
                  ['input', 'url'],
                  isEditingPath
                    ? value
                    : cleanSlashes(`${currentHost}/${value}`, { trimEnd: false })
                );
              }}
              handleResultSelect={operation => this.handleOperationSelect(operation)}
              readOnly={readOnly}
            />
          </div>
        </div>

        <div className="mt-4">
          <Tabs
            id={id}
            editorId={editorId}
            collectionId={collectionId}
            scenarioId={scenarioId}
            standalone={standalone}
            step={step}
            stepIndex={stepIndex}
            modeling={modeling}
            handleUpdate={handleUpdate}
            ui={ui}
            updateUi={updateUi}
            connectedSpecs={connectedSpecs}
            autocompleteScopes={autocompleteScopes}
            config={config}
            readOnly={readOnly}
            whitelist={tabWhitelist}
            currentTab={ui.currentTab || _.first(tabWhitelist)}
            handleChangeTab={name => this.handleChangeTab(name)}
          />
        </div>
      </div>
    );
  }
}

export default inject((stores, props) => {
  const { appStore, collectionEditorStore } = stores;
  const { editorId, connectedSpecs, endpoints } = props;

  const editor = collectionEditorStore.getEditor({ id: editorId });

  return {
    ...appStore.injectUi(`http-step-${editorId}`),
    editor,
    endpoints: endpoints || editor.endpoints,
    connectedSpecs: connectedSpecs || editor.connectedSpecs,
  };
})(observer(HttpEditor));
