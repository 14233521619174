import React from 'react';
import _ from 'lodash';
import { Renderer } from '@platform/utils/markdown';

import BlockAction from '../BlockAction/viewer';

const BarItem = props => {
  const { child = {}, basePath, editor, theme = {}, childIndex } = props;
  const { title, content, actions = [], relativePath } = child;

  const childTextStyle = _.get(theme, ['styles', 'children', childIndex, 'text'], {});

  return (
    <div className="BarListBlock-child flex justify-center">
      <div className="BarListBlock-content flex items-center">
        <div className="BarListBlock-main w-1/3 pr-10">
          <div className="BarListBlock-title" style={childTextStyle}>
            {title}
          </div>
          <div className="BarListBlock-actions">
            {_.map(actions, (action, actionIndex) => {
              const actionTheme = _.get(theme, [
                'styles',
                'children',
                childIndex,
                'actions',
                actionIndex,
              ]);
              return (
                <BlockAction
                  className="pt-4"
                  key={actionIndex}
                  action={action}
                  basePath={basePath}
                  store={editor}
                  theme={actionTheme}
                />
              );
            })}
          </div>
        </div>

        {content ? (
          <div
            className="BarListBlock-body w-2/3"
            dangerouslySetInnerHTML={{ __html: Renderer(content, {}, { basePath, relativePath }) }}
            style={childTextStyle}
          />
        ) : null}
      </div>
    </div>
  );
};

const BlockBarList = props => {
  const { WrapperFactory, data = {}, basePath, relativePath, editor, theme = {} } = props;

  const contentElem = _.map(data.children, (child, key) => {
    const childBgStyle = _.get(theme, ['styles', 'children', key, 'background'], {});

    return (
      <div key={key} className="BarListBlock" style={childBgStyle}>
        <BarItem
          child={child}
          childIndex={key}
          basePath={basePath}
          relativePath={relativePath}
          editor={editor}
          theme={theme}
        />
      </div>
    );
  });

  return (
    <WrapperFactory {...props} contentElem={contentElem} className="flex flex-col justify-center" />
  );
};

export default BlockBarList;
