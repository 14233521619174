import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';

import { registerLogger } from '@platform/utils/logging';

import Block from '../Block/viewer';

const log = registerLogger('components', 'HubBlockList');

class HubBlockList extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    blocks: PropTypes.array,
    blockDereferenced: PropTypes.array,
    parentPath: PropTypes.array, // json path to parent in tree
    basePath: PropTypes.string, // base url path
    relativePath: PropTypes.string, // relative url path (everything between basePath and the page we are on)
    cache: PropTypes.object,
    updateCache: PropTypes.func,
    blockProps: PropTypes.object, // props to pass onto underlying blocks
  };

  blockPath = index => {
    return this.props.parentPath.concat(['blocks', index]);
  };

  renderBlock = ({ id, block, index }) => {
    const {
      store,
      blocks = [],
      blocksDereferenced = [],
      basePath,
      relativePath,
      blockProps = {},
      slugger,
    } = this.props;

    const blockLength = _.size(blocks);
    const isLast = parseInt(index) >= blockLength - 1;

    return (
      <Block
        {...blockProps}
        key={index}
        id={id}
        editor={store} // DEPRECATED
        store={store}
        block={block}
        blockDereferenced={blocksDereferenced[index]}
        blockIndex={index}
        blockPath={this.blockPath(index)}
        isLast={isLast}
        isSolo={blockLength <= 1}
        basePath={basePath}
        relativePath={relativePath}
        slugger={slugger}
      />
    );
  };

  render() {
    const {
      className,
      blocks = [],
      childCacheIds = [],
      parentPath,
      basePath,
      relativePath,
      blockProps,
    } = this.props;

    log.debug('Render', { blocks, parentPath, basePath, relativePath, blockProps });

    let contentElems = [];

    _.forEach(blocks, (block, index) => {
      const id = childCacheIds[index];
      contentElems.push(this.renderBlock({ id, block, index }));
    });

    return <div className={cn('HubBlockList', className)}>{contentElems}</div>;
  }
}

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'store.id',
  basePathProp: 'parentPath',
  path: ['blocks'],
  childrenProp: 'blocks',
})(HubBlockList);
