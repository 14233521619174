import React from 'react';
import { inject } from 'mobx-react';
import _ from 'lodash';

const JsonPathCacheContainer = (props = {}) => BaseComponent => {
  const { cacheKey, cacheKeyProp, path, pathProp, pure = true } = props;

  class JsonPathCache extends React.Component {
    componentWillMount() {
      const { jsonPathContainerData = {} } = this.props;
      const { store, cacheKey, path } = jsonPathContainerData;

      // register if it does not already have an id
      store.register({
        cacheKey,
        path,
      });
    }

    componentWillReceiveProps(nextProps) {
      const data = this.props.jsonPathContainerData || {};
      const nextData = nextProps.jsonPathContainerData || {};

      const currentKey = data.cacheKey + data.id + data.path;
      const nextKey = nextData.cacheKey + nextData.id + nextData.path;

      if (currentKey !== nextKey) {
        nextData.store.register({
          cacheKey: nextData.cacheKey,
          id: nextData.id,
          path: nextData.path,
        });
      }
    }

    // shouldComponentUpdate(nextProps) {
    //   const data = this.props.jsonPathContainerData || {};
    //   const nextData = nextProps.jsonPathContainerData || {};

    //   if (pure) {
    //     return data.cache !== nextData.cache;
    //   }

    //   return true;
    // }

    render() {
      const { jsonPathContainerData = {}, ...baseProps } = this.props;
      const { store, cacheKey, path, updateCache } = jsonPathContainerData;

      const cache = store.getCache({ cacheKey, path }) || {};

      return (
        <BaseComponent
          {...baseProps}
          id={cache.id}
          cacheKey={cacheKey}
          cache={cache.data}
          updated={cache.updated}
          updateCache={(t, p, v, options) => {
            updateCache(t, p, v, options);
            this.forceUpdate();
          }}
        />
      );
    }
  }

  let targetExport = JsonPathCache;
  if (
    typeof window !== 'undefined' &&
    typeof localStorage !== 'undefined' &&
    localStorage.debugRender
  ) {
    const debugRender = require('react-render-debugger');
    targetExport = debugRender(targetExport);
  }

  return inject(({ jsonPathCache }, props) => {
    const ck = cacheKey || _.get(props, cacheKeyProp);
    const p = path || _.get(props, pathProp);

    return {
      jsonPathContainerData: {
        store: jsonPathCache,
        cacheKey: ck,
        path: p,
        cache: jsonPathCache.getCache({ cacheKey: ck, path: p }),
        updateCache: jsonPathCache.updateCacheFactory({ cacheKey: ck, basePath: p }),
      },
    };
  })(targetExport);
};

export default JsonPathCacheContainer;
