import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import pluralize from 'pluralize';
import { safeParse } from '@platform/utils/json';

import EntityEditorGroup from '../EntityEditorGroup';
import FormInput from '../FormInput';

function UsedPathParams({ name, editor = {}, className = '', showEmptyMessage }) {
  const pathParams = editor.currentStepPathParams;
  const hasParams = !_.isEmpty(pathParams);

  if (!hasParams && !showEmptyMessage) return null;

  let subtitle;
  let active;
  let warning;
  let notSetCount = 0;

  _.forEach(pathParams, v => {
    if (_.isUndefined(v) || (typeof v === 'string' && _.isEmpty(v))) {
      notSetCount += 1;
    }
  });

  if (notSetCount > 0) {
    warning = true;
    subtitle = `${notSetCount} path ${pluralize('param', notSetCount)} not set`;
  } else {
    active = true;
  }

  let contents;
  if (hasParams) {
    contents = _.map(pathParams, (value, key) => {
      return (
        <FormInput
          key={key}
          value={_.isUndefined(value) ? '' : `${value}`}
          className="EntityEditorGroup-input"
          placeholder="value"
          onChange={e => {
            const newVal = safeParse(e.target.value, e.target.value);
            const newParams = _.cloneDeep(pathParams);
            _.set(newParams, key, _.isObject(newVal) && _.isEmpty(newVal) ? '' : newVal);
            editor.updateCurrentStepPathParams(newParams);
          }}
          label={{ content: key, basic: true, className: 'Label-fw' }}
          fluid
        />
      );
    });
  } else if (showEmptyMessage) {
    contents = <div className="p-4">{showEmptyMessage}</div>;
  }

  return (
    <EntityEditorGroup
      id="used-path-params"
      className={`PathParamEditor ${className}`}
      name={name || 'Path Params'}
      active={active}
      warning={warning}
      subtitle={subtitle}
      forceOpen={warning}
      defaultOpen
      contentFactory={() => <div>{contents}</div>}
    />
  );
}

export default inject(({ collectionEditorStore }, { editorId }) => ({
  editor: collectionEditorStore.getEditor({ id: editorId }),
}))(observer(UsedPathParams));
