import React from 'react';
import { Image } from 'semantic-ui-react';

const ImageBlock = props => {
  const { WrapperFactory, header = {}, data = '', config = {} } = props;

  const style = {};
  if (config.maxWidth) {
    style.maxWidth = `${String(config.maxWidth)}px`;
  }
  if (config.maxHeight) {
    style.maxHeight = `${String(config.maxHeight)}px`;
  }

  const contentElem = (
    <div className={config.centered ? 'justify-center text-center' : ''}>
      {header.title ? <div className="ImageBlock-title">{header.title}</div> : null}

      <Image
        src={data}
        alt={header.title || header.subtitle}
        centered={config.centered}
        fluid={config.fluid}
        bordered={config.bordered}
        style={style}
      />

      {header.subtitle ? <div className="ImageBlock-subtitle">{header.subtitle}</div> : null}
    </div>
  );

  return <WrapperFactory {...props} headerElem={null} contentElem={contentElem} />;
};

export default ImageBlock;
