import React from 'react';
import cn from 'classnames';
import { Loader } from 'semantic-ui-react';

export default ({ className = undefined, children = null, text = 'Loading', ...extraProps }) => {
  return (
    <div className={cn('PageLoading', className)} style={{ padding: 50 }}>
      <Loader inline="centered" size="big" active inverted {...extraProps}>
        {children || text}
      </Loader>
    </div>
  );
};
