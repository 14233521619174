import React from 'react';
import FormSelect from '@platform/components/FormSelect';

const Method = ({ value, label, onChange, readOnly }) => {
  return (
    <div className="pr-2">
      <FormSelect
        readOnly={readOnly}
        label={label}
        value={value}
        onChange={method => onChange(method)}
        options={[
          { text: 'GET', value: 'get' },
          { text: 'PUT', value: 'put' },
          { text: 'POST', value: 'post' },
          { text: 'PATCH', value: 'patch' },
          { text: 'DELETE', value: 'delete' },
        ]}
        placeholder="Choose one"
      />
    </div>
  );
};

export default Method;
