import _ from 'lodash';

import { getConfigVar } from '@platform/utils/config';
import { isExternalLink, cleanSlashes } from '@platform/utils/url';
import { routeData } from '@platform/utils/json/routeData';

import { findFirstSubpagePath } from './viewer';

// This is implemented in the viewer and editor files.
// All possible options for each block:
// {
//   padded: true, // should this block be padded when rendered at the root? this padding is outside the outline (content padding)
//   paddedInner: true, // should this block have padding inside of its outline (content-inner padding)
//   outlined: true, // should this block be outlined when rendered at the root? (content border)
//   standalone: true, // should the block be set apart from it's siblings more than normal?
//   canvas: true, // should the block be rendered on a background canvas (light grey)
//   solo: { // solo means this block is being rendered by itself inside of another block
//     padded: true,
//     outlined: true,
//   }
//   nested: { // nested means this block is being rendered inside of another block
//     padded: true,
//     outlined: true,
//   },
// }
export const blockConfig = {};

// Build up the appropriate classnames to position and outline the content based on
// the block config properties, and the context with which this block is being
// rendered (nested, nested by itself, or not nested)
//
// Doing it this way makes for more generic css rules, instead of having css rules for every
// Block--type combo possible.
//
export const buildBlockClassNames = props => {
  const { block = {}, config: globalConfig, classNames = [], isNested, isSolo, isLast } = props;

  const computedClassNames = _.concat(classNames, _.get(block, 'config.classNames', []));

  if (!globalConfig) {
    return computedClassNames;
  }

  const config = Object.assign({}, globalConfig, block.config);

  let isOnCanvas = !isSolo && config.canvas;
  let isStandalone = config.standalone && !_.get(block, 'config.plain');
  let isPadded = config.padded && !_.get(block, 'config.plain');
  let isOutlined = config.outlined;
  let hasHeader = !_.isEmpty(block.header);

  if (isNested) {
    if (isSolo) {
      if (config.solo) {
        isOnCanvas = _.has(config.solo, 'canvas') ? config.solo.canvas : isOnCanvas;
        isPadded = hasHeader
          ? true
          : _.has(config.solo, 'padded')
            ? config.solo.padded && !_.get(block, 'config.plain')
            : isPadded;
        isOutlined = _.has(config.solo, 'outlined') ? config.solo.outlined : isOutlined;
      }
    } else if (config.nested) {
      isOnCanvas = _.has(config.nested, 'canvas') ? config.nested.canvas : isOnCanvas;
      isPadded = _.has(config.nested, 'padded')
        ? config.nested.padded && !_.get(block, 'config.plain')
        : isPadded;
      isOutlined = _.has(config.nested, 'outlined') ? config.nested.outlined : isOutlined;
    }
  } else if (isSolo) {
    if (config.solo) {
      if (_.has(config.solo, 'padded')) {
        isPadded = config.solo.padded;
      }
    }
  }

  if (isNested) {
    computedClassNames.push('is-nested');
  }

  if (isSolo) {
    computedClassNames.push('is-solo');
  }

  if (isLast) {
    computedClassNames.push('is-last');
  }

  if (isPadded) {
    computedClassNames.push('is-padded');
  } else {
    computedClassNames.push('is-not-padded');
  }

  if (config.paddedInner) {
    computedClassNames.push('is-padded-inner');
  }

  if (isOutlined) {
    computedClassNames.push('is-outlined');
  }

  if (isStandalone) {
    computedClassNames.push('is-standalone');
  }

  if (isOnCanvas) {
    computedClassNames.push('is-on-canvas');
  }

  return computedClassNames;
};

// replaces nav sections that normally just have ids with relevant data from the pages they point to
export const derefNavItems = (items, parsed, options) => {
  const { autoNav, basePath = '' } = options || {};

  return _.compact(
    _.map(items, item => {
      if (!item) return;

      const navItem = _.pick(item, ['path', 'title', 'image']);

      const page = _.get(parsed, ['pages', navItem.path]);
      if (!page) {
        return navItem;
      }

      if (_.isNil(navItem.title) && page.title) {
        navItem.title = page.title;
      }

      if (!navItem.image && page.image) {
        navItem.image = page.image;
      }

      const { blocks, children } = page.data || {};

      // try to route to the first subpage
      if (autoNav && _.isEmpty(blocks) && !_.isEmpty(children)) {
        const subpagePath = findFirstSubpagePath({
          subpages: children,
          basePath: navItem.path,
        });

        if (subpagePath) {
          navItem.path = subpagePath;
        }
      }

      if (basePath) {
        navItem.path = cleanSlashes(`/${basePath}/${navItem.path}`);
      }

      return navItem;
    })
  );
};

// Dereferences Hub Nav items and forces Title & Logo
export const derefNav = (hub, options) => {
  const { header, title, logo } = hub || {};
  const { nav = {} } = header || {};
  const { basePath = '' } = options || {};

  const homeNav = {
    title: title || logo ? title : 'Untitled Hub',
    path: basePath ? cleanSlashes(`/${basePath}`) : '/',
  };

  if (logo) {
    homeNav.image = logo;
  }

  return {
    left: [homeNav, ...derefNavItems(nav.left, hub, options)],
    right: derefNavItems(nav.right, hub, options),
  };
};

export const getUrlPathFromJsonPath = (parsed = {}, path = [], urlPath = '') => {
  const newPath = `${_.get(parsed, path.concat(['route', 'path']), '')}${urlPath}`;

  if (path.length) {
    return getUrlPathFromJsonPath(parsed, _.dropRight(path, 1), newPath);
  }

  return newPath;
};

/*
  Gets the inferred subpage type.
  page: no children
  group: has children
  link: has :// in the path = absolute url
  divider: no blocks & no children & no route
*/
export const getInferredSubpageType = _subpage => {
  const subpage = _subpage || {};
  const path = _.get(subpage, 'route.path');

  if (isExternalLink(path)) {
    return 'link';
  }

  const { data = {} } = subpage;
  if (_.isEmpty(data.children)) {
    return 'page';
  }

  return 'group';
};

/*
  Given a page, will create a simple text block, using the page name as the title and then
  building up a markdown list of links to immediate children.

  This is used if a user somehow gets to a page that has no blocks - we'll just show them
  some links.
*/
export const buildTextBlockForPage = ({ basePath = '', pagePath = '', page = {} }) => {
  const block = {
    type: 'text',
    header: {
      title: page.title,
    },
    data: '',
  };

  _.forOwn(_.get(page, 'data.children', []), child => {
    const childPath = _.get(child, 'route.path');
    if (childPath) {
      const fullPath = `/${_.trimStart(`${basePath}${pagePath}${childPath}`, '/')}`;
      block.data += `- [${child.title || fullPath}](${fullPath})\n`;
    }
  });

  return block;
};

/**
 * Takes a hub theme object and returns appropriate theme object.
 */
const baseTheme = {
  styles: {
    base: {},
  },
};

export const computeTheme = ({ theme = {}, defaultTheme = {} }) => {
  const computedTheme = {};
  _.merge(computedTheme, baseTheme, defaultTheme, theme);
  return computedTheme;
};

export const buildPreviewUrl = ({ currentFileHash }) => {
  const parts = _.split(getConfigVar('SL_PREVIEW_HOST'), '://');

  return `${parts[0]}://${currentFileHash}.${parts[1]}`;
};

const searchRules = () => {
  const computeDescription = data => {
    let description = _.get(data, ['data', 'blocks', 0, 'data'], '');
    if (typeof description === 'string') {
      description = _.truncate(description.replace(/<\/?[^>]+(>|$)|#|\*/g, ''), { length: 75 });
    } else {
      description = undefined;
    }
    return description;
  };

  return {
    pages: {
      recursive({ currentPath = [] }) {
        return currentPath[0] === 'pages' && _.size(currentPath) <= 3;
      },
      matcher({ parentPath = [], key, value }) {
        return (
          parentPath[0] === 'pages' && _.size(parentPath) === 1 && _.has(value, ['data', 'blocks'])
        );
      },
      dataFactory({ parentPath, key, value }) {
        return {
          category: _.get(value, 'title') || key,
          name: _.get(value, 'title') || key,
          description: computeDescription(value),
        };
      },
      searchFactory({ value }) {
        return {
          title: _.get(value, 'title', ''),
          path: _.get(value, ['route', 'path'], ''),
          blocks: _.get(value, ['data', 'blocks'], ''),
        };
      },
    },
    subpages: {
      circular: true,
      recursive: true,
      matcher({ parentPath = [], key, value }) {
        return (
          _.last(parentPath) === 'children' &&
          !_.includes(parentPath, 'blocks') &&
          !_.isEmpty(_.get(value, ['data', 'blocks']))
        );
      },
      dataFactory({ spec, parentPath, key, value }) {
        let category = _.get(spec, _.slice(parentPath, 0, 2).concat('title')) || parentPath[1];
        if (_.size(parentPath) >= 5) {
          category = _.get(spec, _.slice(parentPath, 0, -2).concat('title'));
        }

        return {
          category,
          key,
          parentPath,
          name: _.get(value, 'title') || _.get(value, 'route.path'),
          description: computeDescription(value),
        };
      },
      searchFactory({ value }) {
        return {
          title: _.get(value, 'title', ''),
          path: _.get(value, ['route', 'path'], ''),
          blocks: _.get(value, ['data', 'blocks'], ''),
        };
      },
    },
  };
};

export const searchResults = ({ data, expression }) => {
  const rdata = routeData(
    { data, rules: searchRules() },
    {
      flatten: true,
      limit: 10,
      search: {
        query: expression,
      },
    }
  );

  let matched = _.flatMap(rdata, rule => {
    return rule.matched;
  });

  matched = _.compact(matched);

  let results = [];
  _.forEach(matched, (match, index) => {
    // const category = match.data.category;
    const path = match.path;
    const title = _.get(match, 'data.name');
    const description = _.get(match, 'data.description');

    results.push({
      key: index,
      path,
      title,
      description,
    });
  });

  return results;
};

export const buildCrumbs = ({ parsedPath = [], hub = {}, buildPath }) => {
  let crumbs = [];

  const rootPageJsonPath = _.take(parsedPath, 2);
  const rootPage = _.get(hub, rootPageJsonPath);

  if (!rootPage) return [];

  crumbs.push({
    content: rootPage.title,
    to: buildPath(rootPageJsonPath),
  });

  // need to turn stringified numbers in path to integers
  _.forEach(parsedPath, (path, index) => {
    const intPath = _.parseInt(path);

    if (_.isFinite(intPath)) {
      const subpageJsonPath = _.take(parsedPath, index).concat(intPath);
      const subpage = _.get(hub, subpageJsonPath);

      const title = _.get(subpage, 'title');

      crumbs.push({
        content: title || _.get(subpage, 'route.path', ''),
        to: buildPath(subpageJsonPath),
      });
    }
  });

  return crumbs;
};

export const isHubBuilder = () => {
  return getConfigVar('APP_NAME') === 'hub-builder';
};
